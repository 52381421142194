import React, { useState, useEffect } from "react";
import FactionLogo from "../Utils/FactionLogo";
import ScoreData from "../../Data/GameData/Objectifs/ScoreData";
import FactionSpan from "../Utils/FactionSpan";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import Objectives from "../../Data/GameData/Objectifs/Objectives";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import Color from "../../Common/Config/Colors";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import MinorFactionMissionInput from "../Ability/MinorFactionMissionInput";
import ObjectiveInput from "../Ability/ObjectiveInput";
import LineSpace from "../Utils/Layout/LineSpace";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");

const PublicObjectiveDetailedView = ({ objectiveName }) => {
  const playerData = StaticGameData.getPlayerData();
  const allPlayersData = PlayerData.getAllPlayersData(playerData);

  const objectiveDesc = Objectives.createDesc(objectiveName);

  const isObjectiveAchieved = Objectives.hasScoredObjective(
    playerData,
    objectiveName
  );

  const DisplayFactionLogo = ({}) => {
    const factions = allPlayersData
      .filter(
        (player) =>
          player.faction && Objectives.hasScoredObjective(player, objectiveName)
      )
      .map((player) => player.faction.name);

    return (
      <span>
        {factions.map((faction, index) => (
          <span key={index}>
            {" "}
            <FactionLogo factionName={faction} />{" "}
          </span>
        ))}
      </span>
    );
  };

  const DisplayContent = ({}) => {
    return (
      <div
        style={{
          //set Interline gap
          lineHeight: "2em",
        }}
      >
        <div>Objective : {objectiveName}</div>
        <div>
          Following factions achived this objective :{" "}
          <DisplayFactionLogo></DisplayFactionLogo>
        </div>
        <div style={{ color: "gold" }}>{objectiveDesc.vp} VP</div>
        <div
          style={{
            marginTop: "0.5em",
            backgroundColor: Color.COLOR_GREY_3,
            marginBottom: "0.5em",
          }}
        >
          {objectiveDesc.description}
        </div>

        {isObjectiveAchieved ? (
          <BaseButton
            type="greyOut"
            onClick={() => {
              UIMessage.displayInfoMessage(
                "Already Scored",
                "You already scored this objective"
              );
            }}
          >
            Already Scored
          </BaseButton>
        ) : (
          <div>
            {objectiveDesc.onlyStartOfRound ? (
              <div>
                This objective can only be scored at the beginning of a round.
                It will be scored automatically if you meet the scoring
                condition.
              </div>
            ) : (
              <BaseButton
                onClick={() => {
                  ObjectiveInput.routeObjectiveInput(objectiveName);
                }}
              >
                Score
              </BaseButton>
            )}{" "}
          </div>
        )}
        <LineSpace></LineSpace>
        <div>
          Objectives are also automatically scored at the beginning of a round,
          before checking if there is a winner for the game.
        </div>
      </div>
    );
  };

  const navigationData = {
    buttonsData: [
      {
        text: "Back",
        callback: () => {
          Popup.goBack();
        },
      },
    ],
  };

  return (
    <GameGlobalLayout backgroundType="uniform">
      <div
        className="text-box"
        style={{
          textAlign: "center",
        }}
      >
        <DisplayContent></DisplayContent>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default PublicObjectiveDetailedView;
