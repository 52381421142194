import React, { useState } from "react";
import "../CSS/Font.css";
import "../CSS/Menu.css";
import UIMessage from "../Data/GameData/Connection/UIMessage";
import BaseButton from "./Utils/BaseButton";
import GameState from "../Data/GameData/Game/GameState";
import PlayerData from "../Data/GameData/PlayerData/PlayerData";
import LogBookView from "./Utils/LogMessage/LogBookView";

const StaticGameData = require("../Data/GameData/StaticGameData");
const Popup = require("../Data/Other/Popup");
const Color = require("../Common/Config/Colors");

const MessageView = ({ playerData }) => {
  let message = null;
  if (playerData) {
    message = UIMessage.getFirstMessageToDisplay(playerData);
  } else {
    message = UIMessage.getFirstMessageToDisplayinStaticGameData();
  }

  if (!message) {
    throw new Error("No message to display");
  }

  if (!message.type) {
    throw new Error("No message type");
  }

  /*if (message.format === UIMessage.MESSAGE_FORMAT_TEXT && !message.lines) {
    throw new Error("No message lines");
  }*/
  //console.log("MessageView: message", message);
  // Define background color based on message type
  let backgroundColor;
  if (message.type === UIMessage.TYPE_ERROR) {
    //backgroundColor = "rgb(255, 200, 200)"; // Red background for error
  } else if (message.type === UIMessage.TYPE_INFO) {
    //backgroundColor = "rgb(200, 200, 255)"; // Blue background for info
    //backgroundColor = "rgb(200, 200, 255)"; // Blue background for info
  } else {
    //backgroundColor = "rgb(31, 31, 31)"; // Default background color
  }
  //backgroundColor = "rgb(0, 0, 0,1)";
  backgroundColor = Color.COLOR_HEAVY_SHADOW;

  const style = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 2000,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  };

  const callback = StaticGameData.popup.callback;

  const handleMouseDownOnBackground = (e) => {
    e.preventDefault();
    //e.stopPropagation();
  };

  const handleMouseUpOnBackground = (e) => {
    //e.preventDefault();
    //e.stopPropagation();
    if (UIMessage.TYPE_INFO === message.type) {
      if (playerData) {
        UIMessage.removeMessageToDisplay(playerData);
      } else {
        UIMessage.removeMessageToDisplayFromStaticGameData();
      }
      Popup.touch();
    }
  };

  const Title = () => {
    return (
      <div
        style={{
          fontSize: "1.5em",
          color: "white",
          //border: "2px solid blue",
          padding: "10px",
          width: "100%",
        }}
      >
        {message.name}
      </div>
    );
  };

  const Content = () => {
    return (
      <div
        style={{
          fontSize: "1rem",
          color: "white",
          //border: "2px solid yellow",
          padding: "10px",
          width: "100%",
          maxHeight: "100%",
          overflow: "auto",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            textAlign: "left",
            maxHeight: "100%",
            overflowY: "auto",
            width: "100%",
          }}
        >
          {message.format === UIMessage.MESSAGE_FORMAT_TEXT &&
            message.lines.map((line, index) => {
              return <div key={index}>{line}</div>;
            })}
        </div>
        {message.format === UIMessage.MESSAGE_FORMAT_LOGBOOK && (
          <LogBookView logBook={message.logBook}></LogBookView>
        )}
        {message.format === UIMessage.MESSAGE_FORMAT_COMPONENT && (
          <div>{message.component}</div>
        )}
        {(message.type === UIMessage.TYPE_INFO ||
          message.type === UIMessage.TYPE_ERROR) && (
          <div style={{ padding: "10px" }}>
            <BaseButton
              //style={{}}
              onClick={() => {
                if (playerData) {
                  UIMessage.removeMessageToDisplay(
                    StaticGameData.getPlayerData()
                  );
                } else {
                  UIMessage.removeMessageToDisplayFromStaticGameData();
                }

                Popup.touch();
              }}
            >
              <div style={{ fontSize: "1.5em" }}>Ok</div>
            </BaseButton>
          </div>
        )}

        {message.type === UIMessage.TYPE_CONFIRM && (
          <div style={{ padding: "10px" }}>
            <BaseButton
              //style={{}}
              onClick={() => {
                if (playerData) {
                  UIMessage.removeMessageToDisplay(
                    StaticGameData.getPlayerData()
                  );
                } else {
                  UIMessage.removeMessageToDisplayFromStaticGameData();
                }
                Popup.touch();
              }}
            >
              <div style={{ fontSize: "1.5em" }}>Cancel</div>
            </BaseButton>
            <BaseButton
              //style={{}}
              onClick={() => {
                if (playerData) {
                  UIMessage.removeMessageToDisplay(
                    StaticGameData.getPlayerData()
                  );
                } else {
                  UIMessage.removeMessageToDisplayFromStaticGameData();
                }
                Popup.touch();

                message.confirmCallback();
              }}
            >
              <div style={{ fontSize: "1.5em" }}>Confirm</div>
            </BaseButton>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      style={style}
      onMouseDown={handleMouseDownOnBackground}
      onMouseUp={handleMouseUpOnBackground}
      className="div-fill"
    >
      <div className="div-fill" style={{ width: "98%", height: "95%" }}>
        <div
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onMouseUp={(e) => {
            e.stopPropagation();
          }}
          style={{
            backgroundColor: backgroundColor,
            height: "auto",
            borderWidth: "4px",
            borderStyle: "solid",
            borderColor: Color.BASE_BUTTON_BORDER,
            borderRadius: "10px",
            //padding: "10px",
            //padding: "10px",
            maxHeight: "100%",
            //maxWidth: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
          className="div-ver-center"
        >
          {message.name && (
            <div>
              <Title></Title>
            </div>
          )}
          <Content></Content>
          {false && (
            <div
              style={{
                //height: "100%",
                width: "100%",
                overflowY: "auto", // Enable scrolling if content overflows
                //overflowX: "hidden",
                //border: "2px solid blue",
              }}
            >
              <Content></Content>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageView;
