import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";

import Popup from "../../Data/Other/Popup.js";

import GameGlobalLayout from "../GameGlobalLayout.jsx";
import NavigationView from "../NavigationView.jsx";
import BaseButton from "../Utils/BaseButton.jsx";
import LineSpace from "../Utils/Layout/LineSpace.jsx";
import Game from "../../Data/GameData/Game/Game.js";
import Time from "../../Data/GameData/Time/Time.js";
import StaticGameData from "../../Data/GameData/StaticGameData.js";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData.js";
import ChatFactionLogo from "../ChatView/ChatFactionLogo.jsx";

const GameInfoView = ({}) => {
  const game = Popup.game;
  const playerData = StaticGameData.getPlayerData();

  console.log("Popup.game", Popup.game);

  const navigationData = {
    buttonsData: [
      {
        text: "Back",
        callback: () => {
          Popup.goBack();
        },
      },
    ],
  };

  const DisplayWaitingOnPlayers = () => {
    const allPlayerData = PlayerData.getAllPlayersData(playerData, true);
    const playersStillPlaying = allPlayerData.filter((player) => {
      return !player.isWaitingEndOfRound;
    });
    const playersWaitingForEndOfRound = allPlayerData.filter((player) => {
      return player.isWaitingEndOfRound;
    });

    return (
      <div>
        <div>Players still playing : </div>
        <div>
          {playersStillPlaying.map((player, index) => (
            <span key={index}>
              <ChatFactionLogo
                playerInGameId={player.playerInGameId}
              ></ChatFactionLogo>
              <span> </span>
            </span>
          ))}
        </div>
        <div>Players ready for next round :</div>
        <div>
          {playersWaitingForEndOfRound.map((player, index) => (
            <span key={index}>
              <ChatFactionLogo
                playerInGameId={player.playerInGameId}
              ></ChatFactionLogo>
              <span> </span>
            </span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className="text-box-center"
      style={
        {
          //boxShadow: "inset 0 0 0 4px pink", // Simulate an inner border
        }
      }
    >
      <DisplayWaitingOnPlayers></DisplayWaitingOnPlayers>
      <LineSpace></LineSpace>
      <div> Game ID : {game.gameId}</div>{" "}
      <div> Draft : {game.draft ? "Yes" : "No"}</div>
      <div> Amount of players : {game.maxNumberPlayers}</div>
      <div> Password : {game.password ? "Yes" : "No"}</div>
      <div> Ranked : {game.ranked ? "Yes" : "No"}</div>
      <div> VP Limit : {game.vpLimit}</div>
      <div> Player Name Hidden : {game.playerNameHidden ? "Yes" : "No"}</div>
      <div> Game List Type : {game.gameListType}</div>
      <div> Time Type : {game.timeType}</div>
      {game.timeType === Game.TIME_TYPE_FREE && (
        <div>
          Preferable round duration :{" "}
          {Time.getDisplayTimeInString(game.roundTime)}
        </div>
      )}
      <LineSpace></LineSpace>
      <div>
        {" "}
        <BaseButton
          onClick={() => {
            Popup.deActivateBlackPopup();
          }}
        >
          Back
        </BaseButton>
      </div>
    </div>
  );
};

export default GameInfoView;
