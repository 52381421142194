import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";

import IconGridView from "./IconGridView";
import IconView from "../Icon/IconView";
import SpaceObjectImageView from "../Object/SpaceObjectImageView";
import GameState from "../../Data/GameData/Game/GameState";
import Logo from "../Utils/Logo";
import FactionLogo from "../Utils/FactionLogo";
import FleetsSummaryView from "../ManageSystem/FleetsSummaryView";
import UnitImageView from "../Object/UnitImageView";
import UnitsStackView from "../Units/UnitsStackView";
import BaseButton from "../Utils/BaseButton";
import ModifyFleetLimitAction from "../../Data/GameData/ActionData/ModifyFleetLimitAction";
import TechButtonView from "../Technology/TechButtonView";
import InteractionFunctions from "../../GameView/UIInteraction/InteractionFunctions";
import InventoryView from "../Transaction/InventoryView.jsx";
import AllAbilitiesView from "../Ability/AllAbilitiesView.jsx";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Popup = require("../../Data/Other/Popup.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const ActivationData = require("../../Data/GameData/Activation/ActivationData");
const Phase = require("../../Data/GameData/Game/Phase");
const EndRoundReviewCA = require("../../ClientAction/EndRoundReviewCA");
const Unit = require("../../Data/GameData/MapData/Unit.js");

const IMAGE_PLANET_TEST = "/Images/Map/Objects/planet_test.png";
const IMAGE_SHIP_URL = "/Images/Map/Icon/icon_ship.png";
const IMAGE_GROUND_FORCE_URL = "/Images/Map/Icon/icon_ground_force.png";

const ICON_BACKGROUND_OPACITY = 0.75;
const ICON_FONT_SIZE_PC = 100;

const GRID_WIDTH = 5;
const GRID_HEIGHT = 5;

const HomeSystemInfoView = ({
  systemSize,
  gapBetweenSystems,
  decallageY,
  minY,
  maxY,
  reverseConnections,
  mapSizeX,
}) => {
  const playerData = StaticGameData.getPlayerData();
  //return;
  const playerdatasToDisplay = [];
  playerdatasToDisplay.push(playerData);
  for (let i = 0; i < playerData.otherPlayers.length; i++) {
    playerdatasToDisplay.push(playerData.otherPlayers[i]);
  }

  const DisplayOnePlayerData = ({ data }) => {
    if (!data.faction || !data.homeSystemCoords) {
      return;
    }

    const borderColor = Color.getColorFromFaction(
      playerData,
      data.faction.name
    );

    if (
      data.homeSystemCoords.y - 1 < minY ||
      data.homeSystemCoords.y - 1 > maxY
    ) {
      return null;
    }

    if (!data.faction.name) return null;
    const coordinates = {
      x:
        (data.homeSystemCoords.x === 1 && !reverseConnections) ||
        (data.homeSystemCoords.x !== 1 && reverseConnections)
          ? -1
          : mapSizeX,
      y: data.homeSystemCoords.y - 1,
    };

    const positionX = coordinates.x * (systemSize + gapBetweenSystems);
    const positionY =
      coordinates.y * (systemSize + gapBetweenSystems) + decallageY;

    return (
      <div
        style={{
          position: "absolute",
          top: positionY,
          left: positionX,
          width: systemSize,
          height: systemSize,
          textAlign: "center",
          //backgroundColor: "yellow",
        }}
      >
        <div>
          <FactionLogo factionName={data.faction.name}></FactionLogo>
          <span> {data.faction.name}</span>
        </div>
        <div style={{ color: "gold" }}>
          {PlayerData.getVP(data)} VP / {playerData.gameParam.vpLimit}
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <div>
            <Logo logoName="credit"></Logo>
            <span> : {data.credit}</span>
          </div>
          <div>
            <Logo logoName="mineral"></Logo>
            <span> : {data.mineral}</span>
          </div>
          <div>
            <Logo logoName="science"></Logo>
            <span> : {data.science}</span>
          </div>
          <div>
            <Logo logoName="population"></Logo>
            <span> : {data.population}</span>
          </div>
          <div>
            <Logo logoName="energy"></Logo>
            <span> : {data.energy}</span>
          </div>
          <div style={{}}>
            <Logo logoName="cargo"></Logo>
            <span> : {data.cargo}</span>
          </div>
          {playerData.colonists > 0 && (
            <div>
              <span>Colonists : {playerData.colonists}</span>
            </div>
          )}
        </div>
        {false &&
          playerData.faction &&
          data.faction.name === playerData.faction.name && (
            <div>
              <BaseButton
                type="action"
                onClick={() => {
                  Popup.addAttributes({
                    actionSelectionPopup: true,
                    actionSelectionButtons: [
                      <BaseButton
                        onClick={() => {
                          ModifyFleetLimitAction.prepare(playerData);
                        }}
                      >
                        Modify Fleet Limit <Logo logoName="cargo"></Logo>
                      </BaseButton>,
                      <BaseButton
                        onClick={() => {
                          Popup.addLayer({
                            name: "ManageProductionView",
                          });
                        }}
                      >
                        Production
                      </BaseButton>,
                      <BaseButton
                        onClick={() => {
                          InteractionFunctions.selectASystem(() => {});
                        }}
                      >
                        Test Map Interaction
                      </BaseButton>,
                      <BaseButton
                        onClick={() => {
                          Popup.addLayer({
                            name: "AbilityList",
                            playerData: data,
                          });
                        }}
                      >
                        Abilities
                      </BaseButton>,
                    ],
                  });
                }}
              >
                Action
              </BaseButton>
            </div>
          )}
        <div>
          <BaseButton
            borderColor={borderColor}
            onClick={() => {
              Popup.addLayer({
                name: "FullScreenLogView",
                logBook: PlayerData.getActivityLog(data),
              });
            }}
          >
            Logs
          </BaseButton>

          <TechButtonView
            playerInGameId={data.playerInGameId}
            borderColor={borderColor}
          ></TechButtonView>
          <BaseButton
            borderColor={borderColor}
            onClick={() => {
              Popup.activateBlackPopup(
                <InventoryView
                  playerId={PlayerData.getPlayerId(data)}
                ></InventoryView>
              );
            }}
          >
            Inventory
          </BaseButton>
          <BaseButton
            borderColor={borderColor}
            onClick={() => {
              Popup.addDynamicLayer(
                <AllAbilitiesView playerDataToDisplay={data}></AllAbilitiesView>
              );
            }}
          >
            Abilities
          </BaseButton>
        </div>
      </div>
    );
  };

  //Otherwise return normal system display
  return (
    <div>
      {playerdatasToDisplay.map((data, index) => (
        <DisplayOnePlayerData key={index} data={data}></DisplayOnePlayerData>
      ))}
    </div>
  );
};

/*<div
            className="div-fill"
            style={{ position: "relative", height: "20%" }}
          >
            <ObjectText></ObjectText>
          </div>*/

export default HomeSystemInfoView;
