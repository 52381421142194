const CustomMath = require("../../../Common/Math/CustomMath");
const Time = require("../Time/Time");

class GameUpdate {
  static create(gameId, roomIdList) {
    return {
      gameId: gameId,
      gameStateIncrement: 0,
      roomUpdateList: roomIdList.map((roomId) => {
        return {
          roomId: roomId, // Use roomId instead of roomName
          roomStateIncrement: 0,
        };
      }),
    };
  }

  static doesNeedGameUpdate(gameUpdateLocal, gameUpdateServer) {
    return (
      gameUpdateLocal.gameStateIncrement < gameUpdateServer.gameStateIncrement
    );
  }

  static doesNeedRoomUpdate(gameUpdateLocal, roomId, gameUpdateServer) {
    const roomUpdateLocal = gameUpdateLocal.roomUpdateList.find(
      (roomUpdate) => roomUpdate.roomId === roomId // Use roomId for comparison
    );
    const roomUpdateServer = gameUpdateServer.roomUpdateList.find(
      (roomUpdate) => roomUpdate.roomId === roomId // Use roomId for comparison
    );
    return (
      roomUpdateLocal.roomStateIncrement < roomUpdateServer.roomStateIncrement
    );
  }

  static incrementGameUpdate(gameUpdate) {
    gameUpdate.gameStateIncrement++;
  }

  static incrementRoomUpdate(gameUpdate, roomId) {
    const roomUpdate = gameUpdate.roomUpdateList.find(
      (roomUpdate) => roomUpdate.roomId === roomId // Use roomId for identification
    );
    roomUpdate.roomStateIncrement++;
  }

  static incrementAllRoomUpdate(gameUpdate) {
    gameUpdate.roomUpdateList.forEach((roomUpdate) => {
      roomUpdate.roomStateIncrement++;
    });
  }

  static setGameIncrement(gameUpdate, gameUpdateSource) {
    gameUpdate.gameStateIncrement = gameUpdateSource.gameStateIncrement;
  }

  static setRoomIncrement(gameUpdate, gameUpdateSource, roomId) {
    const roomUpdate = gameUpdate.roomUpdateList.find(
      (roomUpdate) => roomUpdate.roomId === roomId
    );
    const roomUpdateSource = gameUpdateSource.roomUpdateList.find(
      (roomUpdate) => roomUpdate.roomId === roomId
    );
    roomUpdate.roomStateIncrement = roomUpdateSource.roomStateIncrement;
  }

  static setAllRoomIncrement(gameUpdateToUpdate, gameUpdateSource) {
    gameUpdateToUpdate.roomUpdateList = gameUpdateSource.roomUpdateList;
  }
}

module.exports = GameUpdate;
