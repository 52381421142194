import React, { useState, useRef, useEffect } from "react";
import "../CSS/Font.css";
import "../CSS/Menu.css";
import BaseButton from "./Utils/BaseButton";
import TitleView from "./TitleView";
import Color from "../Common/Config/Colors";
import useScrollPosition from "./Utils/Hooks/UseScrollPosition";

const Popup = require("../Data/Other/Popup");
const StaticGameData = require("../Data/GameData/StaticGameData");

const GameGlobalLayout = ({
  children,
  title = null,
  titleComponent = null,
  forbidOverflow = false,
  visible = true,
  showTitle = true,
  showResourceBarInTitle = true,
  fullScreen = true,
  backgroundType = "none",
  scrollAtEnd = false,
  rememberScroll = true,
  rememberScrollKey = null,
  displayComponentOnTopNavBar = null,
  mode = null,
  centerOnScreen = false,
}) => {
  //Initialize the scoll at the end
  const popup = StaticGameData.popup;
  const scrollContainerRef = useRef(null);

  const scrollPosition = useScrollPosition(
    scrollContainerRef,
    null,
    rememberScroll,
    scrollAtEnd,
    popup,
    rememberScrollKey
  );

  //Initial Scroll
  /*useEffect(() => {
    if (scrollContainerRef.current) {
      if (popup.scrollPosition) {
        scrollContainerRef.current.scrollTop = popup.scrollPosition;
      } else {
        if (scrollAtEnd) {
          // Scroll to the bottom of the container
          scrollContainerRef.current.scrollTop =
            scrollContainerRef.current.scrollHeight;
        }
      }
    }
  }, []);*/

  if (visible === false) return null;
  //console.log("GameGlobalLayout children: ", children);
  //console.log("GameGlobalLayout children.length: ", children.length);
  if (!children || children.length !== 2) {
    throw new Error("GameGlobalLayout requires exactly two children");
  }
  let flexibleStyle = {};
  if (backgroundType === "shadow") {
    flexibleStyle = {
      backgroundColor: Color.COLOR_MANAGE_VIEW_BACKGROUND,
    };
  }
  if (backgroundType === "uniform") {
    flexibleStyle = {
      backgroundColor: Color.COLOR_GREY_1,
    };
  }
  if (backgroundType === "black") {
    flexibleStyle = {
      backgroundColor: "black",
    };
  }

  const childContainerStyle = {
    flex: 1,
    width: fullScreen ? "100%" : "auto",
    overflowY: forbidOverflow ? "hidden" : "auto",
    //outline: "2px solid green",
    //outlineOffset: "-4px",
    //minHeight: "100%",
  };

  if (centerOnScreen) {
    //childContainerStyle.display = "flex";
    //childContainerStyle.flexDirection = "column";
    //childContainerStyle.justifyContent = "center";
    //childContainerStyle.alignItems = "center";
  }

  return (
    <div
      className="div-fill"
      style={{
        width: "100%",
      }}
    >
      {!mode && (
        <div
          className="div-fill"
          style={{
            display: "flex",
            flexDirection: "column",
            //backgroundColor: "black",
            //overflowY: "hidden",
            ...flexibleStyle,
          }}
        >
          {showTitle && !titleComponent && (
            <div style={{ width: fullScreen ? "100%" : "auto" }}>
              <TitleView showResourceBar={showResourceBarInTitle}>
                {" "}
                {title}{" "}
              </TitleView>
            </div>
          )}

          {showTitle && titleComponent && (
            <div style={{ width: fullScreen ? "100%" : "auto" }}>
              <TitleView
                showResourceBar={showResourceBarInTitle}
                titleComponent={titleComponent}
              ></TitleView>
            </div>
          )}

          <div ref={scrollContainerRef} style={childContainerStyle}>
            {/* Render the first child component */}
            <div
              style={
                {
                  //outline: "2px solid red",
                  //outlineOffset: "-4px",
                }
              }
            >
              {" "}
              {children && children[0]}{" "}
            </div>
          </div>

          {/* Fixed component at the bottom */}
          {displayComponentOnTopNavBar && displayComponentOnTopNavBar()}
          <div
            style={{
              width: fullScreen ? "100%" : "auto",
              backgroundColor: "black",
              borderTop: "1px solid white",
            }}
          >
            {/* Render the second child component */}
            {children && children[1]}
          </div>
          {/* Content component taking the rest of the screen */}
        </div>
      )}
      {mode && mode === "map" && (
        <div className="div-fill">
          <div
            className="div-fill"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              //backgroundColor: "black",
              //overflowY: "hidden",
              ...flexibleStyle,
            }}
          >
            {showTitle && (
              <div style={{ width: fullScreen ? "100%" : "auto" }}>
                <TitleView> {title} </TitleView>
              </div>
            )}

            <div
              ref={scrollContainerRef}
              style={{
                flex: 1,
                //backgroundColor: "lightblue",
                width: fullScreen ? "100%" : "auto",
                overflowY: forbidOverflow ? "hidden" : "auto",
              }}
            >
              {/* Render the first child component */}
              {children && children[0]}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              position: "absolute",
              top: "100%",
              transform: "translateY(-100%)",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {/* Fixed component at the bottom */}
            {displayComponentOnTopNavBar && displayComponentOnTopNavBar()}
            {children && children[1]}
            {/* Content component taking the rest of the screen */}
          </div>
        </div>
      )}
    </div>
  );
};

export default GameGlobalLayout;
