import AbilityCommon from "../../Data/GameData/Ability/AbilityCommon";
import ResolveMinorFactionAbility from "../../Data/GameData/Ability/ResolveMinorFactionAbility";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import Map from "../../Data/GameData/MapData/Map";
import SecondaryObject from "../../Data/GameData/MapData/SecondaryObject";
import HeroNames from "../../Data/GameData/MinorFaction/HeroNames";
import MinorFaction from "../../Data/GameData/MinorFaction/MinorFaction";
import Faction from "../../Data/GameData/PlayerData/Faction";
import StaticGameData from "../../Data/GameData/StaticGameData";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import PlanetView from "../Object/PlanetView";
import InteractionFunctions from "../UIInteraction/InteractionFunctions";
const TechList = require("../../Data/GameData/Technology/TechList");
const ResolveTechAbility = require("../../Data/GameData/Ability/ResolveTechAbility");

const {
  default: SelectionListView,
} = require("../Utils/Layout/SelectionListView");
const System = require("../../Data/GameData/MapData/System");

class MinorFactionAbilityInput {
  static routeMinorFactionAbilityInput(spaceObjectName, minorFaction) {
    //For testing
    /*this.erinys(spaceObjectName, minorFaction);
    return;*/

    const route = () => {
      switch (minorFaction.name) {
        case Faction.MINOR_FACTION_NAME_KOHOR:
          this.kohor(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_AETHERION:
          this.aetherion(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_ANDAR:
          this.andar(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_BOREALIS:
          this.borealis(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_CALVARIA:
          this.calvaria(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_DELPHAS:
          this.delphas(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_DRAKOR:
          this.drakor(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_ERINYS:
          this.erinys(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_EROSIUM:
          this.erosium(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_FELTOR:
          this.feltor(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_GHELOR:
          this.ghelor(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_HELKARAN:
          this.helkaran(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_ICANTA:
          this.icanta(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_JELORIA:
          this.jeloria(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_KORATH:
          this.korath(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_KOBAMDA:
          this.kobamda(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_LARAN:
          this.laran(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_MALTRION:
          this.maltrion(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_MINARI:
          this.minari(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_OPHARIAN:
          this.opharian(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_PROTHIAN:
          this.prothian(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_QUORIDIOM:
          this.quoridiom(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_RYNZORATH:
          this.rynzorath(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_SELTAAR:
          this.seltaar(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_TERRAN:
          this.terran(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_TAARKIR:
          this.taarkir(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_UTARION:
          this.utarion(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_VOIDBORN:
          this.voidborn(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_WOLTHAAR:
          this.wolthaar(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_XELPHAR:
          this.xelphar(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_XENOS:
          this.xenos(spaceObjectName, minorFaction);
          break;
        case HeroNames.HERO_ZYLORIAN:
          this.zylorian(spaceObjectName, minorFaction);
          break;

        default:
          throw new Error("Minor Faction ability name not found");
      }
    };

    UIMessage.displayConfirmMessage(
      "Ability of " + minorFaction.name,
      "Do you want to use the ability of the " + minorFaction.name + " ?",
      route
    );
  }

  static kohor = (spaceObjectName, minorFaction) => {
    console.log("routeMinorFactionAbilityInput minorFaction : ", minorFaction);
    ResolveMinorFactionAbility.sendToServerCommon(
      spaceObjectName,
      minorFaction
    );
  };
  static aetherion = (spaceObjectName, minorFaction) => {
    console.log(
      "routeMinorFactionAbilityInput minorFaction : ",
      minorFaction.name
    );
    /* ResolveMinorFactionAbility.sendToServerCommon(
      spaceObjectName,
      minorFaction
    );*/
    InteractionFunctions.chooseATech(
      (tech) => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {
          techName: tech.name,
        });
      },
      false,
      true
    );
  };

  static andar = (spaceObjectName, minorFaction) => {
    InteractionFunctions.selectAPlanet((planet) => {
      ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {
        planetName: planet.name,
      });
      Popup.resetMapNavigationData();
    }, "Select a planet to place a factory on");
  };

  static borealis = (spaceObjectName, minorFaction) => {
    InteractionFunctions.selectAPlanet((planet) => {
      ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {
        planetName: planet.name,
      });
      Popup.resetMapNavigationData();
    }, "Select a planet");
  };

  static calvaria = (spaceObjectName, minorFaction) => {
    UIMessage.displayConfirmMessage(
      minorFaction.name,
      "Do you want to gain a Calvaria scroll ?",
      () => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {});
      }
    );
  };

  static delphas = (spaceObjectName, minorFaction) => {
    UIMessage.displayConfirmMessage(
      minorFaction.name,
      "Do you want to gain a Delphas scroll ?",
      () => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {});
      }
    );
  };

  static drakor = (spaceObjectName, minorFaction) => {
    InteractionFunctions.selectASystem((system) => {
      ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {
        systemName: system.name,
      });
      Popup.resetMapNavigationData();
    }, "Select a system to place 4 Drakor cruisers on");
  };

  static erinys = (spaceObjectName, minorFaction) => {
    InteractionFunctions.selectPlanetsOnMap(1, 2, (planets) => {
      const planetNames = planets.map((p) => p.name);
      ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {
        planetNames: planetNames,
      });
    });
  };

  static erosium = (spaceObjectName, minorFaction) => {};

  static feltor = (spaceObjectName, minorFaction) => {
    InteractionFunctions.selectASystem((system) => {
      ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {
        systemName: system.name,
      });
      Popup.resetMapNavigationData();
    }, "Select a system to remove a portal from : ");
  };

  static ghelor = (spaceObjectName, minorFaction) => {
    InteractionFunctions.selectAPlanet((planet) => {
      ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {
        planetName: planet.name,
      });
      Popup.resetMapNavigationData();
    }, "Select a planet to place a assembly orbital on : ");
  };

  static helkaran = (spaceObjectName, minorFaction) => {
    UIMessage.displayConfirmMessage(
      minorFaction.name,
      "Do you want to use the ability of the " + minorFaction.name + " ?",
      () => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {});
      }
    );
  };

  static icanta = (spaceObjectName, minorFaction) => {
    UIMessage.displayConfirmMessage(
      minorFaction.name,
      "Do you want to gain a Icanta scroll ?",
      () => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {});
      }
    );
  };

  static jeloria = (spaceObjectName, minorFaction) => {
    UIMessage.displayConfirmMessage(
      minorFaction.name,
      "Do you want to gain a Jeloria scroll ?",
      () => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {});
      }
    );
  };

  static korath = (spaceObjectName, minorFaction) => {
    const selectedPlanets = [];

    InteractionFunctions.selectAPlanet((planet) => {
      console.log("DEBUG planet : ", planet);
      selectedPlanets.push(planet);
      InteractionFunctions.selectAPlanet((planet) => {
        selectedPlanets.push(planet);
        const planetNames = selectedPlanets.map((p) => p.name);
        UIMessage.displayConfirmMessage(
          minorFaction.name,
          "Do you want to place the mechs on " +
            planetNames.join(" and ") +
            " ?",
          () => {
            ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {
              planetNames: planetNames,
            });
            Popup.resetMapNavigationData();
          }
        );
      }, "Select the planet you want to place the second mech on");
    }, "Select the planet you want to place the first mech on");
  };

  static kobamda = (spaceObjectName, minorFaction) => {
    UIMessage.displayConfirmMessage(
      minorFaction.name,
      "Do you want to gain a Kobamda scroll ?",
      () => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {});
      }
    );
  };

  static laran = (spaceObjectName, minorFaction) => {
    InteractionFunctions.selectASystem((system) => {
      ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {
        systemName: system.name,
      });
      Popup.resetMapNavigationData();
    }, "Select a system to repair all your units in");
  };

  static maltrion = (spaceObjectName, minorFaction) => {
    UIMessage.displayConfirmMessage(
      minorFaction.name,
      "Do you want to gain a Maltrion scroll ?",
      () => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {});
      }
    );
  };

  static minari = (spaceObjectName, minorFaction) => {
    UIMessage.displayConfirmMessage(
      minorFaction.name,
      "Do you want to gain 6 minerals ?",
      () => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {});
      }
    );
  };

  static opharian = (spaceObjectName, minorFaction) => {
    UIMessage.displayConfirmMessage(
      minorFaction.name,
      "Do you want to gain 8 sciences ?",
      () => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {});
      }
    );
  };

  static prothian = (spaceObjectName, minorFaction) => {
    InteractionFunctions.selectASystem((system) => {
      ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {
        systemName: system.name,
      });
      Popup.resetMapNavigationData();
    }, "Select a system to place a dreadnought on");
  };

  static quoridiom = (spaceObjectName, minorFaction) => {
    UIMessage.displayConfirmMessage(
      minorFaction.name,
      "Do you want to gain a Quoridium scroll ?",
      () => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {});
      }
    );
  };

  static rynzorath = (spaceObjectName, minorFaction) => {
    UIMessage.displayConfirmMessage(
      minorFaction.name,
      "Do you want to gain a Rynzorath scroll ?",
      () => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {});
      }
    );
  };

  static seltaar = (spaceObjectName, minorFaction) => {
    UIMessage.displayConfirmMessage(
      minorFaction.name,
      "Do you want to gain a Seltaar scroll ?",
      () => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {});
      }
    );
  };

  static terran = (spaceObjectName, minorFaction) => {
    InteractionFunctions.selectAExiledFaction((exiledObject) => {
      ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {
        targetSpaceObjectName: exiledObject.name,
      });
    });
  };

  static taarkir = (spaceObjectName, minorFaction) => {
    UIMessage.displayConfirmMessage(
      minorFaction.name,
      "Do you want to gain a Taarkir scroll ?",
      () => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {});
      }
    );
  };

  static utarion = (spaceObjectName, minorFaction) => {
    const playerData = StaticGameData.getPlayerData();
    InteractionFunctions.selectAUnit((unit) => {
      const system = Map.getSystemFromUnitId(playerData.map, unit.id);
      ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {
        unitId: unit.id,
      });
    }, "Select a cruser to transform");
  };

  static voidborn = (spaceObjectName, minorFaction) => {
    UIMessage.displayConfirmMessage(
      minorFaction.name,
      "Do you want to gain a Voidborn scroll ?",
      () => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {});
      }
    );
  };

  static wolthaar = (spaceObjectName, minorFaction) => {
    UIMessage.displayConfirmMessage(
      minorFaction.name,
      "Do you want to use the ability of the " + minorFaction.name + " ?",
      () => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {});
      }
    );
  };

  static xelphar = (spaceObjectName, minorFaction) => {
    UIMessage.displayConfirmMessage(
      minorFaction.name,
      "Do you want to use the ability of the " + minorFaction.name + " ?",
      () => {
        ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {});
      }
    );
  };

  static xenos = (spaceObjectName, minorFaction) => {
    InteractionFunctions.selectPlanetsOnMap(1, 3, (planets) => {
      const planetNames = planets.map((p) => p.name);
      ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {
        planetNames: planetNames,
      });
    });
  };

  static zylorian = (spaceObjectName, minorFaction) => {
    InteractionFunctions.selectPlanetsOnMap(1, 3, (planets) => {
      const planetNames = planets.map((p) => p.name);
      ResolveMinorFactionAbility.sendToServerCommon(spaceObjectName, {
        planetNames: planetNames,
      });
    });
  };
}

export default MinorFactionAbilityInput;
