import React, { useRef, useState, useEffect, useMemo } from "react";
import useViewportSize from "./CustomHook/UseViewportSize";
import "../CSS/Menu.css";
import "../CSS/MapGrid.css";
import ArrowButton from "./Utils/ArrowButton";
import SystemView from "./Map/SystemView";
import System from "../Data/GameData/MapData/System";
import StaticGameData from "../Data/GameData/StaticGameData";
import Popup from "../Data/Other/Popup";
import TaskBarView from "./TaskBarView";
import NavigationView from "./NavigationView";
import GameGlobalLayout from "./GameGlobalLayout";
import ImagesUrl from "../Data/Other/ImagesUrl";
import UIMessage from "../Data/GameData/Connection/UIMessage";
import Phase from "../Data/GameData/Game/Phase";
import HomeSystemInfoView from "./Map/HomeSystemInfoView";
import Logo from "./Utils/Logo";
import MapClickCallBackView from "./Map/MapClickCallBackView.jsx";
import Color from "../Common/Config/Colors.js";
import PlayerData from "../Data/GameData/PlayerData/PlayerData.js";
import ObjectiveMapView from "./Map/ObjectiveMapView.jsx";
import BaseButton from "./Utils/BaseButton.jsx";
import DraftData from "../Data/GameData/Faction/DraftData.js";
import DraftView from "./Map/DraftView.jsx";
import LineSpace from "./Utils/Layout/LineSpace.jsx";
import BaseImage from "./Utils/BaseImage.jsx";
import { useRenderContextMapZoom } from "./Utils/Hooks/RenderContextMapZoom"; // Updated import

const CreateMap = require("../Data/GameData/MapData/CreateMap.js");
const Request = require("../Common/Requests/Request.js");

function MapView({ playerData, popup, mapLightData }) {
  //Hook for touch gestures

  //console.log("DEBUG RENDERING MapView");
  // Using the custom hook to manage viewport size
  const viewportSize = useViewportSize();
  //console.log("Viewport size: ", viewportSize);
  const map = playerData.map;
  //console.log("MapView map: ", map);

  const mapDisplayMode = Popup.mapDisplayMode;

  const mapRef = useRef(null);

  //Map size data

  const mapSizeX = map.systems[0].length;
  const mapSizeY = map.systems.length;

  //console.log("mapSizeX: ", mapSizeX);
  //console.log("mapSizeY: ", mapSizeY);

  const scrollBarWidth = 0;
  //const practicalMapSizeXPixels = Math.min(viewportSize.width - scrollBarWidth);
  /*const itemSizeX = Math.floor(
    ((viewportSize.width - scrollBarWidth) / mapSizeX) * 1
  );
  const spaceBetweenItems = Math.floor(
    ((viewportSize.width - scrollBarWidth) / mapSizeX) * 0
  );*/
  const itemSizeX = 200;
  const spaceBetweenItems = 0;
  const systemFontSize = itemSizeX / 8;
  const mapSizeXPixels =
    itemSizeX * mapSizeX + spaceBetweenItems * (mapSizeX - 1);

  const amountLineForHalfGrid = 0;
  let gapBetween2Grids = 0; //100; //In px
  const dotElementSizeYPixels = itemSizeX;

  if (amountLineForHalfGrid <= 0) {
    gapBetween2Grids = 0;
  }
  const halfGridSizeYPixels =
    itemSizeX * amountLineForHalfGrid +
    spaceBetweenItems * Math.max(amountLineForHalfGrid - 1, 0) +
    gapBetween2Grids;

  const mapConnectionSizeYPixels = 80;

  const middleMapSizeYPixels =
    itemSizeX * mapSizeY + spaceBetweenItems * (mapSizeY - 1);

  const mapSizeYPixels =
    itemSizeX * mapSizeY +
    spaceBetweenItems * (mapSizeY - 1) +
    mapConnectionSizeYPixels * 2;

  /*+
    halfGridSizeYPixels * 2 +
    dotElementSizeYPixels * 2;*/

  //Map move data

  const widthAreaMapAlwaysVisible = 200;

  //Correct the position of the map to ease the player to see his home system in the center
  const homeSystemCoords = playerData.homeSystemCoords;
  const targetRowForPlayer = Math.floor(mapSizeY / 2);
  const decalageHSY = homeSystemCoords
    ? targetRowForPlayer - homeSystemCoords.y
    : 0;
  const decalageToTop = Math.min(0, decalageHSY);
  const decalageToBottom = Math.max(0, decalageHSY);
  const decalageToTopPixels = decalageToTop * (itemSizeX + spaceBetweenItems);
  const decalageToBottomPixels =
    decalageToBottom * (itemSizeX + spaceBetweenItems);

  /*const getMapMoveData = () => {
    const data = Popup.getGlobalState("mapMoveData");
    if (data) {
      return { ...data, isDown: false, isOnLeave: false, isMoving: false };
    } else {
      return {
        mapX: 0,
        mapY: 0,
        previousMouseX: 0,
        previouxMouseY: 0,
        initialDistancePinch: 0,
        centerPinchX: 0,
        centerPinchY: 0,
        zoom: 1,
        previousZoom: 1,
        isDown: false,
        isOnLeave: false,
        isMoving: false,
      };
    }
  };
  const initialMapMoveData = useMemo(() => {
    return getMapMoveData();
  }, []);*/

  const [mapMoveData, setMapMoveData] = useState({
    mapX: Popup.firstMapRender
      ? -mapSizeXPixels / 2 + 200
      : Popup.mapTranslateMemory.x,
    mapY: Popup.firstMapRender
      ? -middleMapSizeYPixels / 2 + 400
      : Popup.mapTranslateMemory.y,
    previousMouseX: 0,
    previouxMouseY: 0,
    initialDistancePinch: 0,
    centerPinchX: 0,
    centerPinchY: 0,
    zoom: Popup.mapScaleMemory,
    previousZoom: 1,
    isDown: false,
    isOnLeave: false,
    isMoving: false,
    isFirstRender: true,
  });

  //Use context to trigger component who should render when map zoom changes
  const { updateRenderValue } = useRenderContextMapZoom(); // Use the context
  // Update zoom in the context when mapMoveData.zoom changes
  useEffect(() => {
    updateRenderValue(mapMoveData.zoom);
  }, [mapMoveData.zoom, updateRenderValue]);

  Popup.setFirstMapRender(false);

  // Use a useEffect hook to watch for changes in mapMoveData and record in PopupGLobal
  /* useEffect(() => {
    Popup.addGlobalState("mapMoveData", mapMoveData);
  }, [mapMoveData]);*/

  const mapXRef = useRef(mapMoveData.mapX);
  const mapYRef = useRef(mapMoveData.mapY);
  const zoomRef = useRef(mapMoveData.zoom);
  useEffect(() => {
    // Update refs when mapMoveData changes
    mapXRef.current = mapMoveData.mapX;
    mapYRef.current = mapMoveData.mapY;
    zoomRef.current = mapMoveData.zoom;
  }, [mapMoveData]);

  useEffect(() => {
    // Code to run on mount
    console.log("LOAD Popup.mapTranslateMemory : ", Popup.mapTranslateMemory);

    return () => {
      // Code to run on unmount
      Popup.mapTranslateMemory = { x: mapXRef.current, y: mapYRef.current };
      Popup.mapScaleMemory = zoomRef.current;

      console.log(
        "UPLOAD Popup.mapTranslateMemory : ",
        Popup.mapTranslateMemory
      );
      console.log(
        "UPLOAD Popup.mapTranslateMemory mapMoveData.mapX : ",
        mapXRef.current
      );
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup runs on unmount

  //Function to control map move

  const handleMouseDown = (e) => {
    //e.preventDefault();
    setMapMoveData((prev) => {
      return {
        ...prev,
        previousMouseX: e.clientX,
        previouxMouseY: e.clientY,
        previousMapX: prev.mapX,
        previousMapY: prev.mapY,
        isDown: true,
        isOnLeave: false,
      };
    });
  };

  const handleMouseUp = (e) => {
    //e.preventDefault();

    if (mapMoveData.isMoving) {
      e.stopPropagation();
    } else {
      //getObjectClicked(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
      getObjectClicked(e, e.clientX, e.clientY);
    }
    setMapMoveData((prev) => {
      return {
        ...prev,
        isDown: false,
        isOnLeave: false,
        isMoving: false,
      };
    });
  };

  const capMapX = (mapX) => {
    return mapX;
    const minLeft =
      -(mapSizeXPixels / 2) * (1 + mapMoveData.zoom) +
      widthAreaMapAlwaysVisible;
    const maxLeft = viewportSize.width - widthAreaMapAlwaysVisible;

    if (mapX < minLeft) {
      mapX = minLeft;
    }
    if (mapX > maxLeft) {
      mapX = maxLeft;
    }
    return mapX;
  };

  const capMapY = (mapY) => {
    return mapY;
    const minTop =
      -(mapSizeYPixels / 2) * (1 + mapMoveData.zoom) +
      widthAreaMapAlwaysVisible;
    const maxTop = viewportSize.height - widthAreaMapAlwaysVisible;
    if (mapY < minTop) {
      mapY = minTop;
    }
    if (mapY > maxTop) {
      mapY = maxTop;
    }
    //console.log("maxTop: ", maxTop);
    return mapY;
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    if (mapMoveData.isDown === true && mapMoveData.isOnLeave === false) {
      //console.log("handleMouseMove client :", e.clientX);
      //console.log("handleMouseMove mapMoveData :", mapMoveData.previousMouseX);
      const deltaX = e.clientX - mapMoveData.previousMouseX;
      const deltaY = e.clientY - mapMoveData.previouxMouseY;
      let newMapX = capMapX(mapMoveData.previousMapX + deltaX);
      let newMapY = capMapY(mapMoveData.previousMapY + deltaY);

      setMapMoveData((prev) => {
        return {
          ...prev,
          //previousMouseX: e.clientX,
          //previouxMouseY: e.clientY,
          mapX: newMapX,
          mapY: newMapY,
          isMoving: true,
        };
      });
    }
  };

  const handleMouseLeave = (e) => {
    //console.log("handleMouseLeave");

    e.preventDefault();
    setMapMoveData((prev) => {
      return {
        ...prev,
        status: "idle",
        isOnLeave: true,
        isDown: false,
        isMoving: false,
      };
    });
  };

  const getObjectClicked = (e, xP, yP) => {
    const targetElement = mapRef.current;
    // Get the bounding box of the target element
    const boundingBox = targetElement.getBoundingClientRect();
    const x = xP - boundingBox.left;
    const y = yP - boundingBox.top;

    let correctedX = x / mapMoveData.zoom;
    /*let correctedY =
      y / mapMoveData.zoom -
      gapBetween2Grids -
      itemSizeX * amountLineForHalfGrid -
      spaceBetweenItems * Math.max(0, amountLineForHalfGrid - 1) -
      dotElementSizeYPixels;*/
    let correctedY = y / mapMoveData.zoom - mapConnectionSizeYPixels;
    if (correctedY < 0 || correctedY > middleMapSizeYPixels) {
      return;
    }

    //Correct X if the map is reversed
    if (map.reverseConnections) {
      if (
        correctedY < decalageToBottomPixels ||
        correctedY > middleMapSizeYPixels + decalageToTopPixels
      ) {
        const indexTempX = Math.floor(
          correctedX / (itemSizeX + spaceBetweenItems)
        );
        const correctionIndexTempX = mapSizeX - 1 - indexTempX;
        const indexTargetTempX = (indexTempX - 1) * -1 + 2;
        const deltaIndexX = indexTargetTempX - indexTempX;
        correctedX = correctedX + deltaIndexX * (itemSizeX + spaceBetweenItems);
      }
    }

    //Corrrect the y due to decalage
    correctedY = correctedY + (-decalageToBottomPixels - decalageToTopPixels);
    correctedY =
      ((correctedY % middleMapSizeYPixels) + middleMapSizeYPixels) %
      middleMapSizeYPixels;

    let indexX = Math.floor(correctedX / (itemSizeX + spaceBetweenItems));
    const indexY = Math.floor(correctedY / (itemSizeX + spaceBetweenItems));

    if (indexX >= 0 && indexX < mapSizeX && indexY >= 0 && indexY < mapSizeY) {
      e.preventDefault();
      const xPercent =
        (correctedX % (itemSizeX + spaceBetweenItems)) / itemSizeX;
      const yPercent =
        (correctedY % (itemSizeX + spaceBetweenItems)) / itemSizeX;
      const system = map.systems[indexY][indexX];

      const itemClicked = System.getItemClicked(
        system,
        xPercent,
        yPercent,
        Popup.mapClickCallbackReturnOnlyType
      );

      if (Popup.mapClickCallback) {
        Popup.mapClickCallback(itemClicked);
      } else {
        /*Popup.addAttributes({
          mapClicked: true,
          targetItem: itemClicked,
        });*/
        MapClickCallBackView.routeClick(playerData, itemClicked);
        //Popup.addLayer({ name: "ManageSystemView", system: itemClicked });
      }
    }
  };

  useEffect(() => {
    const handleWheel = (event) => {
      //event.preventDefault();
      if (event.ctrlKey) {
        event.preventDefault();
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const handleWheel = (ev) => {
    //console.log("handleWheel : " + ev.deltaY);

    let isPinch = Math.abs(ev.deltaY) < 50;

    if (isPinch) {
      // This is a pinch on a trackpad
      let factor = 1 - 0.01 * ev.deltaY;
      setMapMoveData((prev) => {
        return {
          ...prev,
          zoom: Math.min(1, prev.zoom * factor),
        };
      });
    } else {
      // This is a mouse wheel
      let strength = 1.1;
      let factor = ev.deltaY < 0 ? strength : 1.0 / strength;
      //console.log("(ev.clientX - prev.mapX): ", ev.clientX - mapMoveData.mapX);
      //console.log("(ev.clientX): ", ev.clientX);
      //console.log("correction : ", ev.clientX * (1 - factor));
      //console.log("(prev.mapX): ", mapMoveData.mapX);
      const newZoom = mapMoveData.zoom * factor;
      if (newZoom < 0.1 || newZoom > 10) return;
      setMapMoveData((prev) => {
        return {
          ...prev,
          zoom: newZoom,
          mapX:
            prev.mapX +
            (ev.clientX - prev.mapX - mapSizeXPixels / 2) * (1 - factor),
          mapY:
            prev.mapY +
            (ev.clientY - prev.mapY - mapSizeYPixels / 2) * (1 - factor),
        };
      });
    }
  };

  // Handle touchstart event
  const [mobileConsole, setMobileConsole] = useState("idle");
  const [amountMobileTouches, setAmountMobileTouches] = useState(0);
  const [isMovingMobile, setIsMovingMobile] = useState(false);
  const [mobileFirstTouchCoordinates, setMobileFirstTouchCoordinates] =
    useState({
      x: 0,
      y: 0,
    });
  const handleTouchStart = (e) => {
    setMobileConsole("touching ");
    console.log("MOBILE touching");
    //UIMessage.displayInfoMessage("handleWheel", "You are mouse wheeling");

    if (e.touches.length === 1) {
      console.log("MOBILE 1 touch");
      setMobileFirstTouchCoordinates({
        x: e.touches[0].pageX,
        y: e.touches[0].pageY,
      });
      if (amountMobileTouches < 1) {
        setAmountMobileTouches(1);
      }
      setMapMoveData((prev) => {
        return {
          ...prev,
          previousMouseX: e.touches[0].pageX,
          previouxMouseY: e.touches[0].pageY,
          previousMapX: prev.mapX,
          previousMapY: prev.mapY,
          isDown: true,
          isOnLeave: false,
          isMoving: true,
        };
      });
    }

    //e.preventDefault();
    if (e.touches.length === 2) {
      console.log("MOBILE 2 touch");
      setAmountMobileTouches(2);
      // Get the initial distance between two fingers
      const initialDistance = getDistance(e.touches[0], e.touches[1]);
      const initialCenter = {
        x: (e.touches[0].pageX + e.touches[1].pageX) / 2,
        y: (e.touches[0].pageY + e.touches[1].pageY) / 2,
      };
      // Store the initial distance in the map ref
      setMapMoveData((prev) => {
        return {
          ...prev,
          initialDistancePinch: initialDistance,
          centerPinchX: initialCenter.x,
          centerPinchY: initialCenter.y,
          previousMapX: prev.mapX,
          previousMapY: prev.mapY,
          previousZoom: prev.zoom,
          isMoving: false,
        };
      });
    }
  };

  // Handle touchmove event
  const handleTouchMove = (ev) => {
    //ev.preventDefault(); // Prevent scrolling
    console.log("MOBILE touchMove");
    if (ev.touches.length === 1 && mapMoveData.isMoving) {
      console.log("MOBILE mooving");
      setMobileConsole("mooving");
      setIsMovingMobile(true);
      const deltaX = ev.touches[0].pageX - mapMoveData.previousMouseX;
      const deltaY = ev.touches[0].pageY - mapMoveData.previouxMouseY;
      let newMapX = capMapX(mapMoveData.previousMapX + deltaX);
      let newMapY = capMapY(mapMoveData.previousMapY + deltaY);

      setMapMoveData((prev) => {
        return {
          ...prev,
          //previousMouseX: e.clientX,
          //previouxMouseY: e.clientY,
          mapX: newMapX,
          mapY: newMapY,
          isMoving: true,
        };
      });
      return;
    }
    if (ev.touches.length === 2) {
      // Calculate the new distance between two fingers
      const newDistance = getDistance(ev.touches[0], ev.touches[1]);
      // Calculate the scale change
      const scaleChange = newDistance / mapMoveData.initialDistancePinch;
      // Calculate the new scale
      let newScale = mapMoveData.previousZoom * scaleChange;
      if (newScale < 0.1 || newScale > 10) return;
      // Update the scale state
      setMobileConsole(
        "zooming : centerX : " +
          mapMoveData.centerPinchX +
          ", new scale : " +
          newScale
      );
      setMapMoveData((prev) => {
        return {
          ...prev,
          zoom: newScale,
          mapX:
            prev.previousMapX +
            (mapMoveData.centerPinchX -
              prev.previousMapX -
              mapSizeXPixels / 2) *
              (1 - scaleChange),
          mapY:
            prev.previousMapY +
            (mapMoveData.centerPinchY -
              prev.previousMapY -
              mapSizeYPixels / 2) *
              (1 - scaleChange),
        };
      });
    }
  };

  const handleTouchEnd = (e) => {
    console.log("handleTouchEnd", e);
    if (amountMobileTouches === 1 && !isMovingMobile) {
      getObjectClicked(
        e,
        mobileFirstTouchCoordinates.x,
        mobileFirstTouchCoordinates.y
      );
    }

    setMobileConsole("idle");
    setAmountMobileTouches(0);
    setIsMovingMobile(false);

    //e.preventDefault(); // Prevent scrolling
    setMapMoveData((prev) => {
      return {
        ...prev,
        isMoving: false,
        isDown: false,
        isOnLeave: false,
      };
    });
  };

  // Calculate the distance between two touch points
  const getDistance = (touch1, touch2) => {
    const dx = touch1.pageX - touch2.pageX;
    const dy = touch1.pageY - touch2.pageY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  //Task Bar data
  if (!map.systems[0]) {
    throw new Error("Map has no systems");
  }

  /*const getTaskBarData = () => {
    if (!Popup.mode) {
      return null;
    }

    if (Popup.mode.name === Popup.MODE_CHOOSE_PLANET_COLONIZE) {
      console.log("MapView Popup.mode: ", Popup.mode);
      return {
        text:
          "Choose a planet to colonize. You have " +
          playerData.colonists +
          " colonists left.",
      };
    }
  };
  const taskBarData = getTaskBarData();*/

  let navigationData = Popup.getMapNavigationDataInstance();
  if (!navigationData) {
    navigationData = {
      isMapStandardNavigationData: true,
      isComingFromMapView: true,
      buttonsData: [],
    };
  }
  if (!navigationData.buttonsData || navigationData.buttonsData.length === 0) {
    navigationData.buttonsData = [];
    navigationData.isComingFromMapView = true;
    if (playerData.gameVersion && playerData.gameVersion > 0.1) {
      navigationData.buttonsData.push({
        text: "Chat",
        content: <Logo logoName="chat icon"></Logo>,
        callback: () => {
          Popup.addLayer({ name: "MainChatView" });
        },
      });
    }
    let helpReference = "presentation";

    navigationData.buttonsData.push(
      /*{
        text: "?",

        callback: () => {
          Popup.addLayer({
            name: "HelpPopup",
            helpReference: helpReference,
          });
        },
      },*/
      {
        text: "Menu",
        content: <Logo logoName="menu icon"></Logo>,
        callback: () => {
          Popup.addLayer({ name: "GameMenuView" });
        },
      }
    );
  }

  const taskBarData = Popup.mapTaskBarData
    ? JSON.parse(JSON.stringify(Popup.mapTaskBarData))
    : null;

  if (Popup.mapInteractionMode === Popup.MAP_INTERACTION_MODE_NORMAL) {
    /*navigationData.buttonsData.push(
      {
        text: "Menu",
        callback: () => {
          Popup.addLayer({ name: "GameMenuView" });
        },
      },
      {
        text: "Factions",
        callback: () => {
          Popup.addLayer({ name: "GameFactionsView" });
        },
      },
      {
        text: "Display",
        callback: () => {
          Popup.switchMapDisplayMode();
        },
      }
    );*/
  }

  if (Popup.mapInteractionMode === Popup.MAP_INTERACTION_MODE_MOVE) {
    /*navigationData.buttonsData.push(
      {
        text: "Confirm Move",
        callback: () => {
          Popup.addLayer({ name: "GameMenuView" });
        },
      },
      {
        text: "Cancel Move",
        callback: () => {
          Popup.addLayer({ name: "GameFactionsView" });
        },
      }
    );*/
  }

  // Memoize GridItems
  const GridItems = useMemo(() => {
    //console.log("DEBUG RENDERING MapView GridItems");
    const items = [];
    for (let y = 0 - decalageToTop; y < mapSizeY - decalageToBottom; y++) {
      for (let x = 0; x < mapSizeX; x++) {
        const key = `${y};${x}`;

        items.push(
          <div key={key} className="div-fill">
            <SystemView
              system={map.systems[y][x]}
              sizeX={itemSizeX}
              sizeY={itemSizeX}
              mapDisplayMode={mapDisplayMode}
              mapLightData={mapLightData}
              popup={popup}
              mapZoom={mapMoveData.zoom}
            />
          </div>
        );
      }
    }
    return items;
  }, [
    map,
    mapDisplayMode,
    itemSizeX,
    popup,
    //,     mapMoveData.zoom
  ]);

  // Memoize GridItems
  const AfterGridItems = useMemo(() => {
    const items = [];

    for (let y = 0; y < 0 - decalageToTop; y++) {
      if (!map.reverseConnections) {
        for (let x = 0; x < mapSizeX; x++) {
          const key = `${y};${x}`;

          items.push(
            <div key={key} className="div-fill">
              <SystemView
                system={map.systems[y][x]}
                sizeX={itemSizeX}
                sizeY={itemSizeX}
                mapDisplayMode={mapDisplayMode}
                mapLightData={mapLightData}
                popup={popup}
                mapZoom={mapMoveData.zoom}
                //popup={popup}
              />
            </div>
          );
        }
      } else {
        for (let x = mapSizeX - 1; x >= 0; x--) {
          const key = `${y};${x}`;

          items.push(
            <div key={key} className="div-fill">
              <SystemView
                system={map.systems[y][x]}
                sizeX={itemSizeX}
                sizeY={itemSizeX}
                mapDisplayMode={mapDisplayMode}
                popup={popup}
                mapZoom={mapMoveData.zoom}
                //popup={popup}
              />
            </div>
          );
        }
      }
    }
    return items;
  }, [
    map,
    mapDisplayMode,
    itemSizeX,
    popup,
    //, mapMoveData.zoom
  ]);

  // Memoize GridItems
  const BeforeGridItems = useMemo(() => {
    const items = [];
    for (let y = mapSizeY - decalageToBottom; y < mapSizeY; y++) {
      if (!map.reverseConnections) {
        for (let x = 0; x < mapSizeX; x++) {
          const key = `${y};${x}`;

          items.push(
            <div key={key} className="div-fill">
              <SystemView
                system={map.systems[y][x]}
                sizeX={itemSizeX}
                sizeY={itemSizeX}
                mapDisplayMode={mapDisplayMode}
                mapLightData={mapLightData}
                popup={popup}
                mapZoom={mapMoveData.zoom}
                //popup={popup}
              />
            </div>
          );
        }
      } else {
        for (let x = mapSizeX - 1; x >= 0; x--) {
          const key = `${y};${x}`;

          items.push(
            <div key={key} className="div-fill">
              <SystemView
                system={map.systems[y][x]}
                sizeX={itemSizeX}
                sizeY={itemSizeX}
                mapDisplayMode={mapDisplayMode}
                popup={popup}
                mapZoom={mapMoveData.zoom}
                //popup={popup}
              />
            </div>
          );
        }
      }
    }
    return items;
  }, [
    map,
    mapDisplayMode,
    itemSizeX,
    popup,
    //, mapMoveData.zoom
  ]);

  // Memoize GridItems
  /*const GridItemsHalfTop = useMemo(() => {
    const items = [];
    for (let y = 0; y < amountLineForHalfGrid; y++) {
      for (let x = 0; x < mapSizeX; x++) {
        const key = `${y};${x}`;

        items.push(
          <div key={key} className="div-fill">
            <SystemView
              system={map.systems[y][x]}
              sizeX={itemSizeX}
              sizeY={itemSizeX}
              mapDisplayMode={mapDisplayMode}
              popup={popup}
                mapZoom={mapMoveData.zoom}
              //popup={popup}
            />
          </div>
        );
      }
    }
    return items;
  }, [map, mapDisplayMode, itemSizeX, popup]);*/

  // Memoize GridItems
  /*const GridItemsHalfBottom = useMemo(() => {
    const items = [];
    for (let y = mapSizeY - amountLineForHalfGrid; y < mapSizeY; y++) {
      for (let x = 0; x < mapSizeX; x++) {
        const key = `${y};${x}`;

        items.push(
          <div key={key} className="div-fill">
            <SystemView
              system={map.systems[y][x]}
              sizeX={itemSizeX}
              sizeY={itemSizeX}
              mapDisplayMode={mapDisplayMode}
              popup={popup}
                mapZoom={mapMoveData.zoom}
              //popup={popup}
            />
          </div>
        );
      }
    }
    return items;
  }, [map, mapDisplayMode, itemSizeX, popup]);*/

  // Memoize Grid
  const Grid = useMemo(() => {
    //console.log("DEBUG RENDERING MapView Grid");
    return (
      <div>
        {" "}
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${mapSizeX}, ${itemSizeX}px)`,
              gridTemplateRows: `repeat(${
                mapSizeY + decalageToTop - decalageToBottom
              }, ${itemSizeX}px)`, // Set row height here
              gap: spaceBetweenItems + "px",
              padding: "0",
              border: "0",
              //backgroundColor: "purple",
            }}
          >
            {GridItems}
          </div>
        </div>
        <HomeSystemInfoView
          systemSize={itemSizeX}
          gapBetweenSystems={spaceBetweenItems}
          decallageY={
            decalageToBottomPixels +
            decalageToTopPixels +
            mapConnectionSizeYPixels
          }
          minY={-decalageToTop}
          maxY={mapSizeY - decalageToBottom - 1}
          mapSizeX={mapSizeX}
        ></HomeSystemInfoView>
      </div>
    );
  }, [
    viewportSize,
    mapSizeX,
    mapSizeY,
    itemSizeX,
    spaceBetweenItems,
    playerData,
    popup,
    //mapMoveData.zoom,
    //GridItems,
  ]);

  // Memoize Grid
  const AfterGrid = useMemo(() => {
    //console.log("RENDERING After Grid");
    return (
      <div>
        {" "}
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${mapSizeX}, ${itemSizeX}px)`,
              gridTemplateRows: `repeat(${decalageToTop}, ${itemSizeX}px)`, // Set row height here
              gap: spaceBetweenItems + "px",
              padding: "0",
              border: "0",
            }}
          >
            {AfterGridItems}
          </div>
        </div>
        <HomeSystemInfoView
          systemSize={itemSizeX}
          gapBetweenSystems={spaceBetweenItems}
          decallageY={
            decalageToBottomPixels +
            decalageToTopPixels +
            middleMapSizeYPixels +
            mapConnectionSizeYPixels
          }
          minY={0}
          maxY={-decalageToTop - 1}
          reverseConnections={map.reverseConnections}
          mapSizeX={mapSizeX}
        ></HomeSystemInfoView>
      </div>
    );
  }, [
    viewportSize,
    mapSizeX,
    mapSizeY,
    itemSizeX,
    spaceBetweenItems,
    playerData,
    popup,
    //mapMoveData.zoom,
    //GridItems,
  ]);

  // Memoize Grid
  const BeforeGrid = useMemo(() => {
    //console.log("RENDERING After Grid");
    return (
      <div>
        {" "}
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${mapSizeX}, ${itemSizeX}px)`,
              gridTemplateRows: `repeat(${decalageToBottom}, ${itemSizeX}px)`, // Set row height here
              gap: spaceBetweenItems + "px",
              padding: "0",
              border: "0",
            }}
          >
            {BeforeGridItems}
          </div>
        </div>
        <HomeSystemInfoView
          systemSize={itemSizeX}
          gapBetweenSystems={spaceBetweenItems}
          decallageY={
            -middleMapSizeYPixels +
            decalageToBottomPixels +
            decalageToTopPixels +
            mapConnectionSizeYPixels
          }
          minY={mapSizeY - decalageToBottom}
          maxY={mapSizeY - 1}
          reverseConnections={map.reverseConnections}
          mapSizeX={mapSizeX}
        ></HomeSystemInfoView>
      </div>
    );
  }, [
    viewportSize,
    mapSizeX,
    mapSizeY,
    itemSizeX,
    spaceBetweenItems,
    playerData,
    popup,
    //mapMoveData.zoom,
    //GridItems,
  ]);

  // Memoize Grid
  /*const halfGridTop = useMemo(() => {
    console.log("RENDERING halfGridTop");
    return (
      <div>
        {" "}
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${mapSizeX}, ${itemSizeX}px)`,
              gridTemplateRows: `repeat(${amountLineForHalfGrid}, ${itemSizeX}px)`, // Set row height here
              gap: spaceBetweenItems + "px",
              padding: "0",
              border: "0",
            }}
          >
            {GridItemsHalfTop}
          </div>
        </div>
        <HomeSystemInfoView
          systemSize={itemSizeX}
          gapBetweenSystems={spaceBetweenItems}
          decallageY={
            +middleMapSizeYPixels +
            gapBetween2Grids +
            halfGridSizeYPixels +
            dotElementSizeYPixels
          }
          reverseConnection={map.reverseConnection}
          mapSizeX={mapSizeX}
        ></HomeSystemInfoView>
      </div>
    );
  }, [
    viewportSize,
    mapSizeX,
    mapSizeY,
    itemSizeX,
    spaceBetweenItems,
    playerData,
    //GridItems,
  ]);

  // Memoize Grid
  const halfGridBottom = useMemo(() => {
    console.log("RENDERING halfGridBottom");
    return (
      <div>
        {" "}
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${mapSizeX}, ${itemSizeX}px)`,
              gridTemplateRows: `repeat(${amountLineForHalfGrid}, ${itemSizeX}px)`, // Set row height here
              gap: spaceBetweenItems + "px",
              padding: "0",
              border: "0",
            }}
          >
            {GridItemsHalfBottom}
          </div>
        </div>
        <HomeSystemInfoView
          systemSize={itemSizeX}
          gapBetweenSystems={spaceBetweenItems}
          decallageY={
            -middleMapSizeYPixels -
            gapBetween2Grids +
            halfGridSizeYPixels +
            dotElementSizeYPixels
          }
          reverseConnection={map.reverseConnection}
          mapSizeX={mapSizeX}
        ></HomeSystemInfoView>
        {false && (
          <div
            style={{
              position: "absolute",
              top: dotElementSizeYPixels / 2 + "px",
              left: 0,
              width: "100%",
              height:
                halfGridSizeYPixels -
                gapBetween2Grids +
                dotElementSizeYPixels / 2 +
                "px",
              backgroundColor: "RGB(0,0,0,0.3)",
            }}
          ></div>
        )}
      </div>
    );
  }, [
    viewportSize,
    mapSizeX,
    mapSizeY,
    itemSizeX,
    spaceBetweenItems,
    playerData,
    //GridItems,
  ]);*/

  const DisplayDots = ({ position }) => {
    const numberOfDots = mapSizeX; // Adjust the number of dots as needed
    let top = position === "top";

    const VerticalLine = () => {
      return (
        <div
          style={{
            position: "absolute",
            top: top ? "50%" : 0,
            bottom: top ? 0 : "50%",
            left: "50%",
            transform:
              "translateX(-50%)" /* Adjust to perfectly center the line */,
            width: "2px" /* Thickness of the vertical line */,
            borderStyle: "dotted",
            borderWidth: "5px",
            color: Color.COLOR_SYSTEM_BORDER,
            borderLeft: "none",
            borderBottom: "none",
            borderTop: "none",
            height: "50%",
          }}
        ></div>
      );
    };

    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${mapSizeX}, ${itemSizeX}px)`,
        }}
      >
        {[...Array(numberOfDots)].map((_, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              width: itemSizeX,
              height: itemSizeX,
            }}
          >
            <VerticalLine></VerticalLine>
          </div>
        ))}
      </div>
    );
  };

  const DisplayMapConnections = ({ direction }) => {
    const imageUrls = [
      ImagesUrl.MAP_CONNECTION_IMAGE_BASE + "1",
      ImagesUrl.MAP_CONNECTION_IMAGE_BASE + "2",
      ImagesUrl.MAP_CONNECTION_IMAGE_BASE + "3",
      ImagesUrl.MAP_CONNECTION_IMAGE_BASE + "4",
    ];
    if (map.reverseConnections && direction === "down") {
      {
        //Reverse order of imageUrls
        imageUrls.reverse();
      }
    }
    /*let imageUrl =
      ImagesUrl.MAP_CONNECTION_IMAGE_BASE +
      system.mapConnection.number +
      ".png";
    let numberUrl =
      ImagesUrl.MAP_CONNECTION_NUMBER_BASE +
      system.mapConnection.number +
      ".png";*/
    const imageStyle = {
      //If maxWidth defined, use it here, otherwise use 100%
      maxWidth: "100%",
      maxHeight: "100%",
      height: "auto",
      //Disable focus mouse
      pointerEvents: "none",
    };

    if (direction === "up") {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${mapSizeX}, ${itemSizeX}px)`,
            pointerEvents: "none", // Disables all pointer events
            height: mapConnectionSizeYPixels + "px",
            // Additional styles as needed
          }}
        >
          {imageUrls.map((url, index) => {
            return (
              <div
                key={index}
                style={{
                  position: "relative",
                  width: itemSizeX,
                  height: mapConnectionSizeYPixels,
                }}
              >
                <BaseImage
                  style={imageStyle}
                  src={url + ".png"}
                  alt="connection"
                  onlyOneRes={true}
                  tabIndex="-1" // Prevent focus on the div
                ></BaseImage>
              </div>
            );
          })}
        </div>
      );
    }
    if (direction === "down") {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${mapSizeX}, ${itemSizeX}px)`,
            pointerEvents: "none",
            //top: +170,
            //left: 0,
            transform: "scaleY(-1)",
            height: mapConnectionSizeYPixels + "px",
          }}
        >
          {imageUrls.map((url, index) => {
            return (
              <div
                key={index}
                style={{
                  position: "relative",
                  width: itemSizeX,
                  height: mapConnectionSizeYPixels,
                }}
              >
                <BaseImage
                  style={imageStyle}
                  src={url + ".png"}
                  alt="connection"
                  tabIndex="-1" // Prevent focus on the div
                  onlyOneRes={true}
                ></BaseImage>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const displayObjectiveView = () => {
    return <ObjectiveMapView></ObjectiveMapView>;
  };

  const displayDraftView = () => {
    return (
      <div>
        <LineSpace></LineSpace>
        <DraftView draftData={playerData.draftData}></DraftView>{" "}
      </div>
    );
  };

  return (
    <div
      className="div-fill"
      //onTouchStart={(e) => {
      //  e.preventDefault();
      //}}
      //onTouchMove={(e) => {
      //  e.preventDefault();
      //}}
    >
      <GameGlobalLayout mode="map">
        <div
          className="div-fill"
          style={{
            //fontSize: "50px",
            //display: "block",
            position: "relative",
            //backgroundColor: "RGBA(22,36,63)",
            color: "white",
            overflow: "hidden", // Add this line for the scrollbar
            //width: "90%",
            //height: "90%",
            //backgroundColor: "blue",
            touchAction: "none",
          }}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onWheel={handleWheel}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          //onMouseLeave={handleMouseLeave}
        >
          <div style={{ backgroundColor: "blue" }}>
            <div
              style={{
                position: "absolute",
                top: mapMoveData.mapY + "px",
                left: mapMoveData.mapX + "px",

                transform: "scale(" + mapMoveData.zoom + ")",
                //backgroundColor: "yellow",
                //left: -itemSizeX * (1 + indexInBetween - firstColumnIndex) + "px",
              }}
              ref={mapRef}
            >
              {false && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "4px",
                    backgroundColor: "green",
                  }}
                ></div>
              )}
              <DisplayMapConnections direction="up"></DisplayMapConnections>

              {BeforeGrid}
              {Grid}
              {AfterGrid}

              <DisplayMapConnections direction="down"></DisplayMapConnections>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translate(-50%, -100%)",
                  //backgroundColor: "blue",
                  width: "100%",
                }}
              >
                {displayObjectiveView()}

                {true &&
                  playerData.gameParam &&
                  playerData.gameParam.draft &&
                  !DraftData.isDraftFinished(playerData.draftData) &&
                  displayDraftView()}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: +mapSizeYPixels + "px",
                  left: "50%",
                  transform: "translate(-50%, 0%)",
                  width: "100%",
                  //backgroundColor: "blue",
                }}
              >
                {false &&
                  playerData.gameParam &&
                  playerData.gameParam.draft &&
                  !DraftData.isDraftFinished(playerData.draftData) && (
                    <div>
                      {" "}
                      <DraftView
                        draftData={playerData.draftData}
                      ></DraftView>{" "}
                      <LineSpace></LineSpace>{" "}
                    </div>
                  )}

                {displayObjectiveView()}
              </div>
            </div>
          </div>
        </div>
        <NavigationView navigationData={navigationData}></NavigationView>
      </GameGlobalLayout>
      {false && (
        <div
          style={{
            position: "absolute",
            top: 40,
            left: 40,
            fontSize: "1.5em",
            backgroundColor: "grey",
          }}
        >
          {mobileConsole}, {amountMobileTouches},{" "}
          {isMovingMobile ? "moving" : "not moving"}
        </div>
      )}
    </div>
  );
}

export default MapView;
