const PlayerData = require("../GameData/PlayerData/PlayerData.js");
const Rules = require("../GameData/Game/Rules.js");
const System = require("../GameData/MapData/System.js");
const Unit = require("../GameData/MapData/Unit.js");
const Map = require("../GameData/MapData/Map.js");
const Fleet = require("../GameData/MapData/Fleet.js");
const LogBook = require("../GameData/Connection/LogBook.js");
const LogMessage = require("../GameData/Connection/LogMessage.js");
const LogAttachment = require("../GameData/Connection/LogAttachment.js");
const ChatItem = require("./ChatItem.js");

class ChatRoom {
  static createCommonRoom(id) {
    return {
      itemList: [],
      id: id,
      lastItemId: 0,
    };
  }

  static createFactionRoom(player, id) {
    const factionPickList = player.factionPickList;
    const factionName = player.faction;
    const itemList = [];

    return {
      factionName: factionName,
      factionPickList: factionPickList,
      itemList: itemList,
      playerInGameId: player.playerInGameId,
      id: id,
      lastItemId: 0,
    };
  }

  static getChatItems(room) {
    return room.itemList;
  }

  //Chat will work with the generateMessageMethod
  //Message //add
  /*static addChatItemText(room, text, playerInGameId) {
    const itemList = room.itemList;
    const chatItem = ChatItem.createMessageText(text, playerInGameId);

    itemList.push(chatItem);
    if (itemList.length > 100) {
      itemList.shift();
    }
  }*/

  static requestGetChatItems(room) {}

  static test() {}
}

module.exports = ChatRoom;
