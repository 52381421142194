const LogBook = require("../Connection/LogBook.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const Cost = require("../Utils/Cost.js");
const Color = require("../../../Common/Config/Colors.js");
const AbilityCommon = require("../Ability/AbilityCommon.js");
const UnitAbility = require("../Ability/UnitAbility.js");
const TechTree = require("../Technology/TechTree.js");
const TechList = require("../Technology/TechList.js");
const Item = require("../Transactions/Item.js");
const FactionAbility = require("../Faction/FactionAbility.js");
const GameGlobalFunctions = require("../../Other/GameGlobalFunctions.js");
const PlayerEffect = require("../Faction/PlayerEffect.js");

class Unit {
  //Class
  static CLASS_SHIP = "ship";
  static CLASS_GROUND_FORCE = "ground force";
  static CLASS_STRUCTURE = "structure";
  static CLASS_STRUCTURE_ALL = "all";

  //Ships
  static UNIT_TYPE_DESTROYER = "Destroyer";
  static UNIT_TYPE_DREADNOUGH = "Dreadnought";
  static UNIT_TYPE_CRUSER = "Cruiser";
  static UNIT_TYPE_CARRIER = "Carrier";
  static UNIT_TYPE_FIGHTER = "Fighter";
  static UNIT_TYPE_HELL_MOON = "Hell Moon";

  //Ground Forces
  static UNIT_TYPE_INFANTRY = "Infantry";
  static UNIT_TYPE_MECH = "Mech";

  //Structures
  static UNIT_TYPE_ASSEMBLY_ORBITAL = "Assembly Orbital";
  static UNIT_TYPE_SUPPLY_BASE = "Supply Base";
  static UNIT_TYPE_GENERATOR = "Generator";
  static UNIT_TYPE_CITY = "City";
  static UNIT_TYPE_RESEARCH_LAB = "Research Lab";
  static UNIT_TYPE_FACTORY = "Factory";
  static UNIT_TYPE_ORBITAL_CANON = "Orbital Canon";
  static UNIT_TYPE_PLANETARY_CANON = "Planetary Canon";
  static UNIT_TYPE_PLANETARY_SHIELD = "Planetary Shield";

  static unitImageSizes = {
    [Unit.UNIT_TYPE_DREADNOUGH]: { x: 990, y: 700 },
    [Unit.UNIT_TYPE_DESTROYER]: { x: 670, y: 500 },
    [Unit.UNIT_TYPE_CRUSER]: { x: 875, y: 600 },
    [Unit.UNIT_TYPE_CARRIER]: { x: 815, y: 600 },
    [Unit.UNIT_TYPE_FIGHTER]: { x: 465, y: 300 },
    [Unit.UNIT_TYPE_HELL_MOON]: { x: 840, y: 800 },
    [Unit.UNIT_TYPE_INFANTRY]: { x: 450, y: 475 },
    [Unit.UNIT_TYPE_MECH]: { x: 600, y: 500 },
    [Unit.UNIT_TYPE_ASSEMBLY_ORBITAL]: { x: 690, y: 550 },
    [Unit.UNIT_TYPE_FACTORY]: { x: 760, y: 570 },
    [Unit.UNIT_TYPE_PLANETARY_CANON]: { x: 520, y: 520 },
    [Unit.UNIT_TYPE_PLANETARY_SHIELD]: { x: 550, y: 580 },
    [Unit.UNIT_TYPE_HELL_MOON]: { x: 1007, y: 959 },
  };

  static unitGlobalScale = 0.5;

  static generateUnitCreationId(playerData) {
    const inc = CustomMath.generateRandomNumber(1, 5);
    playerData.unitCreationId = playerData.unitCreationId + inc;
    return playerData.unitCreationId;
  }

  static createFakePlayerData(faction) {
    return {
      techTree: { techs: [] },
      faction: faction,
      color: faction.dedicatedColor,
      unitCreationId: 0,
      items: [],
      effects: [],
      otherPlayers: [],
    };
  }

  static createUnit(
    playerData,
    faction,
    type,
    subType = "",
    generateId = true
  ) {
    let id = null;

    if (generateId) {
      const idNumber = this.generateUnitCreationId(playerData);
      id = `${playerData.playerInGameId}-${idNumber}`;
    }

    let unit = {
      id: id,
      color: Color.getColorFromFaction(playerData, faction, true),
      faction: faction,
      abilities: [],
    };

    //console.log("DEBUG : ", GameGlobalFunctions.getPlayerDataFromFaction);

    let playerDataOfUnit = GameGlobalFunctions.getPlayerDataFromFaction(
      playerData,
      faction
    );
    if (!playerDataOfUnit) {
      playerDataOfUnit = this.createFakePlayerData(faction);
    }

    //Ships
    if (type === this.UNIT_TYPE_DREADNOUGH) {
      this.addAbility(
        unit,
        AbilityCommon.createAbilityData(UnitAbility.ID_BOMBARDMENT, {
          min: 1.5,
          max: 3.5,
        })
      );
      unit = {
        ...unit,
        type: type,
        subType: subType,
        mass: 1,
        hp: 8,
        maxHp: 8,
        combatMin: 0.8,
        combatMax: 1.8,
        range: 1,
        class: "ship",
        morale: 4,
        capacity: 2,
        requiredCapacity: 0,
        costMineral: 4,
        costEnergy: 0,
        costPopulation: 0,
        requiredStructures: [this.UNIT_TYPE_ASSEMBLY_ORBITAL],
      };
    }

    if (type === this.UNIT_TYPE_HELL_MOON) {
      this.addAbility(
        unit,
        AbilityCommon.createAbilityData(UnitAbility.ID_BOMBARDMENT, {
          min: 6,
          max: 9,
        })
      );
      unit = {
        ...unit,
        type: type,
        subType: subType,
        mass: 1,
        hp: 12,
        maxHp: 12,
        combatMin: 1.5,
        combatMax: 2.5,
        range: 1,
        class: "ship",
        morale: 4,
        capacity: 8,
        requiredCapacity: 0,
        costMineral: 10,
        costEnergy: 0,
        costPopulation: 1,
        requiredStructures: [this.UNIT_TYPE_ASSEMBLY_ORBITAL],
      };
    }

    if (type === this.UNIT_TYPE_DESTROYER) {
      this.addAbility(
        unit,
        AbilityCommon.createAbilityData(UnitAbility.ID_AFC, {
          min: 1,
          max: 2,
        })
      );
      unit = {
        ...unit,
        type: type,
        subType: subType,
        mass: 1,
        range: 2,
        hp: 6,
        maxHp: 6,
        combatMin: 0.2,
        combatMax: 1.1,
        class: "ship",
        morale: 2,
        capacity: 0,
        requiredCapacity: 0,
        costMineral: 1,
        costEnergy: 0,
        costPopulation: 0,
        requiredStructures: [this.UNIT_TYPE_FACTORY],
      };
    }

    if (type === this.UNIT_TYPE_CRUSER) {
      unit = {
        ...unit,
        type: type,
        subType: subType,
        mass: 1,
        range: 2,
        hp: 7,
        maxHp: 7,
        combatMin: 0.4,
        combatMax: 1.4,
        class: "ship",
        morale: 2,
        capacity: 0,
        requiredCapacity: 0,
        costMineral: 2,
        costEnergy: 0,
        costPopulation: 0,
        requiredStructures: [this.UNIT_TYPE_FACTORY],
      };
    }

    if (type === this.UNIT_TYPE_CARRIER) {
      unit = {
        ...unit,
        type: type,
        subType: subType,
        mass: 1,
        range: 1,
        hp: 7,
        maxHp: 7,
        combatMin: 0.2,
        combatMax: 1.1,
        class: "ship",
        morale: 3,
        capacity: 4,
        requiredCapacity: 0,
        costMineral: 3,
        costEnergy: 0,
        costPopulation: 0,
        requiredStructures: [this.UNIT_TYPE_FACTORY],
      };
    }

    if (type === this.UNIT_TYPE_FIGHTER) {
      unit = {
        ...unit,
        type: type,
        subType: subType,
        mass: 0,
        range: null,
        hp: 4,
        maxHp: 4,
        combatMin: 0.2,
        combatMax: 1.1,
        class: "ship",
        morale: 0.5,
        capacity: 0,
        requiredCapacity: 1,
        transportRequired: true,
        costEnergy: 0,
        costMineral: 0.3,
        costPopulation: 0,
        requiredStructures: [this.UNIT_TYPE_FACTORY],
      };
    }

    //Ground Forces
    if (type === this.UNIT_TYPE_INFANTRY) {
      unit = {
        ...unit,
        type: type,
        subType: subType,
        mass: 0,
        hp: 2,
        maxHp: 2,
        combatMin: 0.2,
        combatMax: 1.2,
        class: "ground force",
        morale: 1,
        costMineral: 0,
        costEnergy: 0,
        costPopulation: 0.6,
        transportRequired: true,
        requiredCapacity: 1,
        requiredStructures: [],
        //requiredStructures: [this.UNIT_TYPE_FACTORY],
      };
    }

    if (type === this.UNIT_TYPE_MECH) {
      unit = {
        ...unit,
        type: type,
        subType: subType,
        mass: 0,
        hp: 5,
        maxHp: 5,
        combatMin: 0.4,
        combatMax: 1.4,
        class: "ground force",
        morale: 3,
        costMineral: 2,
        costEnergy: 0,
        costPopulation: 0,
        transportRequired: true,
        requiredCapacity: 1,
        requiredStructures: [this.UNIT_TYPE_FACTORY],
      };
    }

    //Structures
    if (type === this.UNIT_TYPE_ASSEMBLY_ORBITAL) {
      unit = {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        morale: 1,
        costMineral: 2,
        costEnergy: 0,
        costPopulation: 3,
        onlyOnePerPlanet: true,
        requiredStructures: [this.UNIT_TYPE_FACTORY],
        constructionAmount: 2,
        constructableUnits: [
          this.UNIT_TYPE_DREADNOUGH,
          this.UNIT_TYPE_CARRIER,
          this.UNIT_TYPE_FIGHTER,
        ],
        maxAmountOnPlanet: 1,
      };
    }

    if (type === this.UNIT_TYPE_PLANETARY_CANON) {
      this.addAbility(
        unit,
        AbilityCommon.createAbilityData(UnitAbility.ID_SPACE_CANON_DEFENSE, {
          min: 2.5,
          max: 4.5,
        })
      );
      this.addAbility(
        unit,
        AbilityCommon.createAbilityData(
          UnitAbility.ID_PLANETARY_CANON_DEFENSE,
          {
            min: 1.5,
            max: 2.5,
          }
        )
      );
      unit = {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        morale: 1,
        costMineral: 0,
        costEnergy: 0,
        costPopulation: 3,
        onlyOnePerPlanet: true,
        requiredStructures: [],
        maxAmountOnPlanet: 2,
      };
    }

    if (type === this.UNIT_TYPE_PLANETARY_SHIELD) {
      this.addAbility(
        unit,
        AbilityCommon.createAbilityData(UnitAbility.ID_PLANETARY_SHIELD, {
          value: 50,
        })
      );
      unit = {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        morale: 1,
        costMineral: 0,
        costPopulation: 2,
        onlyOnePerPlanet: true,
        requiredStructures: [],
        maxAmountOnPlanet: 1,
      };
    }

    if (type === this.UNIT_TYPE_SUPPLY_BASE) {
      unit = {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        costMineral: 0,
        costEnergy: 1,
        costPopulation: 1,
      };
    }
    if (type === this.UNIT_TYPE_GENERATOR) {
      unit = {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        costMineral: 1,
        costEnergy: 0,
        costPopulation: 1.5,
      };
    }
    if (type === this.UNIT_TYPE_CITY) {
      unit = {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        costMineral: 0,
        costEnergy: 0,
        costPopulation: 0,
        costInfluence: 5,
      };
    }
    if (type === this.UNIT_TYPE_RESEARCH_LAB) {
      unit = {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        costMineral: 0,
        costEnergy: 3,
        costPopulation: 2,
      };
    }
    if (type === this.UNIT_TYPE_FACTORY) {
      unit = {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        morale: 1,
        costMineral: 1,
        costEnergy: 0,
        costPopulation: 3,
        requiredStructures: [],
        constructionAmount: 2,
        constructableUnits: [
          this.UNIT_TYPE_CARRIER,
          this.UNIT_TYPE_CRUSER,
          this.UNIT_TYPE_FIGHTER,
          this.UNIT_TYPE_DESTROYER,
          this.UNIT_TYPE_INFANTRY,
          this.UNIT_TYPE_MECH,
        ],
        maxAmountOnPlanet: 1,
      };
    }
    if (type === this.UNIT_TYPE_ORBITAL_CANON) {
      unit = {
        ...unit,
        type: type,
        subType: subType,
        class: "structure",
        size: 1,
        costMineral: 0,
        costEnergy: 2,
        costPopulation: 1,
      };
    }

    if (!unit.type) throw new Error("Unit.createUnit : unit type not found");

    //Faction Abilities
    //RESISTANT SHIPS
    if (
      FactionAbility.hasFactionAbility(
        playerDataOfUnit,
        FactionAbility.GLOBAL_RESISTANT_SHIPS
      ) &&
      unit.class === Unit.CLASS_SHIP
    ) {
      unit.hp = unit.hp + 1;
      unit.maxHp = unit.maxHp + 1;
    }

    //WAR SHIPS
    if (
      unit.class === Unit.CLASS_SHIP &&
      unit.type !== Unit.UNIT_TYPE_FIGHTER
    ) {
      //RESISTANT WAR SHIP
      if (
        FactionAbility.hasFactionAbility(
          playerDataOfUnit,
          FactionAbility.GLOBAL_RESISTANT_WAR_SHIPS
        )
      ) {
        unit.hp = unit.hp + 1;
        unit.maxHp = unit.maxHp + 1;
      }
      //FRAGILE WAR SHIP
      if (
        FactionAbility.hasFactionAbility(
          playerDataOfUnit,
          FactionAbility.GLOBAL_FRAGILE_WAR_SHIPS
        )
      ) {
        unit.hp = unit.hp - 1;
        unit.maxHp = unit.maxHp - 1;
      }
    }

    //STRUCTURES
    if (unit.class === Unit.CLASS_STRUCTURE) {
      //FORTIFICATIONS
      if (
        FactionAbility.hasFactionAbility(
          playerDataOfUnit,
          FactionAbility.GLOBAL_FORTIFICATIONS
        )
      ) {
        unit.morale = unit.morale + 1;
      }
    }

    //CARRIER EXTRA CAPACITY
    if (
      FactionAbility.hasFactionAbility(
        playerDataOfUnit,
        FactionAbility.GLOBAL_CARRIER_EXTRA_CAPACITY
      ) &&
      unit.type === Unit.UNIT_TYPE_CARRIER
    ) {
      unit.capacity = unit.capacity + 2;
    }

    if (unit.type === Unit.UNIT_TYPE_INFANTRY) {
      //RESISTANT
      if (
        FactionAbility.hasFactionAbility(
          playerDataOfUnit,
          FactionAbility.GLOBAL_RESISTANT
        )
      ) {
        unit.hp = unit.hp + 1;
        unit.maxHp = unit.maxHp + 1;
      }

      if (
        FactionAbility.hasFactionAbility(
          playerDataOfUnit,
          FactionAbility.GLOBAL_BIG
        )
      ) {
        unit.hp = unit.hp + 1;
        unit.maxHp = unit.maxHp + 1;
        unit.requiredCapacity = unit.requiredCapacity + 0.3;
        unit.combatMin = unit.combatMin + 0.2;
        unit.combatMax = unit.combatMax + 0.2;
      }
      if (
        FactionAbility.hasFactionAbility(
          playerDataOfUnit,
          FactionAbility.ETHERAL_SPREAD_ETHERAL_FORM
        )
      ) {
        unit.hp = unit.hp + 2;
        unit.maxHp = unit.maxHp + 2;
        unit.costPopulation = unit.costPopulation + 0.4;
      }
      if (
        FactionAbility.hasFactionAbility(
          playerDataOfUnit,
          FactionAbility.LITHORIAN_COLLECTIVE_STONE_FORM
        )
      ) {
        unit.morale = unit.morale + 1;
        unit.costMineral = unit.costMineral + 0.4;
      }
    }

    //STRONG SHIPS
    if (
      unit.class === Unit.CLASS_SHIP &&
      FactionAbility.hasFactionAbility(
        playerDataOfUnit,
        FactionAbility.GLOBAL_STRONG_SHIPS
      )
    ) {
      unit.combatMin = unit.combatMin + 0.1;
      unit.combatMax = unit.combatMax + 0.1;
    }

    //TITAN FORGE INEPTITUDE
    if (
      (unit.type === Unit.UNIT_TYPE_DREADNOUGH ||
        unit.type === Unit.UNIT_TYPE_HELL_MOON) &&
      FactionAbility.hasFactionAbility(
        playerDataOfUnit,
        FactionAbility.GLOBAL_TITAN_FORGE_INEPTITUDE
      )
    ) {
      if (unit.type === Unit.UNIT_TYPE_DREADNOUGH) {
        unit.costMineral = unit.costMineral + 1;
      }
      if (unit.type === Unit.UNIT_TYPE_HELL_MOON) {
        unit.costMineral = unit.costMineral + 2;
      }
    }

    //TITAN FORGE AFFINITY
    if (
      (unit.type === Unit.UNIT_TYPE_DREADNOUGH ||
        unit.type === Unit.UNIT_TYPE_HELL_MOON) &&
      FactionAbility.hasFactionAbility(
        playerDataOfUnit,
        FactionAbility.GLOBAL_TITAN_FORGE_AFFINITY
      )
    ) {
      if (unit.type === Unit.UNIT_TYPE_DREADNOUGH) {
        unit.costMineral = unit.costMineral - 1;
      }
      if (unit.type === Unit.UNIT_TYPE_HELL_MOON) {
        unit.costMineral = unit.costMineral - 2;
      }
    }

    //VAHL_AR_SKYBORNE_VOIDSTREAM_PROPULSION
    if (
      unit.class === Unit.CLASS_SHIP &&
      unit.type === Unit.UNIT_TYPE_DESTROYER &&
      FactionAbility.hasFactionAbility(
        playerDataOfUnit,
        FactionAbility.VAHL_AR_SKYBORNE_VOIDSTREAM_PROPULSION
      )
    ) {
      unit.range = unit.range + 1;
    }

    this.updateUnitBuilt(playerDataOfUnit, unit);

    //Checks on units parameters
    unit.combatMin = Math.max(0, CustomMath.roundDec(unit.combatMin));
    unit.combatMax = Math.max(0, CustomMath.roundDec(unit.combatMax));
    unit.hp = Math.max(1, CustomMath.roundDec(unit.hp));
    unit.maxHp = Math.max(1, CustomMath.roundDec(unit.maxHp));
    unit.costMineral = Math.max(0, CustomMath.roundDec(unit.costMineral));
    unit.costEnergy = Math.max(0, CustomMath.roundDec(unit.costEnergy));
    unit.costPopulation = Math.max(0, CustomMath.roundDec(unit.costPopulation));
    unit.requiredCapacity = Math.max(
      0,
      CustomMath.roundDec(unit.requiredCapacity)
    );
    unit.capacity = Math.max(0, CustomMath.roundDec(unit.capacity));
    unit.morale = Math.max(0, CustomMath.roundDec(unit.morale));
    unit.range = Math.max(0, CustomMath.roundInt(unit.range));

    return unit;
  }

  static getUnitShipsCatalog(playerData, faction) {
    return [
      this.createUnit(playerData, faction, this.UNIT_TYPE_HELL_MOON, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_DREADNOUGH, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_DESTROYER, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_CRUSER, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_CARRIER, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_FIGHTER, ""),
    ];
  }

  static getUnitGroundForcesCatalog(playerData, faction) {
    return [
      this.createUnit(playerData, faction, this.UNIT_TYPE_INFANTRY, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_MECH, ""),
    ];
  }

  static getUnitStructuresCatalog(playerData, faction) {
    return [
      this.createUnit(playerData, faction, this.UNIT_TYPE_ASSEMBLY_ORBITAL, ""),
      //this.createUnit(playerData, faction, this.UNIT_TYPE_SUPPLY_BASE, ""),
      //this.createUnit(playerData, faction, this.UNIT_TYPE_GENERATOR, ""),
      //this.createUnit(playerData, faction, this.UNIT_TYPE_CITY, ""),
      //this.createUnit(playerData, faction, this.UNIT_TYPE_RESEARCH_LAB, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_FACTORY, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_PLANETARY_CANON, ""),
      this.createUnit(playerData, faction, this.UNIT_TYPE_PLANETARY_SHIELD, ""),

      //this.createUnit(playerData, faction, this.UNIT_TYPE_ORBITAL_CANON, ""),
    ];
  }

  static getConstructionAmount(playerData, unit) {
    if (!unit.constructionAmount) return null;

    let value = unit.constructionAmount;

    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.quantumAssemblyLine.name)
    ) {
      if (
        unit.type === this.UNIT_TYPE_FACTORY ||
        unit.type === this.UNIT_TYPE_ASSEMBLY_ORBITAL
      ) {
        value += 1;
      }
    }

    return value;
  }

  static getCapacity(playerData, unit) {
    let modifier = 0;

    if (Item.playerHasExhaustedItem(playerData, Item.NAME_SELTAAR_SCROLL)) {
      if (
        unit.class === Unit.UNIT_TYPE_DREADNOUGH ||
        unit.class === Unit.UNIT_TYPE_CARRIER
      ) {
        modifier = modifier + 2;
      }
    }

    return unit.capacity + modifier;
  }

  static getRequiredCapacity(playerData, unit) {
    let modifier = 0;

    return unit.requiredCapacity;
  }

  static getConstructableUnitsFromUnit(playerData, faction, unit) {
    const catalog = [];

    if (
      !Unit.getConstructionAmount(playerData, unit) ||
      !unit.constructableUnits
    ) {
      return null;
    }

    for (let i = 0; i < unit.constructableUnits.length; i++) {
      catalog.push(
        this.createUnit(
          playerData,
          faction,
          unit.constructableUnits[i],
          "",
          false
        )
      );
    }

    return catalog;
  }

  static canUnitConstruct(playerData, unit) {
    if (
      Unit.getConstructionAmount(playerData, unit) &&
      unit.constructableUnits
    ) {
      return true;
    }
    return false;
  }

  static checkUnitConstructUnit(playerData, unit, unitToConstruct) {
    if (
      Unit.getConstructionAmount(playerData, unit) &&
      unit.constructableUnits
    ) {
      for (let i = 0; i < unit.constructableUnits.length; i++) {
        if (this.areUnitsTheSame(unit.constructableUnits[i], unitToConstruct)) {
          return true;
        }
      }
    }
    throw new Error(unit.name + " can't construct a " + unitToConstruct.name);
  }

  static checkUnitConstructUnitList(playerData, unit, unitList) {
    const constructionAmount = Unit.getConstructionAmount(playerData, unit);
    if (constructionAmount > unitList.length) {
      throw new Error(
        unit.name +
          " can only construct " +
          constructionAmount +
          " units. It is trying to construct " +
          unitList.length +
          " units."
      );
    }

    if (constructionAmount && unit.constructableUnits) {
      for (let i = 0; i < unitList.length; i++) {
        this.checkUnitConstructUnit(unit, unitList[i]);
      }
    }
  }

  //Get

  static getHP(playerData, unit) {
    //console.log("Unit.getHP : unit : ", unit);
    if (!unit.hp) {
      return null;
    }
    return unit.hp;
  }

  static doesUnitRollCombat(playerData, unit) {
    return unit.combatMin && unit.combatMax;
  }

  static getMinDamage(playerData, unit) {
    if (!unit.combatMin) {
      return null;
    }

    let modifier = 0;

    //Calvaria scroll
    if (Item.playerHasExhaustedItem(playerData, Item.NAME_CALVARIA_SCROLL)) {
      modifier = modifier + 0.2;
    }

    //Modifier from the common modifier function
    modifier = modifier + this.getModifierDamage(playerData, unit);

    return Math.max(0, CustomMath.roundDec(unit.combatMin + modifier));
  }

  static setMinDamage(playerData, unit, minDamage) {}

  static getMaxDamage(playerData, unit) {
    if (!unit.combatMax) {
      return null;
    }

    let modifier = 0;

    //Calvaria scroll
    if (Item.playerHasExhaustedItem(playerData, Item.NAME_CALVARIA_SCROLL)) {
      modifier = modifier + 0.2;
    }

    //Modifier from the common modifier function
    modifier = modifier + this.getModifierDamage(playerData, unit);

    return Math.max(0, CustomMath.roundDec(unit.combatMax + modifier));
  }

  static getModifierDamage(playerData, unit) {
    let modifier = 0;

    //Effects
    if (
      PlayerEffect.hasEffect(
        playerData,
        PlayerEffect.ETHERAL_ETHERAL_CONNECTION
      )
    ) {
      modifier = modifier + 0.2;
    }

    return modifier;
  }

  static setMaxDamage(playerData, unit, maxDamage) {}

  static setHP(playerData, unit, hp) {
    unit.hp = hp;
  }

  static getMaxHP(playerData, unit) {
    if (!unit.maxHp) {
      return null;
    }

    let modifier = 0;

    return unit.maxHp + modifier;
  }

  static getRange(playerData, unit) {
    return unit.range;
  }

  static setMaxHP(playerData, unit, maxHp) {
    unit.maxHp = maxHp;
  }

  static getMaxAmountOnPlanet(playerData, unit) {
    if (unit.maxAmountOnPlanet) {
      return unit.maxAmountOnPlanet;
    }
    return null;
  }

  static getTypeAbbreviation(type) {
    if (type === this.UNIT_TYPE_DREADNOUGH) {
      return "Dread";
    }

    if (type === this.UNIT_TYPE_DESTROYER) {
      return "Dest";
    }

    if (type === this.UNIT_TYPE_CRUSER) {
      return "Cru";
    }

    if (type === this.UNIT_TYPE_CARRIER) {
      return "Car";
    }

    if (type === this.UNIT_TYPE_FIGHTER) {
      return "Fight";
    }

    if (type === this.UNIT_TYPE_INFANTRY) {
      return "Inf";
    }

    if (type === this.UNIT_TYPE_MECH) {
      return "Mech";
    }

    if (type === this.UNIT_TYPE_ASSEMBLY_ORBITAL) {
      return "AssOrb";
    }

    if (type === this.UNIT_TYPE_SUPPLY_BASE) {
      return "SupBase";
    }

    if (type === this.UNIT_TYPE_GENERATOR) {
      return "Gen";
    }

    if (type === this.UNIT_TYPE_CITY) {
      return "City";
    }

    if (type === this.UNIT_TYPE_RESEARCH_LAB) {
      return "ResLab";
    }

    if (type === this.UNIT_TYPE_FACTORY) {
      return "Fact";
    }

    if (type === this.UNIT_TYPE_ORBITAL_CANON) {
      return "OrbCan";
    }

    throw new Error("Unit.getTypeAbbreviation : type not found");
  }

  static getSuperShortAbbreviation(type) {
    if (type === this.UNIT_TYPE_DREADNOUGH) {
      return "dr";
    }

    if (type === this.UNIT_TYPE_HELL_MOON) {
      return "hm";
    }

    if (type === this.UNIT_TYPE_DESTROYER) {
      return "de";
    }

    if (type === this.UNIT_TYPE_CRUSER) {
      return "cu";
    }

    if (type === this.UNIT_TYPE_CARRIER) {
      return "ca";
    }

    if (type === this.UNIT_TYPE_FIGHTER) {
      return "ff";
    }

    if (type === this.UNIT_TYPE_INFANTRY) {
      return "if";
    }

    if (type === this.UNIT_TYPE_MECH) {
      return "me";
    }

    if (type === this.UNIT_TYPE_ASSEMBLY_ORBITAL) {
      return "ao";
    }

    if (type === this.UNIT_TYPE_SUPPLY_BASE) {
      return "sb";
    }

    if (type === this.UNIT_TYPE_GENERATOR) {
      return "gn";
    }

    if (type === this.UNIT_TYPE_CITY) {
      return "ct";
    }

    if (type === this.UNIT_TYPE_RESEARCH_LAB) {
      return "rl";
    }

    if (type === this.UNIT_TYPE_FACTORY) {
      return "fa";
    }

    /*if (type === this.UNIT_TYPE_ORBITAL_CANON) {
      return "oc";
    }*/

    if (type === this.UNIT_TYPE_PLANETARY_CANON) {
      return "pla_can";
    }

    if (type === this.UNIT_TYPE_PLANETARY_SHIELD) {
      return "pla_shield";
    }

    throw new Error("Unit.getTypeAbbreviation : type not found");
  }

  static getSubtypeAbbreviation(subType) {
    return "";
  }

  static getDisplaySize(type) {
    if (type === this.UNIT_TYPE_DREADNOUGH) {
      return "100%";
    }

    if (type === this.UNIT_TYPE_DESTROYER) {
      return "70%";
    }

    if (type === this.UNIT_TYPE_CRUSER) {
      return "80%";
    }

    if (type === this.UNIT_TYPE_CARRIER) {
      return "90%";
    }

    if (type === this.UNIT_TYPE_FIGHTER) {
      return "50%";
    }

    if (type === this.UNIT_TYPE_INFANTRY) {
      return "40%";
    }

    if (type === this.UNIT_TYPE_MECH) {
      return "70%";
    }

    if (type === this.UNIT_TYPE_ASSEMBLY_ORBITAL) {
      return "100%";
    }

    if (type === this.UNIT_TYPE_SUPPLY_BASE) {
      return "90%";
    }

    if (type === this.UNIT_TYPE_GENERATOR) {
      return "90";
    }

    if (type === this.UNIT_TYPE_CITY) {
      return "90";
    }

    if (type === this.UNIT_TYPE_RESEARCH_LAB) {
      return "90";
    }

    if (type === this.UNIT_TYPE_FACTORY) {
      return "90";
    }

    if (type === this.UNIT_TYPE_ORBITAL_CANON) {
      return "90";
    }

    throw new Error("Unit.getTypeAbbreviation : type not found");
  }

  static findUnitInArray(unit, unitsArray) {
    for (let i = 0; i < unitsArray.length; i++) {
      if (this.areUnitsTheSame(unitsArray[i], unit)) return unitsArray[i];
    }
    return null;
  }

  static areUnitsTheSame(unitA, unitB) {
    if (!unitA.id && !unitB.id) {
      if (unitA.type === unitB.type && unitA.subType === unitB.subType) {
        return true;
      } else {
        return false;
      }
    } else {
      if (unitA.id === unitB.id) {
        return true;
      } else {
        return false;
      }
    }
  }

  static getPrintingName(unit) {
    return `${unit.type} ${unit.subType}`;
  }

  static getPrintingText(unit) {
    return `${unit.type} ${unit.subType} ${Unit.getHP(unit)}/${Unit.getMaxHP(
      unit
    )}`;
  }

  static getLogItem(unit) {
    return { type: "unit", content: this.getPrintingText(unit) };
  }

  static getCost(playerData, unit) {
    let basePopulationCost = unit.costPopulation ? unit.costPopulation : 0;
    let baseEnergyCost = unit.costEnergy ? unit.costEnergy : 0;
    let baseMineralCost = unit.costMineral ? unit.costMineral : 0;
    let baseCreditCost = unit.costCredit ? unit.costCredit : 0;
    let baseScienceCost = unit.costScience ? unit.costScience : 0;

    let mineralModifier = 0;
    let populationModifier = 0;
    let energyModifier = 0;
    let creditModifier = 0;
    let scienceModifier = 0;

    return Cost.createCost({
      mineral: baseMineralCost + mineralModifier,
      energy: baseEnergyCost + energyModifier,
      population: basePopulationCost + populationModifier,
      credit: baseCreditCost + creditModifier,
      science: baseScienceCost + scienceModifier,
    });
  }

  static repair(playerData, unit, amount, logBook = null) {
    const previousHP = Unit.getHP(unit);
    const effectiveAmount = CustomMath.roundInt(
      Math.min(
        Unit.getMaxHP(playerData, unit) - Unit.getHP(playerData, unit),
        amount
      )
    );

    if (logBook) {
      LogBook.createMessage(logBook, [
        { type: "unit", content: unit },
        { type: "text", content: " is repaired by " + amount },
      ]);
    }

    Unit.setHP(
      playerData,
      unit,
      CustomMath.roundInt(Unit.getHP(unit) + effectiveAmount)
    );
  }

  static createRandomShip(playerData, faction) {
    const ships = this.getUnitShipsCatalog(playerData, faction);
    return Unit.createUnit(
      playerData,
      faction,
      ships[CustomMath.generateRandomNumber(0, ships.length - 1)].type,
      "",
      true
    );
  }

  static createRandomGroundForce(playerData, faction) {
    const groundForces = this.getUnitGroundForcesCatalog(playerData, faction);
    return Unit.createUnit(
      playerData,
      faction,
      groundForces[CustomMath.generateRandomNumber(0, groundForces.length - 1)]
        .type,
      "",
      true
    );
  }

  static createRandomStructure(playerData, faction) {
    const structures = this.getUnitStructuresCatalog(playerData, faction);
    return Unit.createUnit(
      playerData,
      faction,
      structures[CustomMath.generateRandomNumber(0, structures.length - 1)]
        .type,
      "",
      true
    );
  }

  static shouldUnitBeTransported(playerData, unit) {
    return unit.transportRequired;
  }

  //Ability

  static getAbilities(playerData, unit) {
    //const resultAbilities = [...unit.abilities];

    return unit.abilities;
  }

  static getAbilitiesDescription(playerData, unit) {
    const abilities = this.getAbilities(playerData, unit);
    const abilityDescList = [];
    for (let i = 0; i < abilities.length; i++) {
      abilityDescList.push(
        UnitAbility.object[abilities[i].id](abilities[i].data)
      );
    }
    return abilityDescList;
  }

  static addAbility(unit, abilityData) {
    unit.abilities.push(abilityData);
  }

  static removeAbility(unit, abilityData) {
    // Find the index of the ability to be removed
    const index = unit.abilities.findIndex(
      (abilityDataUnit) => abilityDataUnit.id === abilityData.id
    );

    // If the ability was found, remove it
    if (index !== -1) {
      unit.abilities.splice(index, 1);
    }
  }

  static removeAllAbilities(unit) {
    unit.abilities = [];
  }

  static hasAbility(playerData, unit, abilityId) {
    const abilities = this.getAbilities(playerData, unit);
    for (let i = 0; i < abilities.length; i++) {
      if (abilities[i].id === abilityId) {
        return true;
      }
    }
    return false;
  }

  static getUnitWithAbility(playerData, unit, abilityId) {
    const abilities = this.getAbilities(playerData, unit);
    const abilitiesData = [];
    for (let i = 0; i < abilities.length; i++) {
      if (abilities[i].id === abilityId) {
        abilitiesData.push(abilities[i].data);
      }
    }
    if (abilitiesData.length > 0) {
      return { unit: unit, data: abilitiesData };
    }
    return null;
  }

  static executeUnitAbility(playerData, unit, abilityId, callback) {
    const abilities = this.getAbilities(playerData, unit);
    for (let i = 0; i < abilities.length; i++) {
      if (abilities[i].id === abilityId) {
        callback(unit, abilities[i].data);
      }
    }
  }

  //Unit Upgrade

  static reinforcedHull = (playerData, unit) => {
    if (TechTree.hasPlayerTech(playerData, TechList.TECH.reinforcedHull.name)) {
      if (unit.class === Unit.CLASS_SHIP) {
        Unit.setHP(playerData, unit, Unit.getHP(playerData, unit) + 1);
        Unit.setMaxHP(playerData, unit, Unit.getMaxHP(playerData, unit) + 1);
      }
    }
  };

  static reinforcedArmors = (playerData, unit) => {
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.reinforcedArmor.name)
    ) {
      if (unit.class === Unit.CLASS_GROUND_FORCE) {
        Unit.setHP(playerData, unit, Unit.getHP(playerData, unit) + 1);
        Unit.setMaxHP(playerData, unit, Unit.getMaxHP(playerData, unit) + 1);
      }
    }
  };

  static ligthArmor = (playerData, unit) => {
    if (TechTree.hasPlayerTech(playerData, TechList.TECH.ligthArmor.name)) {
      if (unit.type === Unit.UNIT_TYPE_INFANTRY) {
        Unit.setHP(playerData, unit, Unit.getHP(playerData, unit) + 1);
        Unit.setMaxHP(playerData, unit, Unit.getMaxHP(playerData, unit) + 1);
      }
    }
  };

  static lightShielding = (playerData, unit) => {
    if (TechTree.hasPlayerTech(playerData, TechList.TECH.lightShielding.name)) {
      if (
        unit.type === Unit.UNIT_TYPE_CRUSER ||
        unit.type === Unit.UNIT_TYPE_DESTROYER
      ) {
        Unit.setHP(playerData, unit, Unit.getHP(playerData, unit) + 1);
        Unit.setMaxHP(playerData, unit, Unit.getMaxHP(playerData, unit) + 1);
      }
    }
  };

  static carrierPropulsion = (playerData, unit) => {
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.carrierPropulsion.name)
    ) {
      if (unit.type === Unit.UNIT_TYPE_CARRIER) {
        unit.range = CustomMath.roundInt(unit.range + 1);
      }
    }
  };

  static improveDestroyers = (playerData, unit) => {
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.improvedDestroyers.name)
    ) {
      if (unit.type === Unit.UNIT_TYPE_DESTROYER) {
        unit.combatMin = CustomMath.roundDec(unit.combatMin + 0.2);
        unit.combatMax = CustomMath.roundDec(unit.combatMax + 0.2);
        unit.abilities.push(
          AbilityCommon.createAbilityData(UnitAbility.ID_AFC, {
            min: 1,
            max: 2,
          })
        );
      }
    }
  };

  static improvedCruisers = (playerData, unit) => {
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.improvedCruisers.name)
    ) {
      if (unit.type === Unit.UNIT_TYPE_CRUSER) {
        unit.combatMin = CustomMath.roundDec(unit.combatMin + 0.1);
        unit.combatMax = CustomMath.roundDec(unit.combatMax + 0.1);
        unit.range = CustomMath.roundInt(unit.range + 1);
      }
    }
  };

  static dreadnoughPropulsion = (playerData, unit) => {
    if (
      TechTree.hasPlayerTech(
        playerData,
        TechList.TECH.dreadnoughPropulsion.name
      )
    ) {
      if (unit.type === Unit.UNIT_TYPE_DREADNOUGH) {
        unit.range = CustomMath.roundInt(unit.range + 1);
      }
    }
  };

  static canonAccuracy = (playerData, unit) => {
    //CANON ACCURACY
    if (TechTree.hasPlayerTech(playerData, TechList.TECH.canonAccuracy.name)) {
      if (
        TechTree.hasPlayerTech(playerData, TechList.TECH.canonAccuracy.name) &&
        unit.type === Unit.UNIT_TYPE_PLANETARY_CANON
      ) {
        unit.abilities.push(
          AbilityCommon.createAbilityData(UnitAbility.ID_SPACE_CANON_DEFENSE, {
            min: 1.5,
            max: 2.5,
          })
        );
      }
    }
  };

  static bombardmentWeaponsMiniaturization = (playerData, unit) => {
    //BOMBARDEMENT MINIATURIZATION
    if (
      TechTree.hasPlayerTech(
        playerData,
        TechList.TECH.bombardmentWeaponsMiniaturization.name
      )
    ) {
      if (
        unit.class === Unit.CLASS_SHIP &&
        unit.type !== Unit.UNIT_TYPE_FIGHTER
      ) {
        unit.abilities.push(
          AbilityCommon.createAbilityData(UnitAbility.ID_BOMBARDMENT, {
            min: 0.5,
            max: 1.5,
          })
        );
      }
    }
  };

  static improvedWeaponry = (playerData, unit) => {
    if (
      TechTree.hasPlayerTech(playerData, TechList.TECH.improvedWeaponry.name)
    ) {
      if (unit.class === Unit.CLASS_GROUND_FORCE) {
        unit.combatMin = CustomMath.roundDec(unit.combatMin + 0.1);
        unit.combatMax = CustomMath.roundDec(unit.combatMax + 0.1);
      }
    }
  };

  static improvedShipTargetting = (playerData, unit) => {
    if (
      TechTree.hasPlayerTech(
        playerData,
        TechList.TECH.improvedShipTargetting.name
      )
    ) {
      if (unit.class === Unit.CLASS_SHIP) {
        unit.combatMin = CustomMath.roundDec(unit.combatMin + 0.1);
        unit.combatMax = CustomMath.roundDec(unit.combatMax + 0.1);
      }
    }
  };

  static hellMoon = (playerData, unit) => {
    if (TechTree.hasPlayerTech(playerData, TechList.TECH.hellMoon.name)) {
      if (unit.type === Unit.UNIT_TYPE_ASSEMBLY_ORBITAL) {
        unit.constructableUnits.push(Unit.UNIT_TYPE_HELL_MOON);
      }
    }
  };

  static updateUnitBuilt = (playerData, unit) => {
    this.reinforcedHull(playerData, unit);
    this.reinforcedArmors(playerData, unit);
    this.ligthArmor(playerData, unit);
    this.lightShielding(playerData, unit);
    this.carrierPropulsion(playerData, unit);
    this.improveDestroyers(playerData, unit);
    this.improvedCruisers(playerData, unit);
    this.dreadnoughPropulsion(playerData, unit);
    this.canonAccuracy(playerData, unit);
    this.bombardmentWeaponsMiniaturization(playerData, unit);
    this.improvedWeaponry(playerData, unit);
    this.improvedShipTargetting(playerData, unit);
    this.hellMoon(playerData, unit);
  };

  static upgradeUnitAfterGain = (playerData, unit, updateName) => {
    if (updateName === TechList.TECH.improvedDestroyers.name) {
      this.improveDestroyers(playerData, unit);
    }
    if (updateName === TechList.TECH.improvedCruisers.name) {
      this.improvedCruisers(playerData, unit);
    }
    if (updateName === TechList.TECH.dreadnoughPropulsion.name) {
      this.dreadnoughPropulsion(playerData, unit);
    }
    if (updateName === TechList.TECH.reinforcedHull.name) {
      this.reinforcedHull(playerData, unit);
    }
    if (updateName === TechList.TECH.reinforcedArmor.name) {
      this.reinforcedArmors(playerData, unit);
    }
    if (updateName === TechList.TECH.ligthArmor.name) {
      this.ligthArmor(playerData, unit);
    }
    if (updateName === TechList.TECH.lightShielding.name) {
      this.lightShielding(playerData, unit);
    }
    if (updateName === TechList.TECH.carrierPropulsion.name) {
      this.carrierPropulsion(playerData, unit);
    }
    if (updateName === TechList.TECH.canonAccuracy.name) {
      this.canonAccuracy(playerData, unit);
    }
    if (updateName === TechList.TECH.bombardmentWeaponsMiniaturization.name) {
      this.bombardmentWeaponsMiniaturization(playerData, unit);
    }
    if (updateName === TechList.TECH.improvedWeaponry.name) {
      this.improvedWeaponry(playerData, unit);
    }
    if (updateName === TechList.TECH.improvedShipTargetting.name) {
      this.improvedShipTargetting(playerData, unit);
    }
    if (updateName === TechList.TECH.hellMoon.name) {
      this.hellMoon(playerData, unit);
    }
  };
}

module.exports = Unit;
