import React, { useState, useEffect, useRef, Children } from "react";
import "../../CSS/Menu.css";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import Request from "../../Common/Requests/Request";
import StaticGameData from "../../Data/GameData/StaticGameData";
import BaseButton from "../Utils/BaseButton";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData";
import FactionLogo from "../Utils/FactionLogo";
import ChatRoomView from "./ChatRoomView";
import ChatFactionLogo from "./ChatFactionLogo";
import ChatStaticData from "../../Data/ChatData/ChatStaticData";
import ChatMenuView from "./ChatMenuView";
import ChatData from "../../Data/ChatData/ChatData";

const IMAGE_PLANET_TEST = "/Images/Map/Objects/planet_test.png";

const MainChatView = ({ playerData }) => {
  const [activeRoom, setActiveRoom] = useState(null);
  const navigationData = { buttonsData: [], taskBarData: null };

  const testRef = useRef(null);
  console.log("scrollContainerRef testRef", testRef.current);

  useEffect(() => {
    const savedState = Popup.getGlobalState(Popup.getGlobalStateKey("chat"));
    if (savedState) {
      setActiveRoom(savedState.activeRoom);
    }
  }, []);

  useEffect(() => {
    Popup.addGlobalState(Popup.getGlobalStateKey("chat"), {
      activeRoom: activeRoom,
    });
  }, [activeRoom]);

  if (activeRoom) {
    return (
      <div ref={testRef} className="div-fill">
        <ChatRoomView
          room={activeRoom}
          setActiveRoom={setActiveRoom}
          navigationData={navigationData}
        ></ChatRoomView>
      </div>
    );
  }

  return (
    <GameGlobalLayout
      style={{ height: "100%" }}
      backgroundType="shadow"
      scrollAtEnd={true}
    >
      <div
        style={{
          height: "100%",
          //backgroundColor: Color.COLOR_MANAGE_VIEW_BACKGROUND,
        }}
      >
        {!activeRoom && (
          <ChatMenuView
            setActiveRoom={setActiveRoom}
            navigationData={navigationData}
          ></ChatMenuView>
        )}
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default MainChatView;
