import React from "react";
import Game from "../Data/GameData/Game/Game";
import StaticGameData from "../Data/GameData/StaticGameData";
import FactionLogo from "../GameView/Utils/FactionLogo";
import DynamicView from "../GameView/DynamicView";
import Popup from "../Data/Other/Popup";
import GameView from "../GameView/GameView";
import GameLobby from "./GameLobby";
import LineSpace from "../GameView/Utils/Layout/LineSpace";

function GameListItem({ game, setRouteMainHandler }) {
  //console.log(game);

  if (!StaticGameData.username) {
    return null;
  }

  const joinGameHandler = (selectedGame) => {
    /*console.log("Join game: ", selectedGame);
    setRouteMainHandler({
      activeScreen: "GameView",
      game: selectedGame,
    });*/
    /*Popup.addDynamicLayer(<GameView game={game}></GameView>, {
      gameView: true,
    });*/
    if (Game.isGameFull(game)) {
      Popup.enterGame(game);
    } else {
      Popup.addDynamicLayer(<GameLobby game={game}></GameLobby>);
    }
  };

  let colorText = "white";

  const selfPlayer = Game.getPlayerFromUsername(game, StaticGameData.username);

  if (selfPlayer && selfPlayer.isWaitingForNewRound) {
    colorText = "grey";
  }

  const DisplayPlayerFaction = ({ game }) => {
    const playerFaction = Game.getPlayerFaction(game, StaticGameData.username);

    if (!Game.isPlayerInGameFromUsername(game, StaticGameData.username)) {
      return null;
    }

    return (
      <span>
        <span>
          Your faction :{" "}
          {playerFaction ? (
            <FactionLogo factionName={playerFaction}></FactionLogo>
          ) : (
            "?"
          )}
        </span>
      </span>
    );
  };

  const DisplayOtherPlayersFactions = ({ game }) => {
    const otherPlayerFactions = Game.getOtherPlayerFactions(
      game,
      StaticGameData.username
    );

    return (
      <span>
        <span>
          Other Players factions :{" "}
          {otherPlayerFactions.map((faction, index) => (
            <span key={index}>
              {faction ? (
                <FactionLogo factionName={faction}></FactionLogo>
              ) : (
                "?"
              )}
            </span>
          ))}
        </span>
      </span>
    );
  };

  const HorSPace = () => {
    return <span style={{ marginRight: "3em" }}></span>;
  };

  return (
    <span onClick={() => joinGameHandler(game)} style={{ color: colorText }}>
      <div className="div-hor-center">
        <span> Id: {game.gameId}</span>
        <HorSPace></HorSPace>
        <span>Status : {Game.isGameFull(game) ? "Started" : "Lobby"}</span>
      </div>
      <hr></hr>
      <div className="div-hor-center">
        {!Game.isGameFull(game) && (
          <div>
            {" "}
            <span>
              Number of Players: {game.players.length}/{game.maxNumberPlayers}
            </span>
            <HorSPace></HorSPace>
          </div>
        )}
        <div>
          {" "}
          {game.gameListType === "Custom" && <div>Type : Custom</div>}
          {game.gameListType === "Ranked" && <div>Type : Ranked</div>}
        </div>
      </div>
      <hr></hr>
      <div>
        <DisplayPlayerFaction game={game}></DisplayPlayerFaction>
      </div>
      <div>
        {" "}
        <DisplayOtherPlayersFactions game={game}></DisplayOtherPlayersFactions>
      </div>
    </span>
  );
}

export default GameListItem;
