const Unit = require("../MapData/Unit");

const TechTree = require("../Technology/TechTree");
const TechList = require("../Technology/TechList");
const Map = require("../MapData/Map");
const MapUnitaryAction = require("../UnitaryAction/MapUnitaryAction");
const Tech = require("../Technology/Tech");
const Fleet = require("../MapData/Fleet");
const System = require("../MapData/System");
const ResolveData = require("../EndOfRound/ResolveData");
const CombatData = require("../Combat/CombatData");
const LogBook = require("../Connection/LogBook");
const SolveFleetLimitData = require("../MandatoryAction/SolveFleetLimitData");
const SolveCapacityData = require("../MandatoryAction/SolveCapacityData");
const CombatRecording = require("../Combat/CombatRecording");
const PlayerData = require("../PlayerData/PlayerData");
const Cost = require("../Utils/Cost");
const SecondaryObject = require("../MapData/SecondaryObject");
const ScoreData = require("../Objectifs/ScoreData");
const MinorFaction = require("../MinorFaction/MinorFaction");
const Request = require("../../../Common/Requests/Request");
const AbilityCommon = require("./AbilityCommon");
const Phase = require("../Game/Phase");
const StaticGameData = require("../StaticGameData");
const Objectives = require("../Objectifs/Objectives");
const Color = require("../../../Common/Config/Colors");
const Item = require("../Transactions/Item");
const UIMessage = require("../Connection/UIMessage");

class ResolveObjective {
  //static PHASE_TECH_ABILITY = "resolveTechAbility";

  static sendToServerCommon(objectiveName, actionData = {}) {
    const playerData = StaticGameData.getPlayerData();
    const data = {
      objectiveName: objectiveName,
      data: actionData,
    };

    playerData.phase = AbilityCommon.PHASE_RESOLVE_ABILITY;
    playerData.step = Phase.STEP_OBJECTIVE_SCORE;

    UIMessage.displayConfirmMessage(
      "Score Objective ?",
      "Do you want to score this objective ?",
      () => {
        Request.updateGameState(data);
      }
    );
  }

  static resolveCommon = (playerData, updateData, startOfRound = false) => {
    const data = updateData.data;
    const objectiveName = updateData.objectiveName;

    const objectiveDesc = Objectives.createDesc(objectiveName);

    if (!playerData.faction) {
      throw new Error("You have to pick a faction first.");
    }

    if (Objectives.hasScoredObjective(playerData, objectiveName)) {
      throw new Error("You already scored this objective.");
    }

    if (objectiveDesc.onlyStartOfRound && !startOfRound) {
      throw new Error(
        "This objective can only be scored at the start of the round."
      );
    }

    this.routeObjective(playerData, objectiveName, objectiveDesc, data);

    Objectives.scoreObjective(playerData, objectiveName);
  };

  static gainVP = (playerData, objectiveDesc) => {
    const scoreData = PlayerData.getScoreData(playerData);
    ScoreData.addScore(
      scoreData,
      objectiveDesc.vp,
      " Scoring objective " + objectiveDesc.name + "."
    );
  };

  static routeObjective = (playerData, objectiveName, objectiveDesc, data) => {
    switch (objectiveName) {
      case Objectives.NAME_SPEND_SCIENCE_1:
        this.spendScience1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_SPEND_MINERAL_1:
        this.spendMineral1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_SPEND_ENERGY_1:
        this.spendEnergy1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_SPEND_CREDIT_1:
        this.spendCredit1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_SPEND_POPULATION_1:
        this.spendPopulation1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_PLANET_ICE_WATER_1:
        this.planetIceWater1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_PLANET_EARTH_DESERT_1:
        this.planetEarthDesert1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_PLANET_GAZ_VOLCANIC_1:
        this.planetGazVolcanic1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_EMPTY_SPACE_1:
        this.emptySpace1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_WHORMHOLE_1:
        this.whormhole1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_MINOR_FACTION_1:
        this.minorFaction1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_SPACE_MIDDLE_1:
        this.spaceMiddle1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_PLANET_MIDDLE_1:
        this.planetMiddle1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_SPACE_FAR_1:
        this.spaceFar1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_SPACE_FAR_2:
        this.spaceFar2(playerData, objectiveName, objectiveDesc, data);
        break;

      case Objectives.NAME_PLANET_FAR_1:
        this.planetFar1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_PLANET_FAR_2:
        this.planetFar2(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_SPACE_HS_1:
        this.spaceHS1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_PLANET_HS_1:
        this.planetHS1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_TECH_RED_1:
        this.techRed1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_TECH_BLUE_1:
        this.techBlue1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_TECH_GREEN_1:
        this.techGreen1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_TECH_YELLOW_1:
        this.techYellow1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_TECH_DIVERSITY_1:
        this.techDiversity1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_TECH_DIVERSITY_2:
        this.techDiversity2(playerData, objectiveName, objectiveDesc, data);
        break;

      case Objectives.NAME_STRUCTURES_CANON_1:
        this.structuresCanon1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_STRUCTURES_FACTORY_1:
        this.structuresFactory1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_STRUCTURES_AO_1:
        this.structuresAO1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_STRUCTURES_PLANETARY_SHIELS_1:
        this.structuresPlanetaryShields1(
          playerData,
          objectiveName,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_ARTIFACT_1:
        this.artifact1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_RELIC_1:
        this.relic1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_DESTROYER_1:
        this.destroyer1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_CRUISER_1:
        this.cruiser1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_DREADNOUGHT_1:
        this.dreadnought1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_CARRIER_1:
        this.carrier1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.NAME_MECH_1:
        this.mech1(playerData, objectiveName, objectiveDesc, data);
        break;
      case Objectives.techSpeciality:
        this.techSpeciality(playerData, objectiveName, objectiveDesc, data);
        break;

      /*static NAME_SA_ADJ_HS_UNIT = "Tactical positioning";
  static NAME_SA_ADJ_HS_CONTROL = "Intimidation";
  static NAME_SA_HS_UNIT = "Spying home systems";
  static NAME_SA_ADJ_HS_CONTROL = "Blockade";
  static NAME_SPACE_COUNT = "Space extension";
  static NAME_SPACE_COUNT_2 = "Space domination";

  static NAME_PL_ADJ_HS_UNIT = "Tactical invasions";
  static NAME_PL_ADJ_HS_CONTROL = "Pillage";
  static NAME_PL_HS_UNIT = "Home planets spying";
  static NAME_PL_HS_CONTROL = "Genocide";
  static NAME_PLANET_COUNT = "World extension";
  static NAME_PLANET_COUNT_2 = "World domination";

  static NAME_STRUCTURES = "Construction";*/

      //New objectives
      case Objectives.NAME_SA_ADJ_HS_UNIT:
        this.saAdjHsUnit(playerData, objectiveName, objectiveDesc, data);
        break;

      case Objectives.NAME_PL_ADJ_HS_UNIT:
        this.plAdjHsUnit(playerData, objectiveName, objectiveDesc, data);
        break;

      case Objectives.NAME_SA_ADJ_HS_CONTROL:
        this.saAdjHsControl(playerData, objectiveName, objectiveDesc, data);
        break;

      case Objectives.NAME_PL_ADJ_HS_CONTROL:
        this.plAdjHsControl(playerData, objectiveName, objectiveDesc, data);
        break;

      case Objectives.NAME_SA_HS_UNIT:
        this.saHsUnit(playerData, objectiveName, objectiveDesc, data);
        break;

      case Objectives.NAME_PL_HS_UNIT:
        this.plHsUnit(playerData, objectiveName, objectiveDesc, data);
        break;

      case Objectives.NAME_SA_HS_CONTROL:
        this.saHsControl(playerData, objectiveName, objectiveDesc, data);
        break;

      case Objectives.NAME_PL_HS_CONTROL:
        this.plHsControl(playerData, objectiveName, objectiveDesc, data);
        break;

      case Objectives.NAME_SPACE_COUNT:
        this.spaceCount(playerData, objectiveName, objectiveDesc, data);
        break;

      case Objectives.NAME_SPACE_COUNT_2:
        this.spaceCount2(playerData, objectiveName, objectiveDesc, data);
        break;

      case Objectives.NAME_PLANET_COUNT:
        this.planetCount(playerData, objectiveName, objectiveDesc, data);
        break;

      case Objectives.NAME_PLANET_COUNT_2:
        this.planetCount2(playerData, objectiveName, objectiveDesc, data);
        break;

      case Objectives.NAME_STRUCTURES:
        this.structures(playerData, objectiveName, objectiveDesc, data);
        break;

      default:
        throw new Error("Minor Faction mission name not found");
    }
  };

  static spendScience1(playerData, objectiveName, objectiveDesc, data) {
    PlayerData.spendCost(playerData, Cost.createCost({ science: 8 }));

    this.logScoring(playerData, objectiveDesc);
  }

  static spendMineral1(playerData, objectiveName, objectiveDesc, data) {
    PlayerData.spendCost(playerData, Cost.createCost({ mineral: 10 }));

    this.logScoring(playerData, objectiveDesc);
  }

  static spendEnergy1(playerData, objectiveName, objectiveDesc, data) {
    PlayerData.spendCost(playerData, Cost.createCost({ energy: 8 }));

    this.logScoring(playerData, objectiveDesc);
  }

  static spendCredit1(playerData, objectiveName, objectiveDesc, data) {
    PlayerData.spendCost(playerData, Cost.createCost({ credit: 6 }));

    this.logScoring(playerData, objectiveDesc);
  }

  static spendPopulation1(playerData, objectiveName, objectiveDesc, data) {
    PlayerData.spendCost(playerData, Cost.createCost({ population: 8 }));

    this.logScoring(playerData, objectiveDesc);
  }

  static planetIceWater1(playerData, objectiveName, objectiveDesc, data) {
    const types = ["Ice", "Oceanic"];
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );

    //Check that plantes include at least 4 planets of type ice or oceanic
    let count = 0;
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      if (types.includes(planet.type)) {
        count++;
      }
    }

    if (count < 3) {
      throw new Error(
        "You must control at least 3 planets of type ice or oceanic to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static planetEarthDesert1(playerData, objectiveName, objectiveDesc, data) {
    const types = ["Terran", "Desert"];
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );

    //Check that plantes include at least 4 planets of type ice or oceanic
    let count = 0;
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      if (types.includes(planet.type)) {
        count++;
      }
    }

    if (count < 3) {
      throw new Error(
        "You must control at least 3 planets of type terran or desert to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static planetGazVolcanic1(playerData, objectiveName, objectiveDesc, data) {
    const types = ["Gaz", "Volcanic"];
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );

    //Check that plantes include at least 4 planets of type ice or oceanic
    let count = 0;
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      if (types.includes(planet.type)) {
        count++;
      }
    }

    if (count < 3) {
      throw new Error(
        "You must control at least 3 planets of type gaz or volcanic to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static emptySpace1(playerData, objectiveName, objectiveDesc, data) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      const planets = System.getPlanets(system);
      if (planets.length === 0) {
        count++;
      }
    }

    if (count < 2) {
      throw new Error(
        "You must control at least 2 systems with no planet to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static whormhole1(playerData, objectiveName, objectiveDesc, data) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      let countWH = 0;
      const objects = System.getSpaceObjects(system);
      for (let j = 0; j < objects.length; j++) {
        const object = objects[j];
        if (object.type === SecondaryObject.SECONDARY_OBJECT_WORMHOLE) {
          countWH++;
        }
      }
      if (countWH > 0) {
        count++;
      }
    }

    if (count < 2) {
      throw new Error(
        "You must control at least 2 systems with at least one wormhole to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static minorFaction1(playerData, objectiveName, objectiveDesc, data) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      const objects = System.getSpaceObjects(system);
      for (let j = 0; j < objects.length; j++) {
        const object = objects[j];
        if (object.type === SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION) {
          count++;
        }
      }
    }

    if (count < 12) {
      throw new Error(
        "You must control the space area of at least 8 exiled to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static spaceMiddle1(playerData, objectiveName, objectiveDesc, data) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );
    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      if (
        Map.getDistanceBetweenSystems(playerData, system, hs, playerData.map) >=
        2
      ) {
        count++;
      }
    }

    if (count < 4) {
      throw new Error(
        "You must control the space area of at least 4 systems at a distance of at least 2 from your home system to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static planetMiddle1(playerData, objectiveName, objectiveDesc, data) {
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );
    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      const system = Map.getSystemFromSpaceObject(planet, playerData.map);

      if (
        Map.getDistanceBetweenSystems(playerData, system, hs, playerData.map) >=
        2
      ) {
        count++;
      }
    }

    if (count < 3) {
      throw new Error(
        "You must control at least 3 planets at a distance of at least 2 from your home system to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static spaceFar1(playerData, objectiveName, objectiveDesc, data) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );
    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      if (
        Map.getDistanceBetweenSystems(playerData, system, hs, playerData.map) >=
        3
      ) {
        count++;
      }
    }

    if (count < 2) {
      throw new Error(
        "You must control the space area of at least 3 systems at a distance of at least 3 from your home system to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static spaceFar2(playerData, objectiveName, objectiveDesc, data) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );
    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      if (
        Map.getDistanceBetweenSystems(playerData, system, hs, playerData.map) >=
        3
      ) {
        count++;
      }
    }

    if (count < 3) {
      throw new Error(
        "You must control the space area of at least 3 systems at a distance of at least 3 from your home system to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static planetFar1(playerData, objectiveName, objectiveDesc, data) {
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );
    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      const system = Map.getSystemFromSpaceObject(planet, playerData.map);

      if (
        Map.getDistanceBetweenSystems(playerData, system, hs, playerData.map) >=
        3
      ) {
        count++;
      }
    }

    if (count < 2) {
      throw new Error(
        "You must control at least 2 planets at a distance of at least 3 from your home system to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static planetFar2(playerData, objectiveName, objectiveDesc, data) {
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );
    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      const system = Map.getSystemFromSpaceObject(planet, playerData.map);

      if (
        Map.getDistanceBetweenSystems(playerData, system, hs, playerData.map) >=
        3
      ) {
        count++;
      }
    }

    if (count < 3) {
      throw new Error(
        "You must control at least 2 planets at a distance of at least 3 from your home system to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static spaceHS1(playerData, objectiveName, objectiveDesc, data) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );

    let targetSystems = [];
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      if (
        system.isHomeSystem &&
        PlayerData.getHomeSystemOfFaction(
          playerData,
          playerData.faction.name
        ) !== system
      ) {
        targetSystems.push(system);
      }
    }

    if (targetSystems.length < 1) {
      throw new Error(
        "You must control at least one other player's home system to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static planetHS1(playerData, objectiveName, objectiveDesc, data) {
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );

    let targetSystems = [];
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      const system = Map.getSystemFromSpaceObject(planet, playerData.map);
      if (
        system.isHomeSystem &&
        PlayerData.getHomeSystemOfFaction(
          playerData,
          playerData.faction.name
        ) !== system
      ) {
        targetSystems.push(system);
      }
    }

    if (targetSystems.length < 1) {
      throw new Error(
        "You must control at least one planet in another player's home system to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static techRed1(playerData, objectiveName, objectiveDesc, data) {
    const techTree = playerData.techTree;
    const techOwnedList = TechTree.getTechOwned(techTree);

    let count = 0;
    for (let i = 0; i < techOwnedList.length; i++) {
      const tech = techOwnedList[i];
      if (tech.color === Color.COLOR_NAME_TECH_RED) {
        count++;
      }
    }

    if (count < 2) {
      throw new Error(
        "You must own at least 2 red technologies to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static techBlue1(playerData, objectiveName, objectiveDesc, data) {
    const techTree = playerData.techTree;
    const techOwnedList = TechTree.getTechOwned(techTree);

    let count = 0;
    for (let i = 0; i < techOwnedList.length; i++) {
      const tech = techOwnedList[i];
      if (tech.color === Color.COLOR_NAME_TECH_BLUE) {
        count++;
      }
    }

    if (count < 2) {
      throw new Error(
        "You must own at least 2 blue technologies to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static techGreen1(playerData, objectiveName, objectiveDesc, data) {
    const techTree = playerData.techTree;
    const techOwnedList = TechTree.getTechOwned(techTree);

    let count = 0;
    for (let i = 0; i < techOwnedList.length; i++) {
      const tech = techOwnedList[i];
      if (tech.color === Color.COLOR_NAME_TECH_GREEN) {
        count++;
      }
    }

    if (count < 2) {
      throw new Error(
        "You must own at least 2 green technologies to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static techYellow1(playerData, objectiveName, objectiveDesc, data) {
    const techTree = playerData.techTree;
    const techOwnedList = TechTree.getTechOwned(techTree);

    let count = 0;
    for (let i = 0; i < techOwnedList.length; i++) {
      const tech = techOwnedList[i];
      if (tech.color === Color.COLOR_NAME_TECH_YELLOW) {
        count++;
      }
    }

    if (count < 2) {
      throw new Error(
        "You must own at least 2 yellow technologies to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static techSpeciality(playerData, objectiveName, objectiveDesc, data) {
    const techTree = playerData.techTree;
    const techOwnedList = TechTree.getTechOwned(techTree);

    let countRed = 0;
    let countBlue = 0;
    let countGreen = 0;
    let countYellow = 0;
    for (let i = 0; i < techOwnedList.length; i++) {
      const tech = techOwnedList[i];
      if (tech.color === Color.COLOR_NAME_TECH_RED) {
        countRed++;
      }
      if (tech.color === Color.COLOR_NAME_TECH_BLUE) {
        countBlue++;
      }
      if (tech.color === Color.COLOR_NAME_TECH_GREEN) {
        countGreen++;
      }
      if (tech.color === Color.COLOR_NAME_TECH_YELLOW) {
        countYellow++;
      }
    }

    if (Math.max(countRed, countBlue, countGreen, countYellow) < 4) {
      throw new Error(
        "You must own at least 4 technologies of the same color to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static techDiversity1(playerData, objectiveName, objectiveDesc, data) {
    const techTree = playerData.techTree;
    const techOwnedList = TechTree.getTechOwned(techTree);

    //Check that we have at least 2 techs of each color
    let red = 0;
    let blue = 0;
    let green = 0;
    let yellow = 0;
    for (let i = 0; i < techOwnedList.length; i++) {
      const tech = techOwnedList[i];
      if (tech.color === Color.COLOR_NAME_TECH_RED) {
        red++;
      }
      if (tech.color === Color.COLOR_NAME_TECH_BLUE) {
        blue++;
      }
      if (tech.color === Color.COLOR_NAME_TECH_GREEN) {
        green++;
      }
      if (tech.color === Color.COLOR_NAME_TECH_YELLOW) {
        yellow++;
      }
    }

    if (red < 1 || blue < 1 || green < 1 || yellow < 1) {
      throw new Error(
        "You must own at least 1 technologies of each color to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static techDiversity2(playerData, objectiveName, objectiveDesc, data) {
    const techTree = playerData.techTree;
    const techOwnedList = TechTree.getTechOwned(techTree);

    //Check that we have at least 2 techs of each color
    let red = 0;
    let blue = 0;
    let green = 0;
    let yellow = 0;
    for (let i = 0; i < techOwnedList.length; i++) {
      const tech = techOwnedList[i];
      if (tech.color === Color.COLOR_NAME_TECH_RED) {
        red++;
      }
      if (tech.color === Color.COLOR_NAME_TECH_BLUE) {
        blue++;
      }
      if (tech.color === Color.COLOR_NAME_TECH_GREEN) {
        green++;
      }
      if (tech.color === Color.COLOR_NAME_TECH_YELLOW) {
        yellow++;
      }
    }

    if (red < 2 || blue < 2 || green < 2 || yellow < 2) {
      throw new Error(
        "You must own at least 2 technologies of each color to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static structuresCanon1(playerData, objectiveName, objectiveDesc, data) {
    const units = Map.getUnitsFromTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_PLANETARY_CANON
    );

    if (units.length < 5) {
      throw new Error(
        "You must have at least 5 Planetary Canons to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static structuresFactory1(playerData, objectiveName, objectiveDesc, data) {
    const units = Map.getUnitsFromTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_FACTORY
    );

    if (units.length < 3) {
      throw new Error(
        "You must have at least 3 Factories to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static structuresAO1(playerData, objectiveName, objectiveDesc, data) {
    const units = Map.getUnitsFromTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_ASSEMBLY_ORBITAL
    );

    if (units.length < 3) {
      throw new Error(
        "You must have at least 3 Assembly Orbitals to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static structuresPlanetaryShields1(
    playerData,
    objectiveName,
    objectiveDesc,
    data
  ) {
    const units = Map.getUnitsFromTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_PLANETARY_SHIELD
    );

    if (units.length < 5) {
      throw new Error(
        "You must have at least 5 Planetary Shields to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static artifact1(playerData, objectiveName, objectiveDesc, data) {
    const artifacts = Item.getArtifactsFromItems(playerData.items);

    if (artifacts.length < 4) {
      throw new Error(
        "You must have at least 4 artifacts to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static relic1(playerData, objectiveName, objectiveDesc, data) {
    const relics = Item.getArtifactsFromItems(playerData.items).filter(
      (item) => item.faction !== Item.playerData.faction.name
    );

    if (relics.length < 3) {
      throw new Error(
        "You must have at least 3 relics from other factions to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static destroyer1(playerData, objectiveName, objectiveDesc, data) {
    const systems = Map.getSystemWhereAtLeastOneUnitOfTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_DESTROYER
    );

    if (systems.length < 6) {
      throw new Error(
        "You must have at least 1 destroyers in at least 6 different systems to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static cruiser1(playerData, objectiveName, objectiveDesc, data) {
    const systems = Map.getSystemWhereAtLeastOneUnitOfTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_CRUSER
    );

    if (systems.length < 5) {
      throw new Error(
        "You must have at least 1 cruser in at least 5 different systems to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static dreadnought1(playerData, objectiveName, objectiveDesc, data) {
    const systems = Map.getSystemWhereAtLeastOneUnitOfTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_DREADNOUGH
    );

    if (systems.length < 4) {
      throw new Error(
        "You must have at least 1 dreadnough in at least 4 different systems to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static carrier1(playerData, objectiveName, objectiveDesc, data) {
    const units = Map.getUnitsFromTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_CARRIER
    );

    if (units.length < 6) {
      throw new Error(
        "You must have at least 6 Carriers to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static mech1(playerData, objectiveName, objectiveDesc, data) {
    const planets = Map.getPlanetsWhereAtLeastOneUnitOfTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_MECH
    );

    if (planets.length < 4) {
      throw new Error(
        "You must have at least 1 Mech on at least 4 different planets to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static saAdjHsUnit(playerData, objectiveName, objectiveDesc, data) {
    const systems = Map.getSystemsWhereFactionHasShips(
      playerData.map,
      playerData.faction.name
    );

    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      const adjacentSystems = Map.getAdjacentSystems(
        playerData,
        system,
        playerData.map,
        false
      );

      for (let j = 0; j < adjacentSystems.length; j++) {
        const adjSystem = adjacentSystems[j];
        if (System.isHomeSystem(adjSystem) && adjSystem.name !== hs.name) {
          count++;
        }
      }
    }

    if (count < 2) {
      throw new Error(
        "There should be at least 2 systems compliant with the scoring condition."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static plAdjHsUnit(playerData, objectiveName, objectiveDesc, data) {
    const planets = Map.getPlanetsWhereFactionHasUnit(
      playerData,
      playerData.faction.name
    );

    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      const system = Map.getSystemFromSpaceObject(planet, playerData.map);
      const adjacentSystems = Map.getAdjacentSystems(
        playerData,
        system,
        playerData.map,
        false
      );

      for (let j = 0; j < adjacentSystems.length; j++) {
        const adjSystem = adjacentSystems[j];
        if (System.isHomeSystem(adjSystem) && adjSystem.name !== hs.name) {
          count++;
        }
      }
    }

    if (count < 2) {
      throw new Error(
        "There should be at least 2 planets compliant with the scoring condition."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static saAdjHsControl(playerData, objectiveName, objectiveDesc, data) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );

    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];

      const adjacentSystems = Map.getAdjacentSystems(
        playerData,
        system,
        playerData.map,
        false
      );

      for (let j = 0; j < adjacentSystems.length; j++) {
        const adjSystem = adjacentSystems[j];
        if (System.isHomeSystem(adjSystem) && adjSystem.name !== hs.name) {
          count++;
        }
      }
    }

    if (count < 1) {
      throw new Error(
        "There should be at least 1 system compliant with the scoring condition."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static plAdjHsControl(playerData, objectiveName, objectiveDesc, data) {
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );

    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      const system = Map.getSystemFromSpaceObject(planet, playerData.map);
      const adjacentSystems = Map.getAdjacentSystems(
        playerData,
        system,
        playerData.map,
        false
      );

      for (let j = 0; j < adjacentSystems.length; j++) {
        const adjSystem = adjacentSystems[j];
        if (System.isHomeSystem(adjSystem) && adjSystem.name !== hs.name) {
          count++;
        }
      }
    }

    if (count < 1) {
      throw new Error(
        "There should be at least 1 planet compliant with the scoring condition."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static saHsUnit(playerData, objectiveName, objectiveDesc, data) {
    const systems = Map.getSystemsWhereFactionHasShips(
      playerData.map,
      playerData.faction.name
    );

    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      if (System.isHomeSystem(system) && system.name !== hs.name) {
        count++;
      }
    }

    if (count < 2) {
      throw new Error(
        "There should be at least 2 systems compliant with the scoring condition."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }
  static plHsUnit(playerData, objectiveName, objectiveDesc, data) {
    const planets = Map.getPlanetsWhereFactionHasUnit(
      playerData,
      playerData.faction.name
    );

    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      const system = Map.getSystemFromSpaceObject(planet, playerData.map);
      if (System.isHomeSystem(system) && system.name !== hs.name) {
        count++;
      }
    }

    if (count < 2) {
      throw new Error(
        "There should be at least 2 planets compliant with the scoring condition."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static saHsControl(playerData, objectiveName, objectiveDesc, data) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );

    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      if (System.isHomeSystem(system) && system.name !== hs.name) {
        count++;
      }
    }

    if (count < 1) {
      throw new Error(
        "There should be at least 1 system compliant with the scoring condition."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static plHsControl(playerData, objectiveName, objectiveDesc, data) {
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );

    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      const system = Map.getSystemFromSpaceObject(planet, playerData.map);
      if (System.isHomeSystem(system) && system.name !== hs.name) {
        count++;
      }
    }

    if (count < 1) {
      throw new Error(
        "There should be at least 1 planet compliant with the scoring condition."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static spaceCount(playerData, objectiveName, objectiveDesc, data) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );

    if (systems.length < 8) {
      throw new Error(
        "You must control at least 8 systems to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static spaceCount2(playerData, objectiveName, objectiveDesc, data) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );

    if (systems.length < 10) {
      throw new Error(
        "You must control at least 10 systems to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static planetCount(playerData, objectiveName, objectiveDesc, data) {
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );

    if (planets.length < 6) {
      throw new Error(
        "You must control at least 6 planets to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static planetCount2(playerData, objectiveName, objectiveDesc, data) {
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );

    if (planets.length < 9) {
      throw new Error(
        "You must control at least 9 planets to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static structures(playerData, objectiveName, objectiveDesc, data) {
    const units = Map.getAllUnitsFromFaction(
      playerData,
      playerData.map,
      playerData.faction.name
    );

    let count = 0;

    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      if (unit.class === Unit.CLASS_STRUCTURE) {
        count++;
      }
    }

    if (count < 10) {
      throw new Error(
        "You must have at least 10 structures to resolve this objective."
      );
    }

    this.logScoring(playerData, objectiveDesc);
  }

  static logScoring = (playerData, objectiveDesc) => {
    ScoreData.addScore(
      playerData.scoreData,
      objectiveDesc.vp,
      "Scoring objective " + objectiveDesc.name + "."
    );
  };
}

module.exports = ResolveObjective;
