import AbilityCommon from "../../Data/GameData/Ability/AbilityCommon";
import ResolveMinorFactionAbility from "../../Data/GameData/Ability/ResolveMinorFactionAbility";
import ResolveObjective from "../../Data/GameData/Ability/ResolveObjective";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import Map from "../../Data/GameData/MapData/Map";
import SecondaryObject from "../../Data/GameData/MapData/SecondaryObject";
import MinorFaction from "../../Data/GameData/MinorFaction/MinorFaction";
import Objectives from "../../Data/GameData/Objectifs/Objectives";
import Faction from "../../Data/GameData/PlayerData/Faction";
import StaticGameData from "../../Data/GameData/StaticGameData";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import PlanetView from "../Object/PlanetView";
import InteractionFunctions from "../UIInteraction/InteractionFunctions";
const TechList = require("../../Data/GameData/Technology/TechList");
const ResolveTechAbility = require("../../Data/GameData/Ability/ResolveTechAbility");

const {
  default: SelectionListView,
} = require("../Utils/Layout/SelectionListView");
const System = require("../../Data/GameData/MapData/System");

class ObjectiveInput {
  static routeObjectiveInput(objectiveName) {
    const playerData = StaticGameData.getPlayerData();
    const objectiveDesc = Objectives.createDesc(objectiveName);
    if (Objectives.hasScoredObjective(playerData, objectiveName)) {
      UIMessage.displayInfoMessage(
        "Objective already scored",
        "You already scored this objective"
      );
      return;
    }

    switch (objectiveName) {
      case Objectives.NAME_SPEND_SCIENCE_1:
        this.spendScience1(objectiveName);
        break;
      case Objectives.NAME_SPEND_MINERAL_1:
        this.spendMineral1(objectiveName);
        break;
      case Objectives.NAME_SPEND_ENERGY_1:
        this.spendEnergy1(objectiveName);
        break;
      case Objectives.NAME_SPEND_CREDIT_1:
        this.spendCredit1(objectiveName);
        break;
      case Objectives.NAME_SPEND_POPULATION_1:
        this.spendPopulation1(objectiveName);
        break;
      case Objectives.NAME_PLANET_ICE_WATER_1:
        this.planetIceWater1(objectiveName);
        break;
      case Objectives.NAME_PLANET_EARTH_DESERT_1:
        this.planetEarthDesert1(objectiveName);
        break;
      case Objectives.NAME_PLANET_GAZ_VOLCANIC_1:
        this.planetGazVolcanic1(objectiveName);
        break;
      case Objectives.NAME_EMPTY_SPACE_1:
        this.emptySpace1(objectiveName);
        break;
      case Objectives.NAME_WHORMHOLE_1:
        this.whormhole1(objectiveName);
        break;
      case Objectives.NAME_MINOR_FACTION_1:
        this.minorFaction1(objectiveName);
        break;
      case Objectives.NAME_SPACE_MIDDLE_1:
        this.spaceMiddle1(objectiveName);
        break;
      case Objectives.NAME_PLANET_MIDDLE_1:
        this.planetMiddle1(objectiveName);
        break;
      case Objectives.NAME_SPACE_FAR_1:
        this.spaceFar1(objectiveName);
        break;
      case Objectives.NAME_PLANET_FAR_1:
        this.planetFar1(objectiveName);
        break;
      case Objectives.NAME_SPACE_HS_1:
        this.spaceHS1(objectiveName);
        break;
      case Objectives.NAME_PLANET_HS_1:
        this.planetHS1(objectiveName);
        break;
      case Objectives.NAME_TECH_RED_1:
        this.techRed1(objectiveName);
        break;
      case Objectives.NAME_TECH_BLUE_1:
        this.techBlue1(objectiveName);
        break;
      case Objectives.NAME_TECH_GREEN_1:
        this.techGreen1(objectiveName);
        break;
      case Objectives.NAME_TECH_YELLOW_1:
        this.techYellow1(objectiveName);
        break;
      case Objectives.NAME_TECH_DIVERSITY_1:
        this.techDiversity1(objectiveName);
        break;
      case Objectives.NAME_STRUCTURES_CANON_1:
        this.structuresCanon1(objectiveName);
        break;
      case Objectives.NAME_STRUCTURES_FACTORY_1:
        this.structuresFactory1(objectiveName);
        break;
      case Objectives.NAME_STRUCTURES_AO_1:
        this.structuresAO1(objectiveName);
        break;
      case Objectives.NAME_STRUCTURES_PLANETARY_SHIELS_1:
        this.structuresPlanetaryShields1(objectiveName);
        break;
      case Objectives.NAME_ARTIFACT_1:
        this.artifact1(objectiveName);
        break;
      case Objectives.NAME_RELIC_1:
        this.relic1(objectiveName);
        break;
      case Objectives.NAME_DESTROYER_1:
        this.destroyer1(objectiveName);
        break;
      case Objectives.NAME_CRUISER_1:
        this.cruiser1(objectiveName);
        break;
      case Objectives.NAME_DREADNOUGHT_1:
        this.dreadnought1(objectiveName);
        break;
      case Objectives.NAME_CARRIER_1:
        this.carrier1(objectiveName);
        break;
      case Objectives.NAME_MECH_1:
        this.mech1(objectiveName);
        break;
      case Objectives.NAME_TECH_SPECIALITY:
        this.techSpeciality(objectiveName);
        break;

      default:
        throw new Error("Minor Faction mission name not found");
    }
  }

  static spendScience1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static spendMineral1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static spendEnergy1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static spendCredit1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static spendPopulation1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static planetIceWater1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static planetEarthDesert1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static planetGazVolcanic1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static emptySpace1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static whormhole1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static minorFaction1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static spaceMiddle1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static planetMiddle1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static spaceFar1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static planetFar1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static spaceHS1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static planetHS1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static techRed1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static techBlue1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static techGreen1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static techYellow1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static techDiversity1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static structuresCanon1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static structuresFactory1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static structuresAO1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static structuresPlanetaryShields1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static artifact1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static relic1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static destroyer1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static cruiser1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static dreadnought1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static carrier1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static mech1(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }

  static techSpeciality(objectiveName) {
    ResolveObjective.sendToServerCommon(objectiveName);
  }
}

export default ObjectiveInput;
