const Unit = require("../MapData/Unit");

const TechTree = require("../Technology/TechTree");
const TechList = require("../Technology/TechList");
const Map = require("../MapData/Map");
const MapUnitaryAction = require("../UnitaryAction/MapUnitaryAction");
const Tech = require("../Technology/Tech");
const Fleet = require("../MapData/Fleet");
const System = require("../MapData/System");
const ResolveData = require("../EndOfRound/ResolveData");
const CombatData = require("../Combat/CombatData");
const LogBook = require("../Connection/LogBook");
const SolveFleetLimitData = require("../MandatoryAction/SolveFleetLimitData");
const SolveCapacityData = require("../MandatoryAction/SolveCapacityData");
const CombatRecording = require("../Combat/CombatRecording");
const PlayerData = require("../PlayerData/PlayerData");
const Cost = require("../Utils/Cost");
const SecondaryObject = require("../MapData/SecondaryObject");
const ScoreData = require("../Objectifs/ScoreData");
const MinorFaction = require("../MinorFaction/MinorFaction");
const Request = require("../../../Common/Requests/Request");
const AbilityCommon = require("./AbilityCommon");
const Phase = require("../Game/Phase");
const StaticGameData = require("../StaticGameData");
const Objectives = require("../Objectifs/Objectives");
const Color = require("../../../Common/Config/Colors");
const Item = require("../Transactions/Item");
const UIMessage = require("../Connection/UIMessage");

class ResolveMinorFactionMission {
  //static PHASE_TECH_ABILITY = "resolveTechAbility";

  static sendToServerCommon(spaceObject, system, actionData) {
    const playerData = StaticGameData.getPlayerData();
    const data = {
      spaceObjectName: spaceObject.name,
      data: actionData,
    };

    playerData.phase = AbilityCommon.PHASE_RESOLVE_ABILITY;
    playerData.step = Phase.STEP_MINOR_FACTION_MISSION;

    UIMessage.displayConfirmMessage(
      "Validate mission ?",
      "Do you want to validate this mission ?",
      () => {
        Request.updateGameState(data);
      }
    );
  }

  static resolveCommon = (playerData, updateData) => {
    const spaceObjectName = updateData.spaceObjectName;
    const data = updateData.data;
    const system = Map.getSystemFromSpaceObjectName(
      playerData.map,
      spaceObjectName
    );
    const spaceObject = System.getSpaceObjectFromName(system, spaceObjectName);
    const objective = SecondaryObject.getObjectiveData(spaceObject);

    const objectiveDesc = Objectives.createDesc(objective);

    if (objective.missionDone === true) {
      throw new Error(
        "This mission has already been accomplished by a player. A mission can be done only one time for the entire game."
      );
    }
    objective.missionDone = true;

    if (!playerData.faction) {
      throw new Error("You have to pick a faction first.");
    }

    if (system.faction !== playerData.faction.name) {
      throw new Error(
        "You do not have control of the space area of the system " +
          system.name +
          ". To resolve an objective, you must have control of the space area of the system it is in."
      );
    }

    this.routeMission(
      playerData,
      system,
      spaceObject,
      objective,
      objectiveDesc,
      data
    );
  };

  static gainVP = (playerData, system, minorFaction) => {
    const scoreData = PlayerData.getScoreData(playerData);
    ScoreData.addScore(
      scoreData,
      minorFaction.vp,
      " Ability of " + minorFaction.name + " in the system " + system.name + "."
    );
    MinorFaction.resetVP(minorFaction);
  };

  static routeMission = (
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) => {
    switch (objective.name) {
      case Objectives.NAME_SPEND_SCIENCE_1:
        this.spendScience1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_SPEND_MINERAL_1:
        this.spendMineral1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_SPEND_ENERGY_1:
        this.spendEnergy1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_SPEND_CREDIT_1:
        this.spendCredit1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_SPEND_POPULATION_1:
        this.spendPopulation1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_PLANET_ICE_WATER_1:
        this.planetIceWater1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_PLANET_EARTH_DESERT_1:
        this.planetEarthDesert1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_PLANET_GAZ_VOLCANIC_1:
        this.planetGazVolcanic1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_EMPTY_SPACE_1:
        this.emptySpace1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_WHORMHOLE_1:
        this.whormhole1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_MINOR_FACTION_1:
        this.minorFaction1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_SPACE_MIDDLE_1:
        this.spaceMiddle1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_PLANET_MIDDLE_1:
        this.planetMiddle1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_SPACE_FAR_1:
        this.spaceFar1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_PLANET_FAR_1:
        this.planetFar1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_SPACE_HS_1:
        this.spaceHS1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_PLANET_HS_1:
        this.planetHS1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_TECH_RED_1:
        this.techRed1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_TECH_BLUE_1:
        this.techBlue1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_TECH_GREEN_1:
        this.techGreen1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_TECH_YELLOW_1:
        this.techYellow1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_TECH_DIVERSITY_1:
        this.techDiversity1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_STRUCTURES_CANON_1:
        this.structuresCanon1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_STRUCTURES_FACTORY_1:
        this.structuresFactory1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_STRUCTURES_AO_1:
        this.structuresAO1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_STRUCTURES_PLANETARY_SHIELS_1:
        this.structuresPlanetaryShields1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_ARTIFACT_1:
        this.artifact1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_RELIC_1:
        this.relic1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_DESTROYER_1:
        this.destroyer1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_CRUISER_1:
        this.cruiser1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_DREADNOUGHT_1:
        this.dreadnought1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_CARRIER_1:
        this.carrier1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;
      case Objectives.NAME_MECH_1:
        this.mech1(
          playerData,
          system,
          spaceObject,
          objective,
          objectiveDesc,
          data
        );
        break;

      default:
        throw new Error("Minor Faction mission name not found");
    }
  };

  static spendScience1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    PlayerData.spendCost(playerData, Cost.createCost({ science: 8 }));

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static spendMineral1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    PlayerData.spendCost(playerData, Cost.createCost({ mineral: 8 }));

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static spendEnergy1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    PlayerData.spendCost(playerData, Cost.createCost({ energy: 6 }));

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static spendCredit1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    PlayerData.spendCost(playerData, Cost.createCost({ credit: 8 }));

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static spendPopulation1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    PlayerData.spendCost(playerData, Cost.createCost({ population: 8 }));

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static planetIceWater1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const types = ["ice", "oceanic"];
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );

    //Check that plantes include at least 4 planets of type ice or oceanic
    let count = 0;
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      if (types.includes(planet.type)) {
        count++;
      }
    }

    if (count < 4) {
      throw new Error(
        "You must control at least 4 planets of type ice or oceanic to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static planetEarthDesert1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const types = ["terran", "desert"];
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );

    //Check that plantes include at least 4 planets of type ice or oceanic
    let count = 0;
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      if (types.includes(planet.type)) {
        count++;
      }
    }

    if (count < 4) {
      throw new Error(
        "You must control at least 4 planets of type terran or desert to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static planetGazVolcanic1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const types = ["gaz", "volcanic"];
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );

    //Check that plantes include at least 4 planets of type ice or oceanic
    let count = 0;
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      if (types.includes(planet.type)) {
        count++;
      }
    }

    if (count < 4) {
      throw new Error(
        "You must control at least 4 planets of type gaz or volcanic to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static emptySpace1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      const planets = System.getPlanets(system);
      if (planets.length === 0) {
        count++;
      }
    }

    if (count < 2) {
      throw new Error(
        "You must control at least 2 systems with no planet to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static whormhole1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      let countWH = 0;
      const objects = System.getSpaceObjects(system);
      for (let j = 0; j < objects.length; j++) {
        const object = objects[j];
        if (object.type === SecondaryObject.SECONDARY_OBJECT_WORMHOLE) {
          countWH++;
        }
      }
      if (countWH > 0) {
        count++;
      }
    }

    if (count < 2) {
      throw new Error(
        "You must control at least 2 systems with at least one wormhole to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static minorFaction1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      const objects = System.getSpaceObjects(system);
      for (let j = 0; j < objects.length; j++) {
        const object = objects[j];
        if (object.type === SecondaryObject.SECONDARY_OBJECT_MINOR_FACTION) {
          count++;
        }
      }
    }

    if (count < 12) {
      throw new Error(
        "You must control the space area of at least 12 exiled factions to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static spaceMiddle1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );
    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      if (
        Map.getDistanceBetweenSystems(playerData, system, hs, playerData.map) >=
        2
      ) {
        count++;
      }
    }

    if (count < 5) {
      throw new Error(
        "You must control the space area of at least 5 systems at a distance of at least 2 from your home system to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static planetMiddle1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );
    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      const system = Map.getSystemFromSpaceObject(planet, playerData.map);

      if (
        Map.getDistanceBetweenSystems(playerData, system, hs, playerData.map) >=
        2
      ) {
        count++;
      }
    }

    if (count < 4) {
      throw new Error(
        "You must control at least 4 planets at a distance of at least 2 from your home system to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static spaceFar1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );
    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      if (
        Map.getDistanceBetweenSystems(playerData, system, hs, playerData.map) >=
        3
      ) {
        count++;
      }
    }

    if (count < 3) {
      throw new Error(
        "You must control the space area of at least 3 systems at a distance of at least 3 from your home system to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static planetFar1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );
    const hs = PlayerData.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );

    let count = 0;
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      const system = Map.getSystemFromSpaceObject(planet, playerData.map);

      if (
        Map.getDistanceBetweenSystems(playerData, system, hs, playerData.map) >=
        3
      ) {
        count++;
      }
    }

    if (count < 2) {
      throw new Error(
        "You must control at least 2 planets at a distance of at least 3 from your home system to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static spaceHS1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const systems = Map.getSystemsControlledByFaction(
      playerData.map,
      playerData.faction.name
    );

    let targetSystems = [];
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      if (system.isHomeSystem) {
        targetSystems.push(system);
      }
    }

    if (targetSystems.length < 0) {
      throw new Error(
        "You must control at least one other player's home system to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description + " (" + targetSystems.join(", ") + ")"
    );
  }

  static planetHS1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const planets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );

    let targetSystems = [];
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      const system = Map.getSystemFromSpaceObject(planet, playerData.map);
      if (system.isHomeSystem) {
        targetSystems.push(system);
      }
    }

    if (targetSystems.length < 0) {
      throw new Error(
        "You must control at least one planet in another player's home system to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description + " (" + targetSystems.join(", ") + ")"
    );
  }

  static techRed1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const techTree = playerData.techTree;
    const techOwnedList = TechTree.getTechOwned(techTree);

    let count = 0;
    for (let i = 0; i < techOwnedList.length; i++) {
      const tech = techOwnedList[i];
      if (tech.color === Color.COLOR_NAME_TECH_RED) {
        count++;
      }
    }

    if (count < 4) {
      throw new Error(
        "You must own at least 4 red technologies to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static techBlue1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const techTree = playerData.techTree;
    const techOwnedList = TechTree.getTechOwned(techTree);

    let count = 0;
    for (let i = 0; i < techOwnedList.length; i++) {
      const tech = techOwnedList[i];
      if (tech.color === Color.COLOR_NAME_TECH_BLUE) {
        count++;
      }
    }

    if (count < 4) {
      throw new Error(
        "You must own at least 4 blue technologies to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static techGreen1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const techTree = playerData.techTree;
    const techOwnedList = TechTree.getTechOwned(techTree);

    let count = 0;
    for (let i = 0; i < techOwnedList.length; i++) {
      const tech = techOwnedList[i];
      if (tech.color === Color.COLOR_NAME_TECH_GREEN) {
        count++;
      }
    }

    if (count < 4) {
      throw new Error(
        "You must own at least 4 green technologies to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static techYellow1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const techTree = playerData.techTree;
    const techOwnedList = TechTree.getTechOwned(techTree);

    let count = 0;
    for (let i = 0; i < techOwnedList.length; i++) {
      const tech = techOwnedList[i];
      if (tech.color === Color.COLOR_NAME_TECH_YELLOW) {
        count++;
      }
    }

    if (count < 4) {
      throw new Error(
        "You must own at least 4 yellow technologies to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static techDiversity1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const techTree = playerData.techTree;
    const techOwnedList = TechTree.getTechOwned(techTree);

    //Check that we have at least 2 techs of each color
    let red = 0;
    let blue = 0;
    let green = 0;
    let yellow = 0;
    for (let i = 0; i < techOwnedList.length; i++) {
      const tech = techOwnedList[i];
      if (tech.color === Color.COLOR_NAME_TECH_RED) {
        red++;
      }
      if (tech.color === Color.COLOR_NAME_TECH_BLUE) {
        blue++;
      }
      if (tech.color === Color.COLOR_NAME_TECH_GREEN) {
        green++;
      }
      if (tech.color === Color.COLOR_NAME_TECH_YELLOW) {
        yellow++;
      }
    }

    if (red < 2 || blue < 2 || green < 2 || yellow < 2) {
      throw new Error(
        "You must own at least 2 technologies of each color to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static structuresCanon1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const units = Map.getUnitsFromTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_PLANETARY_CANON
    );

    if (units.length < 8) {
      throw new Error(
        "You must have at least 8 Planetary Canons to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static structuresFactory1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const units = Map.getUnitsFromTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_FACTORY
    );

    if (units.length < 4) {
      throw new Error(
        "You must have at least 4 Factories to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static structuresAO1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const units = Map.getUnitsFromTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_ASSEMBLY_ORBITAL
    );

    if (units.length < 3) {
      throw new Error(
        "You must have at least 3 Assembly Orbitals to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static structuresPlanetaryShields1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const units = Map.getUnitsFromTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_PLANETARY_SHIELD
    );

    if (units.length < 5) {
      throw new Error(
        "You must have at least 5 Planetary Shields to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static artifact1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const artifacts = Item.getArtifactsFromItems(playerData.items);

    if (artifacts.length < 6) {
      throw new Error(
        "You must have at least 6 artifacts to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static relic1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const relics = Item.getArtifactsFromItems(playerData.items).filter(
      (item) => item.faction !== Item.playerData.faction.name
    );

    if (relics.length < 4) {
      throw new Error(
        "You must have at least 4 relics from other factions to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static destroyer1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const systems = Map.getSystemWhereAtLeastOneUnitOfTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_DESTROYER
    );

    if (systems.length < 8) {
      throw new Error(
        "You must have at least 1 destroyers in at least 8 different systems to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static cruiser1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const systems = Map.getSystemWhereAtLeastOneUnitOfTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_CRUSER
    );

    if (systems.length < 6) {
      throw new Error(
        "You must have at least 1 destroyers in at least 6 different systems to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static dreadnought1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const systems = Map.getSystemWhereAtLeastOneUnitOfTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_DREADNOUGH
    );

    if (systems.length < 6) {
      throw new Error(
        "You must have at least 1 destroyers in at least 6 different systems to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static carrier1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const units = Map.getUnitsFromTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_CARRIER
    );

    if (units.length < 6) {
      throw new Error(
        "You must have at least 6 Carriers to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static mech1(
    playerData,
    system,
    spaceObject,
    objective,
    objectiveDesc,
    data
  ) {
    const planets = Map.getPlanetsWhereAtLeastOneUnitOfTypeFromFaction(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_MECH
    );

    if (planets.length < 5) {
      throw new Error(
        "You must have at least 1 Mech on at least 5 different planets to resolve this objective."
      );
    }

    this.logScoring(
      playerData,
      system,
      objectiveDesc,
      objectiveDesc.description
    );
  }

  static logScoring = (playerData, system, objectiveDesc, text) => {
    ScoreData.addScore(
      playerData.scoreData,
      objectiveDesc.vp,
      "Objective in system " + system.name + " : " + text
    );
  };
}

module.exports = ResolveMinorFactionMission;
