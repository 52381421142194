import React, { useState, useEffect } from "react";
import GameListItem from "./GameListItem";
import Game from "../Data/GameData/Game/Game";
import StaticGameData from "../Data/GameData/StaticGameData";
import BaseButton from "../GameView/Utils/BaseButton";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import Popup from "../Data/Other/Popup";
import { CreateCustomGame } from "./CreateCustomGame";
import Logo from "../GameView/Utils/Logo";
import UIMessage from "../Data/GameData/Connection/UIMessage";
import DiscordLinkView from "../MainMenu/DiscordLinkView";

export const GameListView = ({ setRouteMainHandler }) => {
  const [games, setGames] = useState([]);
  const [gameListType, setGameListType] = useState(
    Popup.getGlobalState("gameListType")
      ? Popup.getGlobalState("gameListType")
      : "Your ongoing games"
  );
  const [refreshCounter, setRefreshCounter] = useState(0);

  Popup.addGlobalState("gameListType", gameListType);
  const fetchGames = async () => {
    console.log("Fetching games...");
    try {
      Popup.setClientWaitingServer(true);
      let gameListTypeR = "";
      if (gameListType === "Your ongoing games") {
        gameListTypeR = "YourOngoingGames";
      } else if (gameListType === "Observable games") {
        gameListTypeR = "ObservableGames";
      } else if (gameListType === "Joinable custom games") {
        gameListTypeR = "CustomGames";
      } else {
        throw new Error("Invalid game list type");
      }

      const response = await fetch("/api/list-games", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gameListType: gameListTypeR,
        }),
      });
      Popup.setClientWaitingServer(false);
      if (response.ok) {
        const result = await response.json();
        setGames(result.listOfGames); // Update the state with the received game data
      } else {
        console.error("Failed to fetch games:", response.statusText);
      }
    } catch (error) {
      Popup.setClientWaitingServer(false);
      console.error("Error fetching games:", error);
    }
  };

  useEffect(() => {
    fetchGames();
  }, [gameListType, refreshCounter]);

  const handleJoinGame = async () => {
    console.debug("Joining game...");
    try {
      // Send a request to "/api/join-game"
      const response = await fetch("/api/join-game", {
        method: "GET",
      });

      if (response.ok) {
        console.log("Waiting for server response...");
        // The request was successful
        const data = await response.json();
        //console.log("VWA 2");
        console.log("Joined game successfully:", data);
        // Handle the response data as needed, such as updating the game list
        fetchGames();
      } else {
        // The request failed with an error
        console.error("Failed to join game:", response.statusText);
      }
    } catch (error) {
      console.error("Error joining game:", error);
    }
  };

  const DisplayGameListTypeChoice = () => {
    return (
      <div>
        <div>
          <BaseButton
            onClick={() => {
              setGameListType("Your ongoing games");
              Popup.deActivateBlackPopup();
              setRefreshCounter(refreshCounter + 1);
              Popup.touch();
            }}
          >
            Ongoing
          </BaseButton>
        </div>{" "}
        <div>
          {" "}
          <BaseButton
            onClick={() => {
              setGameListType("Observable games");
              Popup.deActivateBlackPopup();
              setRefreshCounter(refreshCounter + 1);

              Popup.touch();
            }}
          >
            Observe
          </BaseButton>
        </div>{" "}
        <div>
          <BaseButton
            onClick={() => {
              setGameListType("Joinable custom games");
              Popup.deActivateBlackPopup();
              setRefreshCounter(refreshCounter + 1);

              Popup.touch();
            }}
          >
            Custom Games
          </BaseButton>
        </div>
      </div>
    );
  };

  const DisplayNewGamePopup = () => {
    return (
      <div>
        <BaseButton
          onClick={() => {
            Popup.deActivateBlackPopup();
            Popup.addDynamicLayer(<CreateCustomGame></CreateCustomGame>);
          }}
        >
          Create custom game
        </BaseButton>
        <BaseButton
          onClick={() => {
            /*Popup.activateBlackPopup(
              <DisplayJoinRankedGamePopup></DisplayJoinRankedGamePopup>
            );*/
            UIMessage.displayInfoMessageToStaticGameData(
              "Ranked Game",
              "Ranked games will be available soon. This will automatically transport you in a game with other players. Your ranking will be affected by the outcome of the game."
            );
          }}
        >
          Join ranked game
        </BaseButton>
      </div>
    );
  };

  const DisplayJoinRankedGamePopup = () => {
    return (
      <div>
        <BaseButton>Join ranked game with draft</BaseButton>
        <BaseButton
          onClick={() => {
            handleJoinGame();
            Popup.deActivateBlackPopup();
          }}
        >
          Join ranked game with no draft
        </BaseButton>
      </div>
    );
  };

  const handleJoinTutorial = async () => {
    console.debug("Joining Tutorial...");
    try {
      // Send a request to "/api/join-game"
      const response = await fetch("/api/join-tutorial", {
        method: "GET",
      });

      if (response.ok) {
        console.log("Waiting for server response...");
        // The request was successful
        const data = await response.json();
        //console.log("VWA 2");
        console.log("Joined tutorial successfully:", data);
        // Handle the response data as needed, such as updating the game list
        fetchGames();
      } else {
        // The request failed with an error
        console.error("Failed to join tutorial:", response.statusText);
      }
    } catch (error) {
      console.error("Error joining tutorial:", error);
    }
  };

  useEffect(() => {
    // Fetch the list of games from the server and update the state

    fetchGames();
  }, []); // Run this effect only once when the component mounts

  return (
    <div>
      <div style={{ fontSize: "1.5em" }}>
        {" "}
        <BaseButton
          onClick={() => {
            Popup.activateBlackPopup(
              <DisplayGameListTypeChoice></DisplayGameListTypeChoice>
            );
          }}
        >
          {gameListType}...
        </BaseButton>
      </div>

      <div>
        {/* Button to create a new game */}
        <BaseButton
          onClick={() => {
            Popup.activateBlackPopup(<DiscordLinkView></DiscordLinkView>);
          }}
        >
          Discord
        </BaseButton>

        <BaseButton
          onClick={() => {
            Popup.activateBlackPopup(
              <DisplayNewGamePopup></DisplayNewGamePopup>
            );
          }}
        >
          New Game
        </BaseButton>
        <BaseButton
          onClick={() => {
            setRefreshCounter(refreshCounter + 1);
          }}
        >
          <Logo logoName="refresh icon"></Logo>
        </BaseButton>
        <LineSpace></LineSpace>
        {false && (
          <div>
            <button onClick={handleJoinTutorial} style={{ marginRight: "2em" }}>
              Join Tutorial
            </button>
            <button onClick={handleJoinGame}>Join Game</button>
          </div>
        )}
      </div>
      {gameListType === "Observable games" && (
        <div>
          <div> VERY SOON </div> <LineSpace></LineSpace>{" "}
          <div>
            {" "}
            The functionality to observe game or stream games will arrive very
            soon, it is the next one on the backlog.{" "}
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "0.5em",
          justifyContent: "center",
        }}
      >
        {/* Render the list of games */}
        {games.map((game) => {
          return (
            <div key={game.gameId}>
              <BaseButton>
                <GameListItem
                  game={game}
                  setRouteMainHandler={setRouteMainHandler}
                />
              </BaseButton>
            </div>
          );
        })}
      </div>
    </div>
  );
};
