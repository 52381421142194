import AbilityCommon from "../../Data/GameData/Ability/AbilityCommon";
import ResolveMinorFactionAbility from "../../Data/GameData/Ability/ResolveMinorFactionAbility";
import ResolveMinorFactionMission from "../../Data/GameData/Ability/ResolveMinorFactionMission";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import Map from "../../Data/GameData/MapData/Map";
import SecondaryObject from "../../Data/GameData/MapData/SecondaryObject";
import MinorFaction from "../../Data/GameData/MinorFaction/MinorFaction";
import Objectives from "../../Data/GameData/Objectifs/Objectives";
import Faction from "../../Data/GameData/PlayerData/Faction";
import StaticGameData from "../../Data/GameData/StaticGameData";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import PlanetView from "../Object/PlanetView";
import InteractionFunctions from "../UIInteraction/InteractionFunctions";
const TechList = require("../../Data/GameData/Technology/TechList");
const ResolveTechAbility = require("../../Data/GameData/Ability/ResolveTechAbility");

const {
  default: SelectionListView,
} = require("../Utils/Layout/SelectionListView");
const System = require("../../Data/GameData/MapData/System");

class MinorFactionMissionInput {
  static routeMinorFactionMissionInput(spaceObject) {
    const objective = SecondaryObject.getObjectiveData(spaceObject);
    const objectiveDesc = Objectives.createDesc(objective);
    if (objective.missionDone === true) {
      UIMessage.displayInfoMessage(
        "Mission already done",
        "This mission has already been accomplished by a player. A mission can be done only one time for the entire game."
      );
      return;
    }

    const playerData = StaticGameData.getPlayerData();
    const system = Map.getSystemFromSpaceObjectName(
      playerData.map,
      spaceObject.name
    );

    switch (objective.name) {
      case Objectives.NAME_SPEND_SCIENCE_1:
        this.spendScience1(spaceObject, system);
        break;
      case Objectives.NAME_SPEND_MINERAL_1:
        this.spendMineral1(spaceObject, system);
        break;
      case Objectives.NAME_SPEND_ENERGY_1:
        this.spendEnergy1(spaceObject, system);
        break;
      case Objectives.NAME_SPEND_CREDIT_1:
        this.spendCredit1(spaceObject, system);
        break;
      case Objectives.NAME_SPEND_POPULATION_1:
        this.spendPopulation1(spaceObject, system);
        break;
      case Objectives.NAME_PLANET_ICE_WATER_1:
        this.planetIceWater1(spaceObject, system);
        break;
      case Objectives.NAME_PLANET_EARTH_DESERT_1:
        this.planetEarthDesert1(spaceObject, system);
        break;
      case Objectives.NAME_PLANET_GAZ_VOLCANIC_1:
        this.planetGazVolcanic1(spaceObject, system);
        break;
      case Objectives.NAME_EMPTY_SPACE_1:
        this.emptySpace1(spaceObject, system);
        break;
      case Objectives.NAME_WHORMHOLE_1:
        this.whormhole1(spaceObject, system);
        break;
      case Objectives.NAME_MINOR_FACTION_1:
        this.minorFaction1(spaceObject, system);
        break;
      case Objectives.NAME_SPACE_MIDDLE_1:
        this.spaceMiddle1(spaceObject, system);
        break;
      case Objectives.NAME_PLANET_MIDDLE_1:
        this.planetMiddle1(spaceObject, system);
        break;
      case Objectives.NAME_SPACE_FAR_1:
        this.spaceFar1(spaceObject, system);
        break;
      case Objectives.NAME_PLANET_FAR_1:
        this.planetFar1(spaceObject, system);
        break;
      case Objectives.NAME_SPACE_HS_1:
        this.spaceHS1(spaceObject, system);
        break;
      case Objectives.NAME_PLANET_HS_1:
        this.planetHS1(spaceObject, system);
        break;
      case Objectives.NAME_TECH_RED_1:
        this.techRed1(spaceObject, system);
        break;
      case Objectives.NAME_TECH_BLUE_1:
        this.techBlue1(spaceObject, system);
        break;
      case Objectives.NAME_TECH_GREEN_1:
        this.techGreen1(spaceObject, system);
        break;
      case Objectives.NAME_TECH_YELLOW_1:
        this.techYellow1(spaceObject, system);
        break;
      case Objectives.NAME_TECH_DIVERSITY_1:
        this.techDiversity1(spaceObject, system);
        break;
      case Objectives.NAME_STRUCTURES_CANON_1:
        this.structuresCanon1(spaceObject, system);
        break;
      case Objectives.NAME_STRUCTURES_FACTORY_1:
        this.structuresFactory1(spaceObject, system);
        break;
      case Objectives.NAME_STRUCTURES_AO_1:
        this.structuresAO1(spaceObject, system);
        break;
      case Objectives.NAME_STRUCTURES_PLANETARY_SHIELS_1:
        this.structuresPlanetaryShields1(spaceObject, system);
        break;
      case Objectives.NAME_ARTIFACT_1:
        this.artifact1(spaceObject, system);
        break;
      case Objectives.NAME_RELIC_1:
        this.relic1(spaceObject, system);
        break;
      case Objectives.NAME_DESTROYER_1:
        this.destroyer1(spaceObject, system);
        break;
      case Objectives.NAME_CRUISER_1:
        this.cruiser1(spaceObject, system);
        break;
      case Objectives.NAME_DREADNOUGHT_1:
        this.dreadnought1(spaceObject, system);
        break;
      case Objectives.NAME_CARRIER_1:
        this.carrier1(spaceObject, system);
        break;
      case Objectives.NAME_MECH_1:
        this.mech1(spaceObject, system);
        break;

      default:
        throw new Error("Minor Faction mission name not found");
    }
  }

  static spendScience1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static spendMineral1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static spendEnergy1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static spendCredit1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static spendPopulation1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static planetIceWater1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static planetEarthDesert1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static planetGazVolcanic1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static emptySpace1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static whormhole1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static minorFaction1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static spaceMiddle1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static planetMiddle1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static spaceFar1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static planetFar1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static spaceHS1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static planetHS1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static techRed1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static techBlue1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static techGreen1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static techYellow1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static techDiversity1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static structuresCanon1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static structuresFactory1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static structuresAO1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static structuresPlanetaryShields1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static artifact1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static relic1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static destroyer1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static cruiser1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static dreadnought1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static carrier1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }

  static mech1(spaceObject, system) {
    ResolveMinorFactionMission.sendToServerCommon(spaceObject, system, {});
  }
}

export default MinorFactionMissionInput;
