import React, { useState, useEffect, useRef, Children } from "react";
import "../../CSS/Menu.css";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import Request from "../../Common/Requests/Request";
import StaticGameData from "../../Data/GameData/StaticGameData";
import Color from "../../Common/Config/Colors";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData";

import ChatRoom from "../../Data/ChatData/ChatRoom.js";
import FactionLogo from "../Utils/FactionLogo";
import ChatFactionLogo from "./ChatFactionLogo";
import BaseButton from "../Utils/BaseButton";
import useChatData from "./UseChatData";

const ChatRequest = require("../../Data/ChatData/ChatRequest");
const ChatStaticData = require("../../Data/ChatData/ChatStaticData");

const IMAGE_PLANET_TEST = "/Images/Map/Objects/planet_test.png";

const ChatMenuView = ({ setActiveRoom, navigationData }) => {
  const [chatData, setChatData] = useState(ChatStaticData.chatData);

  const { loading } = useChatData(setChatData); // Pass the callback to the custom hook

  //Loading screen
  useEffect(() => {
    if (!chatData) {
      Popup.setClientWaitingServer(true);
    } else {
      Popup.setClientWaitingServer(false);
    }
  }, [ChatStaticData.chatData]);

  //Navigation data
  navigationData.buttonsData.push(
    {
      text: "Map",
      callback: () => {
        Popup.unStackToMap();
      },
    },
    {
      text: "Back",
      callback: () => {
        Popup.goBack();
      },
    }
  );

  const DisplayRoomButton = ({ room }) => {
    console.log("room", room);
    let displayItem = null;
    if (room.playerInGameId) {
      displayItem = (
        <ChatFactionLogo
          playerInGameId={room.playerInGameId}
          showFactionName={true}
        ></ChatFactionLogo>
      );
    } else {
      displayItem = <span>Chat Room Common</span>;
    }

    return (
      <div>
        <BaseButton
          onClick={() => {
            setActiveRoom(room);
          }}
        >
          {displayItem}
        </BaseButton>
      </div>
    );
  };

  const DisplayMainChatMenu = () => {
    return (
      <div className="text-box" style={{ textAlign: "center" }}>
        <DisplayRoomButton room={chatData.commonRoom}></DisplayRoomButton>
        {chatData.factionRooms.map((room, index) => {
          return (
            <DisplayRoomButton key={index} room={room}></DisplayRoomButton>
          );
        })}
      </div>
    );
  };

  if (!chatData) return <div></div>;

  return <DisplayMainChatMenu></DisplayMainChatMenu>;
};

/*<GameGlobalLayout style={{ height: "100%" }} backgroundType="shadow">
      <div
        style={{
          height: "100%",
          overflow: "hidden",
          //backgroundColor: Color.COLOR_MANAGE_VIEW_BACKGROUND,
        }}
      >
        <DisplayChatView></DisplayChatView>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>*/

export default ChatMenuView;
