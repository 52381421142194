import React, { useState, useEffect } from "react";
import FactionLogo from "../Utils/FactionLogo.jsx";
import Logo from "../Utils/Logo.jsx";
import UnitLineView from "../Units/UnitLineView.jsx";
import SelectionListView from "../Utils/Layout/SelectionListView.jsx";
import UIMessage from "../../Data/GameData/Connection/UIMessage.js";
import FleetSummaryView from "../ManageSystem/FleetSummaryView.jsx";
import BaseButton from "../Utils/BaseButton.jsx";
import Popup from "../../Data/Other/Popup.js";
import FleetLineView from "../Object/FleetLineView.jsx";

const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const System = require("../../Data/GameData/MapData/System.js");
const Map = require("../../Data/GameData/MapData/Map.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const SolveFleetLimitData = require("../../Data/GameData/MandatoryAction/SolveFleetLimitData.js");
const MandatoryActionCA = require("../../ClientAction/MandatoryActionCA.js");

const SolveCapacityView = ({ navigationData }) => {
  console.log("RENDER SolveCapacityView ");

  const playerData = StaticGameData.getPlayerData();

  const data = PlayerData.getFirstMandatoryAction(playerData).data;

  const system = Map.getSystemFromName(playerData.map, data.systemName);

  const fleet = System.getFleet(system, playerData.faction.name);

  const units = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_ALL);
  const filteredUnits = units.filter((unit) => unit.transportRequired);

  const fleetCapacity = Fleet.getTotalCapacity(playerData, fleet);
  const fleetRequiredCapacity = Fleet.getTotalRequiredCapacity(
    playerData,
    fleet
  );

  const fleetLeft = Fleet.createFleet(playerData, playerData.faction.name);
  Fleet.addFleetToFleet(fleet, fleetLeft);
  Fleet.removeFleetFromFleet(data.fleetToRemove, fleetLeft);

  const fleetCapacityLeft = Fleet.getTotalCapacity(playerData, fleetLeft);
  const fleetRequiredCapacityLeft = Fleet.getTotalRequiredCapacity(
    playerData,
    fleetLeft
  );

  //const fleet = System.getFleet(system, playerData.faction.name);

  const confirmScrap = () => {
    UIMessage.displayConfirmMessage(
      "Scrap units",
      "Are you sure you want to scrap the selected units ?",
      /*Request.sendRequest(
          Request.GAME_ACTION_MANDATORY,
          "SolveFleetLimit",
          resolveData
        );*/ () => {
        MandatoryActionCA.resolve_STEP_MANDATORY_FLEET_CAPACITY();
      }
    );
  };

  navigationData.buttonsData.push({
    type: "action",
    text: "Scrap",
    callback: confirmScrap,
  });

  const DisplayText = () => {
    return (
      <div>
        <div>
          <div>
            Capacity issue in system :
            <BaseButton
              onClick={() => {
                Popup.addLayer({ name: "ManageSystemView", system: system });
              }}
            >
              {system.name}
            </BaseButton>
          </div>
          <div>The carrying capacity of your fleet is {fleetCapacity}.</div>
          <div>
            {" "}
            The required capacity to carry all your units is{" "}
            {fleetRequiredCapacity}.
          </div>
          <div>
            You have to scrap units until the mass of your fleet is equal lower
            than your fleet limit.
          </div>
        </div>
        <div>
          <span>
            Capacity : {fleetRequiredCapacityLeft} / {fleetCapacityLeft}
          </span>
        </div>
      </div>
    );
  };

  const DisplayScrapSelection = () => {
    const selectedUnits = Fleet.getUnits(
      data.fleetToRemove,
      Fleet.UNIT_CLASS_ALL
    );
    //const groundForces = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_GROUND_FORCE);

    const items = filteredUnits;
    const displayItem = (item) => {
      return (
        <UnitLineView
          unit={item}
          factionName={playerData.faction.name}
        ></UnitLineView>
      );
    };
    const selectedItems = selectedUnits;
    const selectItem = (item) => {
      Fleet.addUnit(data.fleetToRemove, item);
    };

    const unSelectItem = (item) => {
      Fleet.removeUnit(data.fleetToRemove, item);
    };

    const textIfEmpty = "No units to scrap";

    return (
      <div>
        <SelectionListView
          items={items}
          displayItem={displayItem}
          selectedItems={selectedItems}
          selectItem={selectItem}
          unSelectItem={unSelectItem}
          textIfEmpty={textIfEmpty}
        ></SelectionListView>
      </div>
    );
  };

  return (
    <div className="text-box" style={{}}>
      <DisplayText></DisplayText>
      <div>
        <FleetLineView fleet={fleet} showFactionLogo={false}></FleetLineView>
      </div>
      <hr></hr>
      <DisplayScrapSelection></DisplayScrapSelection>
    </div>
  );
};

export default SolveCapacityView;
