const Unit = require("../MapData/Unit");

const TechTree = require("../Technology/TechTree");
const TechList = require("../Technology/TechList");
const Map = require("../MapData/Map");
const MapUnitaryAction = require("../UnitaryAction/MapUnitaryAction");
const Tech = require("../Technology/Tech");
const Fleet = require("../MapData/Fleet");
const System = require("../MapData/System");
const ResolveData = require("../EndOfRound/ResolveData");
const CombatData = require("../Combat/CombatData");
const LogBook = require("../Connection/LogBook");
const SolveFleetLimitData = require("../MandatoryAction/SolveFleetLimitData");
const SolveCapacityData = require("../MandatoryAction/SolveCapacityData");
const CombatRecording = require("../Combat/CombatRecording");
const PlayerData = require("../PlayerData/PlayerData");
const Cost = require("../Utils/Cost");
const Item = require("../Transactions/Item");
const PlayerRememberAction = require("../PlayerData/PlayerRememberAction");
const Rules = require("../Game/Rules");
const AbilityCommon = require("./AbilityCommon");
const Phase = require("../Game/Phase");
const UIMessage = require("../Connection/UIMessage");

class ResolveRelicAbility {
  //static PHASE_TECH_ABILITY = "resolveTechAbility";

  static resolveCommon = (playerData, item, data) => {
    //const itemDesc = Item.getItemDescription(item);
    const factionProducer = Item.getFactionProducerFromRelic(item);
    if (factionProducer === playerData.faction.name) {
      throw new Error(
        "You cannot use your own relic. It is for other faction to use."
      );
    }

    this.routeAbility(playerData, item, data);
  };

  static routeAbility = (playerData, item, data) => {
    switch (item.name) {
      case Item.NAME_TRADE_NETWORK:
        this.tradeNetwork(playerData, item, data);
        break;

      case Item.NAME_PROPULSION_AID:
        this.propulsionAid(playerData, item, data);
        break;

      case Item.NAME_DREADNOUGHT_DEPLOYMENT:
        this.dreadnoughtDeployment(playerData, item, data);
        break;

      case Item.NAME_GEO_FACTORY_ASSEMBLER:
        this.geoFactoryAssembler(playerData, item, data);
        break;

      case Item.NAME_CULTURAL_RENAISSANCE:
        this.culturalRenaissance(playerData, item, data);
        break;

      case Item.NAME_GEO_FACTORY_ASSEMBLER:
        this.geoFactoryAssembler(playerData, item, data);
        break;

      case Item.NAME_ETHERAL_CONDENSATOR:
        this.etheralCondensator(playerData, item, data);
        break;

      case Item.NAME_MOTIVATION:
        break;

      default:
        throw new Error("Unknown item ability for " + item.name + ".");
    }
  };

  static tradeNetwork = (playerData, item, data) => {
    this.logAndMessage(playerData, item, "Exhaust.");
  };

  static propulsionAid = (playerData, item, data) => {
    this.logAndMessage(playerData, item, "Exhaust.");
  };

  static dreadnoughtDeployment = (playerData, item, data) => {
    const system = Map.getSystemFromName(playerData.map, data.systemName);
    const planets = System.getPlanets(system);
    if (planets.some((planet) => planet.faction === playerData.faction.name)) {
      PlayerData.spendMineral(playerData, 3);
      MapUnitaryAction.placeUnitsInSystemSA(playerData, system, [
        Unit.createUnit(
          playerData,
          playerData.faction.name,
          Unit.UNIT_TYPE_DREADNOUGH
        ),
      ]);
      this.logAndMessage(
        playerData,
        item,
        "Placed a dreadnought in " + system.name
      );
    } else {
      throw new Error(
        "You need to control at least one planet in the system to use this relic."
      );
    }
  };
  static geoFactoryAssembler = (playerData, item, data) => {
    const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);
    if (planet.faction === playerData.faction.name) {
      PlayerData.spendMineral(playerData, 3);
      MapUnitaryAction.placeUnitsOnPlanet(playerData, planet, [
        Unit.createUnit(
          playerData,
          playerData.faction.name,
          Unit.UNIT_TYPE_FACTORY
        ),
      ]);
      this.logAndMessage(
        playerData,
        item,
        "Placed a factory on " + planet.name
      );
    } else {
      throw new Error("You need to control the planet to use this relic.");
    }
  };

  static etheralCondensator = (playerData, item, data) => {
    PlayerData.gainEnergy(playerData, 2);
    this.logAndMessage(playerData, item, "Gained 2 energy.");
  };

  static culturalRenaissance = (playerData, item, data) => {
    PlayerData.gainPopulation(playerData, 4);
    this.logAndMessage(playerData, item, "Gained 4 populations.");
  };

  static logAndMessage = (playerData, item, message) => {
    UIMessage.sendInfoMessageToClient(playerData, item.name, message);
    PlayerData.generateLogActivity(playerData, item.name + " : " + message);
  };

  static spacePeaceRelic = (recData, resolveData, system) => {
    //Defendor
    const defenderFaction = system.faction;

    //If no defendor, no need to resolve
    if (!system.faction) {
      return;
    }

    //if denfender, defender playerData
    const defenderPlayerData = ResolveData.getPlayerDataFromFaction(
      resolveData,
      defenderFaction
    );

    const playerDataList = ResolveData.getAllPlayerData(resolveData);
    for (let i = 0; i < playerDataList.length; i++) {
      const playerData = playerDataList[i];

      if (
        defenderFaction !== playerData.faction.name &&
        Item.includeRelicFromNameFromFaction(
          Item.NAME_PEACE_SPACE,
          playerData.faction.name,
          defenderPlayerData.items
        )
      ) {
        if (
          PlayerRememberAction.hasAttackedFactionInSpaceInSystem(
            playerData.rememberAction,
            defenderFaction,
            system
          )
        ) {
          //Loosing population
          PlayerData.gainPopulation(
            playerData,
            -Rules.COST_ATTACK_SPACE_WHEN_PEACE
          );

          //Logging in playerDara
          PlayerData.generateLogActivity(
            playerData,
            "Loosing " +
              Rules.COST_ATTACK_SPACE_WHEN_PEACE +
              " $logo$ because attacked $faction$ which owns the $faction$ " +
              Item.NAME_PEACE_SPACE +
              " relic in space area of $system$.",
            ["population", defenderFaction, playerData.faction.name, system]
          );

          //Logging
          const HLLog = LogBook.createLogBook();
          const DLog = LogBook.createLogBook();
          LogBook.generateAddMessage(
            HLLog,
            "$faction$ looses " +
              Rules.COST_ATTACK_SPACE_WHEN_PEACE +
              " $logo$ because of their " +
              Item.NAME_PEACE_SPACE +
              " relic.",
            [playerData.faction.name, "population"]
          );
          LogBook.generateAddMessage(
            DLog,
            "$faction$ is considered the defender because it has control of the space area in this system.",
            [defenderFaction]
          );
          LogBook.generateAddMessage(
            DLog,
            "$faction$ did move ships to this system during this round, which is then considered as an attacked in space against $faction$.",
            [playerData.faction.name, defenderFaction]
          );
          LogBook.generateAddMessage(
            DLog,
            "$faction$ attacked another faction which owns its " +
              Item.NAME_PEACE_SPACE +
              " relic, which causes it to loose " +
              Rules.COST_ATTACK_SPACE_WHEN_PEACE +
              "$logo$. At the start of next round, the relic will be sent back to $faction$.",
            [playerData.faction.name, "population", playerData.faction.name]
          );
          CombatRecording.createStep(recData, HLLog, DLog, system);
        }
      }
    }
  };

  static groundPeaceRelic = (recData, resolveData, planet, system) => {
    //Defendor
    const defenderFaction = planet.faction;

    //If no defendor, no need to resolve
    if (!planet.faction) {
      return;
    }

    //if denfender, defender playerData
    const defenderPlayerData = ResolveData.getPlayerDataFromFaction(
      resolveData,
      defenderFaction
    );

    const playerDataList = ResolveData.getAllPlayerData(resolveData);
    for (let i = 0; i < playerDataList.length; i++) {
      const playerData = playerDataList[i];

      console.log("faction : ", playerData.faction.name);
      if (
        defenderFaction !== playerData.faction.name &&
        Item.includeRelicFromNameFromFaction(
          Item.NAME_PEACE_GROUND,
          playerData.faction.name,
          defenderPlayerData.items
        )
      ) {
        console.log("relic owned");
        if (
          PlayerRememberAction.hasAttackedFactionOnGroundOnPlanet(
            playerData.rememberAction,
            defenderFaction,
            planet
          )
        ) {
          console.log("relic player has attacked");

          //Loosing population
          PlayerData.gainPopulation(
            playerData,
            -Rules.COST_ATTACK_GROUND_WHEN_PEACE
          );

          //Logging in playerDara
          PlayerData.generateLogActivity(
            playerData,
            "Loosing " +
              Rules.COST_ATTACK_GROUND_WHEN_PEACE +
              " $logo$ because attacked $faction$ which owns the $faction$ " +
              Item.NAME_PEACE_GROUND +
              " relic on planet $planet$.",
            ["population", defenderFaction, playerData.faction.name, planet]
          );

          //Logging
          const HLLog = LogBook.createLogBook();
          const DLog = LogBook.createLogBook();
          LogBook.generateAddMessage(
            HLLog,
            "$faction$ looses " +
              Rules.COST_ATTACK_GROUND_WHEN_PEACE +
              " $logo$ because of their " +
              Item.NAME_PEACE_GROUND +
              " relic.",
            [playerData.faction.name, "population"]
          );
          LogBook.generateAddMessage(
            DLog,
            "$faction$ is considered the defender because it has control of this planet.",
            [defenderFaction]
          );
          LogBook.generateAddMessage(
            DLog,
            "$faction$ did invade this planet during this round, which is then considered as an attacked on the ground against $faction$.",
            [playerData.faction.name, defenderFaction]
          );
          LogBook.generateAddMessage(
            DLog,
            "$faction$ attacked another faction which owns its " +
              Item.NAME_PEACE_GROUND +
              " relic, which causes it to loose " +
              Rules.COST_ATTACK_GROUND_WHEN_PEACE +
              "$logo$. At the start of next round, the relic will be sent back to $faction$.",
            [playerData.faction.name, "population", playerData.faction.name]
          );
          CombatRecording.createStep(recData, HLLog, DLog, system);
        }
      }
    }
  };
}

module.exports = ResolveRelicAbility;
