const PlayerData = require("../GameData/PlayerData/PlayerData");
const Rules = require("../GameData/Game/Rules.js");
const System = require("../GameData/MapData/System.js");
const Unit = require("../GameData/MapData/Unit.js");
const Map = require("../GameData/MapData/Map.js");
const Fleet = require("../GameData/MapData/Fleet.js");
const LogBook = require("../GameData/Connection/LogBook.js");
const LogMessage = require("../GameData/Connection/LogMessage.js");
const LogAttachment = require("../GameData/Connection/LogAttachment.js");
const ChatMessageList = require("./ChatRoom.js");
const ChatItem = require("./ChatItem.js");
const StaticGameData = require("../GameData/StaticGameData");
const ChatRoom = require("./ChatRoom.js");
const Request = require("../../Common/Requests/Request");

class ChatData {
  static create(game) {
    const data = {
      gameId: game.gameId,
      factionRooms: [],
      commonRoom: ChatRoom.createCommonRoom("common"),
    };

    const players = game.players;
    for (let i = 0; i < players.length; i++) {
      const player = players[i];
      data.factionRooms.push(
        ChatRoom.createFactionRoom(player, player.playerInGameId)
      );
    }

    return data;
  }

  //Faction update
  static updateFaction(game, factionPickList, username) {
    const playerIndex = game.players.findIndex(
      (player) => player.username === username
    );
    if (playerIndex === -1) {
      throw new Error("Player not found");
    }
    game.players[playerIndex].factionPickList = factionPickList;
  }

  //Get
  /*static getRoomFromPlayerInGameId(chatData, playerInGameId) {
    if (playerInGameId === "common") return chatData.commonRoom;

    for (let i = 0; i < chatData.factionRooms.length; i++) {
      if (chatData.factionRooms[i].playerInGameId === playerInGameId) {
        return chatData.factionRooms[i];
      }
    }
    return chatData.commonRoom;
  }*/

  static getRoomFromId(chatData, id) {
    if (id === chatData.commonRoom.id) return chatData.commonRoom;

    for (let i = 0; i < chatData.factionRooms.length; i++) {
      if (chatData.factionRooms[i].id === id) {
        return chatData.factionRooms[i];
      }
    }
    return chatData.commonRoom;
  }

  static getRoomIdList(chatData) {
    const roomList = [];
    roomList.push(chatData.commonRoom.id);
    for (let i = 0; i < chatData.factionRooms.length; i++) {
      roomList.push(chatData.factionRooms[i].id);
    }
    return roomList;
  }
}

module.exports = ChatData;
