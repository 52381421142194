const PlayerData = require("../GameData/PlayerData/PlayerData.js");
const Rules = require("../GameData/Game/Rules.js");
const System = require("../GameData/MapData/System.js");
const Unit = require("../GameData/MapData/Unit.js");
const Map = require("../GameData/MapData/Map.js");
const Fleet = require("../GameData/MapData/Fleet.js");
const LogBook = require("../GameData/Connection/LogBook.js");
const LogMessage = require("../GameData/Connection/LogMessage.js");
const LogAttachment = require("../GameData/Connection/LogAttachment.js");
const ChatItem = require("./ChatItem.js");
const Request = require("../../Common/Requests/Request.js");
const ChatData = require("./ChatData.js");

class ChatStaticData {
  //Static data in game
  static chatData = null;
  static roomChatItems = {};
  static roomLastLoadedItemId = {};

  static isLoadingChatData = false;

  static async initialize() {
    await this.loadChatData();

    await this.loadMostRecentChatItems(this.chatData.commonRoom.id);
    const factionRooms = this.chatData.factionRooms;
    for (let i = 0; i < factionRooms.length; i++) {
      await this.loadMostRecentChatItems(factionRooms[i].id);
    }
  }

  static async loadMostRecentChatItems(roomId) {
    const room = ChatData.getRoomFromId(this.chatData, roomId);

    const startIndex = Math.max(room.lastItemId - 99, 0);
    const endIndex = null;

    const result = await Request.getChatItems(roomId, startIndex, endIndex);

    this.concatChatItems(roomId, result);

    return this.roomChatItems[roomId];
  }

  static async loadChatData() {
    console.log("ChatStaticData requesting chat data...");
    //if (this.isLoadingChatData) return;
    this.isLoadingChatData = true;
    this.chatData = await Request.getChatData();
    this.isLoadingChatData = false;
    console.log("ChatStaticData chatData : ", this.chatData);
    return this.chatData;
  }

  static deleteChatItemsWithoutItemId(roomId) {
    if (!this.roomChatItems[roomId]) return;

    this.roomChatItems[roomId] = this.roomChatItems[roomId].filter(
      (chatItem) => {
        if (chatItem.itemId) {
          return true;
        } else {
          return false;
        }
      }
    );
  }

  static concatChatItems(roomId, chatItems) {
    //Delete chat items that have no id, so chat item added by the user and not coming from the DB. It should be replaced by a chat item from the db
    this.deleteChatItemsWithoutItemId(roomId);

    if (
      !this.roomChatItems[roomId] ||
      this.roomChatItems[roomId].length === 0
    ) {
      this.roomChatItems[roomId] = chatItems;
      this.roomLastLoadedItemId[roomId] = -1;

      return;
    }

    let indexIncoming = 0; // Use let instead of const
    let indexCurrent = 0; // Use let instead of const
    const currentItems = this.roomChatItems[roomId];

    while (indexIncoming < chatItems.length && indexIncoming < 100) {
      // If no more items in current
      if (indexCurrent >= currentItems.length) {
        currentItems.push(chatItems[indexIncoming]);
        indexIncoming++;
        continue;
      }

      // If should be placed before
      if (chatItems[indexIncoming].itemId < currentItems[indexCurrent].itemId) {
        currentItems.splice(indexCurrent, 0, chatItems[indexIncoming]);
        indexIncoming++;
        indexCurrent++; // Increment both indices to move past the newly inserted item
        continue;
      }

      // If same item
      if (
        chatItems[indexIncoming].itemId === currentItems[indexCurrent].itemId
      ) {
        currentItems[indexCurrent] = chatItems[indexIncoming];
        indexIncoming++;
        indexCurrent++;
        continue;
      }

      // If should be placed after
      if (chatItems[indexIncoming].itemId > currentItems[indexCurrent].itemId) {
        indexCurrent++;
        continue;
      }
    }

    if (currentItems.length > 0) {
      this.roomLastLoadedItemId[roomId] =
        currentItems[currentItems.length - 1].itemId;
    } else {
      this.roomLastLoadedItemId[roomId] = -1;
    }
  }

  //Get
  static getChatItems(roomId) {
    return this.roomChatItems[roomId];
  }

  //Add
  static addChatItemText(room, text, playerInGameId) {
    const chatItem = ChatItem.createMessageText(text, playerInGameId);
    this.roomChatItems[room.id].push(chatItem);
    Request.insertChatItem(room.id, chatItem);
  }
}

module.exports = ChatStaticData;
