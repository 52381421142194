import React, { useEffect, useState } from "react";
import StaticGameData from "../../Data/GameData/StaticGameData.js";

const Color = require("../../Common/Config/Colors.js");
const DeviceData = require("../../Data/Other/DeviceData.js");

const BaseButton = ({
  type = "white",
  children,
  onClick,
  //fillSpace = false,
  width = "auto",
  height = "auto",
  textColor = "black",
  noPadding = false,
  borderStyle = null,
  borderWidth = null,
  borderColor = null,
  overflow = "auto",
  backgroundColor = null,
  color = null,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const [canMouseMove, setCanMouseMove] = useState(false);
  const popup = StaticGameData.popup;

  const handleMouseDown = () => {
    setIsPressed(true);
    DeviceData.userClickedMouse();
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  const handleTouchStart = () => {
    DeviceData.userTouchedScreen();
    setIsPressed(true);
    setIsHovered(true);
  };

  const handleTouchEnd = () => {
    setIsHovered(false);
    setIsPressed(false);
  };

  const handleMouseEnter = () => {
    if (DeviceData.IS_USER_USING_MOUSE) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseMove = () => {
    console.log("Mouse move");
    setCanMouseMove(true);
  };

  useEffect(() => {
    setIsHovered(false);
  }, [popup]);

  let style = {
    color: textColor,
    borderRadius: "10px",

    padding: noPadding ? "2px" : "8px 16px",
    margin: "2px",
    cursor: "pointer",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "inherit", // Inherit the font size from the parent};
    font: "inherit",
    width: width,
    height: height,
    overflow: overflow,
    flexShrink: 0,

    //
    //padding: "inherit",
    //margin: "inherit",
  };

  if (type === "submit") {
    style = {
      ...style,
      type: "submit",
    };
    type = "navigation";
  }

  if (type === "navigation" || type === "grey" || type === "greyOut") {
    /*style = {
      ...style,
      backgroundColor: isHovered ? Color.COLOR_GREEN_THEME_SHADOW : "black",
      color: "white",
      borderColor: "white",
    };*/
    style = {
      ...style,
      backgroundColor: isHovered ? "rgb(54,80,134)" : "rgb(0,0,0,1)",
      color: "rgb(220,220,220)",
      //borderColor: "rgb(13,21,34)",
      border: "2px solid rgb(27,40,67)",
    };
  }

  if (type === "white_2") {
    style = {
      ...style,
      backgroundColor: isHovered ? "rgb(54,80,134)" : "rgb(55,102,177)",
      color: "white",
      borderColor: "rgb(13,21,34)",
    };
  }

  if (type === "white") {
    style = {
      ...style,
      backgroundColor: isHovered
        ? Color.BASE_BUTTON_BACK_HOVER
        : Color.BASE_BUTTON_BACK,
      color: "white",
      //borderColor: "rgb(13,21,34)",
      border: "2px solid rgb(54,80,134)",
    };
  }
  if (type === "gold") {
    style = {
      ...style,
      backgroundColor: isHovered
        ? Color.BASE_BUTTON_BACK_HOVER
        : Color.BASE_BUTTON_BACK,
      color: "white",
      //borderColor: "rgb(13,21,34)",
      border: "2px solid gold",
    };
  }

  if (type === "white_old") {
    style = {
      ...style,
      backgroundColor: isHovered ? "rgb(200,200,200)" : "rgb(240,240,240)",
      color: "black",
      borderColor: isHovered ? "rgb(200,200,200)" : "white",
    };
  }

  if (type === "grey_2") {
    style = {
      ...style,
      backgroundColor: isHovered ? Color.COLOR_GREY_5 : Color.COLOR_GREY_3,
      color: "white",
      borderColor: Color.COLOR_GREY_5,
    };
  }

  if (type === "fleet") {
    style = {
      ...style,
      //backgroundColor: isHovered ? "#2980b9" : "white",
      backgroundColor: isHovered ? Color.COLOR_GREY_4 : Color.COLOR_GREY_1,
      //color: textColor,
    };
  }

  if (type === "action") {
    style = {
      ...style,
      //backgroundColor: isHovered ? "#2980b9" : "white",
      backgroundColor: isHovered ? Color.COLOR_GREEN_THEME_SHADOW : "black",
      color: Color.COLOR_GREEN_THEME_LIGHT,
      //"RGB(10,40,0)"
      //backgroundColor: isHovered ? Color.COLOR_BUTTON_ACTION_2 : "white",
      borderColor: Color.COLOR_GREEN_THEME_LIGHT,
      //color: "red",
      //color: textColor,
    };
  }

  if (type === "transparent") {
    style = {
      ...style,
      //backgroundColor: isHovered ? "#2980b9" : "white",
      backgroundColor: Color.COLOR_HEAVY_SHADOW,
      color: "red",
      //color: textColor,
    };
  }

  if (type === "greyOut_2") {
    style = {
      ...style,
      //backgroundColor: "black",
      backgroundColor: isHovered ? "rgb(100,100,100)" : "rgb(140,140,140)",
      color: "black",
      borderColor: "white",

      //color: textColor,
    };
  }

  if (borderStyle) {
    style.border = borderStyle;
  }

  if (backgroundColor) {
    style.backgroundColor = backgroundColor;
  }

  if (color) {
    style.color = color;
  }

  if (borderColor) {
    style.borderColor = borderColor;
  }

  if (borderWidth) {
    style.borderWidth = borderWidth;
  }

  const handleButtonClick = (e) => {
    //console.log("Button clicked");

    e.preventDefault();

    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      style={style}
      onClick={handleButtonClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}

      //onMouseMove={handleMouseMove}
    >
      {children}
    </button>
  );
};

export default BaseButton;
