const Faction = require("./Faction");
const Map = require("../MapData/Map");
const Color = require("../../../Common/Config/Colors");
const Phase = require("../Game/Phase");
const Planet = require("../MapData/Planet");
const Unit = require("../MapData/Unit");
const Cost = require("../Utils/Cost");
const LogBook = require("../Connection/LogBook");
const System = require("../MapData/System");
const LogAttachment = require("../Connection/LogAttachment");
const CustomMath = require("../../../Common/Math/CustomMath");
const LogMessage = require("../Connection/LogMessage");
const UIMessage = require("../Connection/UIMessage");
const Rules = require("../../../Data/GameData/Game/Rules");
const TechTree = require("../Technology/TechTree");
const TechList = require("../Technology/TechList");
const ScoreData = require("../Objectifs/ScoreData");
const PlayerRememberAction = require("./PlayerRememberAction");
const Fleet = require("../MapData/Fleet");
const Objectives = require("../Objectifs/Objectives");
const GameUpdate = require("../Game/GameUpdate");

class PlayerData {
  static createPlayerData(
    map,
    factionsToPickFrom,
    homeSystemCoords,
    playerInGameId,
    techTree,
    game,
    gameUpdate,
    phase = Phase.PHASE_START_OF_GAME,
    step = Phase.STEP_START_OF_GAME_PICK_FACTION
  ) {
    const playerData = {
      faction: null,
      map: map,
      messageToDisplay: null,
      otherPlayers: [],
      homeSystemCoords: homeSystemCoords,
      factionsToPickFrom: factionsToPickFrom,
      //ongoingAction: { name: "pick faction" },
      phase: phase,
      step: step,
      mandatoryAction: [],
      colonists: 0,
      color: null,
      influence: 0,
      energy: 4,
      energyConsumption: 0,
      science: 0,
      credit: 2,
      cargo: 3,
      mineral: 0,
      population: 4,
      unitCreationId: 0,
      items: [],
      rememberAction: PlayerRememberAction.create(),

      //log
      activityLogs: [],
      scoreData: ScoreData.create(),

      //Game info
      roundNumber: 1,
      isWaitingEndOfRound: false,
      playersWaitingForNewRound: [],
      gameVersion: 0.11, // Chat available
      gameUpdate: gameUpdate,

      //player identification
      playerInGameId: playerInGameId,

      //Tutorial
      isTutorial: false,

      //Activation Data
      activationData: null,

      //Tech
      techTree: techTree,

      //Effects
      effects: [],

      //Game Param
      gameParam: {
        draft: game.draft,
        vpLimit: game.vpLimit,
        playerNameHidden: game.playerNameHidden,
        ranked: game.ranked,
      },
      gameStatus: null,

      draftData: null,
    };

    //this.adminAfterRound(playerData);
    this.createNewRoundLogs(playerData, playerData.roundNumber);
    /*ScoreData.adminAfterRound(
      playerData,
      playerData.scoreData,
      playerData.roundNumber
    );*/
    //LogAttachment.create(playerData, true, false);

    Objectives.setScoredObjectives(playerData, []);

    return playerData;
  }

  static getNeutralPlayerData(factionName) {
    const neutralPlayerData = {
      faction: {
        name: factionName,
      },
      color: Color.COLOR_NEUTRAL_FACTION,
      isNeutralPlayer: true,
      playerInGameId: factionName,
      otherPlayers: [],

      //Tech
      techTree: TechTree.createEmptyTechTree(),
    };

    return neutralPlayerData;
  }

  static getOtherPlayers(playerData) {
    return playerData.otherPlayers;
  }

  static getOtherPlayersWithFaction(playerData) {
    return playerData.otherPlayers.filter((player) => player.faction);
  }

  static filterPlayerHiddenData(playerDataToHide) {
    //This function is to hide the data of other players. This is not needed if you use the functions to add and update other players
    //map on other players
    const otherPlayers = playerDataToHide.otherPlayers;
    for (let i = 0; i < otherPlayers.length; i++) {
      this.fileterOtherPlayerHiddenData(otherPlayers[i]);
    }

    return playerDataToHide;
  }

  static fileterOtherPlayerHiddenData(otherPlayerDataToHide) {
    otherPlayerDataToHide.messageToDisplay = null;
    otherPlayerDataToHide.colonists = null;
    otherPlayerDataToHide.otherPlayers = null;
    otherPlayerDataToHide.map = null;
    return otherPlayerDataToHide;
  }

  static createPlayerFaction(playerData, factionName) {
    playerData.faction = Faction.createFaction(factionName);
  }

  static createOtherPlayer(
    playerData,
    playerDataToAdd,
    filterHiddenData = true
  ) {
    //Check if other player to add is the player
    if (playerDataToAdd.playerInGameId === playerData.playerInGameId) {
      throw new Error("Player cannot be added as other player");
    }
    if (filterHiddenData) {
      //Add a json deep copy, as this is a create function. This is to avoid impacting the original data with the filter.
      playerData.otherPlayers.push(
        this.fileterOtherPlayerHiddenData(
          JSON.parse(JSON.stringify(playerDataToAdd))
        )
      );
    }
  }

  static getPlayerId(playerData) {
    return playerData.playerInGameId;
  }

  static removeAllOtherPlayers(playerData) {
    playerData.otherPlayers = [];
  }

  static updateOtherPlayer(playerData, playerDataToUpdateP) {
    const playerDataToUpdate = JSON.parse(JSON.stringify(playerDataToUpdateP));
    const playerIndex = playerData.otherPlayers.findIndex(
      (player) => player.playerInGameId === playerDataToUpdate.playerInGameId
    );
    if (playerIndex === -1) {
      throw new Error("Player not found");
    }
    playerData.otherPlayers[playerIndex] =
      this.fileterOtherPlayerHiddenData(playerDataToUpdate);
  }

  static getPlayerDataFromFaction(
    playerData,
    factionName,
    onlyRealPlayers = false
  ) {
    //check if player is the faction
    //console.log("getPlayerDataFromFaction playerData", playerData);
    //console.log("getPlayerDataFromFaction factionName", factionName);
    if (playerData.faction && playerData.faction.name === factionName) {
      return playerData;
    }

    //Search in other players
    const playerIndex = playerData.otherPlayers.findIndex(
      (player) => player.faction && player.faction.name === factionName
    );
    if (playerIndex > -1) {
      return playerData.otherPlayers[playerIndex];
    }

    if (onlyRealPlayers === false) {
      if (Faction.isMinorFaction(factionName)) {
        const neutralPlayerData = this.getNeutralPlayerData();
        return neutralPlayerData;
      }
    }

    //return fake player data
    return Unit.createFakePlayerData(Faction.createFaction(factionName));

    throw new Error("PlayerData from factionName not found");
  }

  static getPlayerDisplayName(playerData, playerInGameId) {
    const otherPlayerData = this.getPlayerDataFromInGameId(
      playerData,
      playerInGameId
    );
    if (playerData.gameParam.playerNameHidden || !otherPlayerData.username) {
      return "Player " + otherPlayerData.playerInGameId;
    } else {
      return otherPlayerData.username;
    }
  }

  static getPlayerDataFromInGameId(playerData, playerInGameId) {
    //check if player is the faction
    if (playerData.playerInGameId === playerInGameId) {
      return playerData;
    }

    //Search in other players
    const playerIndex = playerData.otherPlayers.findIndex(
      (player) => player.playerInGameId === playerInGameId
    );
    if (playerIndex === -1) {
      throw new Error("Player not found");
    }
    return playerData.otherPlayers[playerIndex];
  }

  static getHomeSystemOfFaction(playerData, factionName) {
    //check if player is the faction
    if (playerData.faction.name === factionName) {
      return Map.getSystemFromCoords(
        playerData.map,
        playerData.homeSystemCoords.x,
        playerData.homeSystemCoords.y
      );
    }

    //Search in other players
    const playerIndex = playerData.otherPlayers.findIndex(
      (player) => player.faction.name === factionName
    );
    if (playerIndex === -1) {
      throw new Error("Player not found");
    }
    return Map.getSystemFromCoords(
      playerData.map,
      playerData.otherPlayers[playerIndex].homeSystemCoords.x,
      playerData.otherPlayers[playerIndex].homeSystemCoords.y
    );
  }

  static pickFaction(playerData, factionName) {
    //Search in factions to pick from
    const factionIndex = playerData.factionsToPickFrom.findIndex(
      (faction) => faction.name === factionName
    );
    if (factionIndex === -1) {
      throw new Error("Faction not found in playerData");
    }

    //Add faction to player
    this.createPlayerFaction(playerData, factionName);
    playerData.ongoingAction = { name: "colonize" };
    playerData.colonists = playerData.faction.startingColonists;
    console.log("playerData.faction", playerData.faction);
    console.log(
      "playerData.faction.startingColonists",
      playerData.faction.startingColonists
    );
    console.log("playerData.colonists", playerData.colonists);
    //Remove faction from factions to pick
    playerData.factionsToPickFrom = null;
  }

  static colonize(playerData, spaceObject) {
    //console.log("colonize spaceObject", spaceObject);
    //console.log("colonize playerData", playerData);
    const homeSystem = this.getHomeSystemOfFaction(
      playerData,
      playerData.faction.name
    );
    if (Map.CanObjectBeColonized(spaceObject, homeSystem, playerData)) {
      if (playerData.colonists <= 0) {
        throw new Error("Not enough colonists. You have 0 colonists.");
      } else {
        playerData.colonists--;
        spaceObject.faction = playerData.faction.name;
        Planet.addPopulation(spaceObject, 1);

        //Take control of space area
        const system = Map.getSystemFromSpaceObject(
          spaceObject,
          playerData.map
        );

        const systemFleets = System.getFleets(system);
        const fleetCount = systemFleets.filter(
          (fleet) => !Fleet.isEmpty(fleet)
        ).length;
        let controlMessage =
          "As your fleet is not the only one in this system, you do not gain control of the space area.";
        if (fleetCount === 0) {
          system.faction = playerData.faction.name;
          controlMessage =
            "You gained control of the space area of this system.";
        }

        const destroyer = Unit.createUnit(
          playerData,
          playerData.faction.name,
          "Destroyer"
        );

        System.addUnit(playerData, system, destroyer, playerData.faction.name);

        return {
          message:
            spaceObject.name +
            " has been colonized. You spent 1 colonist for it. One population has been placed on " +
            spaceObject.name +
            ". One destroyer has been placed in the system.",
        };
      }
    }
  }

  static getFleetColor(playerData, fleet) {
    const fleetPlayerData = PlayerData.getPlayerDataFromFaction(
      playerData,
      fleet.faction
    );
    return Color.getColorFromPlayerColor(fleetPlayerData.color);
  }

  static getPlayerColor(playerData, faction = null) {
    if (!faction) {
      return playerData.color;
    } else {
      return PlayerData.getPlayerDataFromFaction(playerData, faction).color;
    }
  }

  static getAllPlayersData(playerData, onlyRealPlayers = false) {
    return [playerData, ...playerData.otherPlayers];
  }

  /*static getFactionNameFromColor(playerData, color) {
    if (playerData.color === color) {
      return playerData.faction.name;
    } else {
      const playerIndex = playerData.otherPlayers.findIndex(
        (player) => player.color === color
      );
      if (playerIndex > -1) {
        return playerData.otherPlayers[playerIndex].faction.name;
      } else {
        if (this.getNeutralPlayerData().color === color) {
          return this.getNeutralPlayerData().faction.name;
        } else {
          return null;
        }
      }
    }
  }*/

  /*static setOngoingAction(playerData, name, data = {}) {
    playerData.ongoingAction = { name: name, ...data };
  }*/

  static setPhase(playerData, phase) {
    playerData.phase = phase;
  }

  static setStep(playerData, step) {
    playerData.step = step;
  }

  static spendCargo(playerData, amount) {
    if (amount <= 0) {
      return;
    }
    if (playerData.cargo < amount) {
      throw new Error(
        "Not enough cargo. You have " +
          playerData.cargo +
          " cargo, and you are trying to spend " +
          amount +
          " cargo."
      );
    }
    playerData.cargo -= amount;
  }
  static gainCargo(playerData, amount) {
    playerData.cargo += amount;
  }
  static spendInfluence(playerData, amount) {
    if (amount <= 0) {
      return;
    }
    if (playerData.influence < amount) {
      throw new Error(
        "Not enough influence. You have " +
          playerData.influence +
          " influence, and you are trying to spend " +
          amount +
          " influence."
      );
    }
    playerData.influence -= amount;
  }
  static gainInfluence(playerData, amount) {
    playerData.influence += amount;
  }

  static consumeEnergy(playerData, amount) {
    let previousEnergy = playerData.energy;
    playerData.energy = CustomMath.roundDec(playerData.energy - amount);
    LogAttachment.logActivity(playerData, [
      {
        type: LogMessage.ITEM_TYPE_FACTION_LOGO,
        content: playerData.faction.name,
      },
      {
        content: " consumed " + playerData.energyConsumption + " ",
      },
      { type: LogMessage.ITEM_TYPE_RESOURCE_LOGO, content: "energy" },
      {
        content:
          " (was " + previousEnergy + ", now " + playerData.energy + ").",
      },
    ]);

    previousEnergy = playerData.energy;
    const energyBonus = Faction.getEnergyProduction(playerData.faction);
    playerData.energy = CustomMath.roundDec(playerData.energy + energyBonus);
    LogAttachment.logActivity(playerData, [
      {
        type: LogMessage.ITEM_TYPE_FACTION_LOGO,
        content: playerData.faction.name,
      },
      {
        content: " gained end of round energy bonus : + " + energyBonus + " ",
      },
      { type: LogMessage.ITEM_TYPE_RESOURCE_LOGO, content: "energy" },
      {
        content:
          " (was " + previousEnergy + ", now " + playerData.energy + ").",
      },
    ]);
  }

  static spendEnergy(playerData, amount) {
    if (amount <= 0) {
      return;
    }
    if (playerData.energy < amount) {
      throw new Error(
        "Not enough energy. You have " +
          playerData.energy +
          " energy, and you are trying to spend " +
          amount +
          " energy."
      );
    }
    playerData.energy = CustomMath.roundDec(playerData.energy - amount);
  }
  static gainEnergy(playerData, amount) {
    playerData.energy = CustomMath.roundDec(playerData.energy + amount);
  }
  static spendScience(playerData, amount) {
    if (amount <= 0) {
      return;
    }
    if (playerData.science < amount) {
      throw new Error(
        "Not enough science. You have " +
          playerData.science +
          " science, and you are trying to spend " +
          amount +
          " science."
      );
    }
    playerData.science = CustomMath.roundDec(playerData.science - amount);
  }
  static gainScience(playerData, amount) {
    playerData.science = CustomMath.roundDec(playerData.science + amount);
  }
  static spendCredit(playerData, amount) {
    if (amount <= 0) {
      return;
    }
    if (playerData.credit < amount) {
      throw new Error(
        "Not enough credit. You have " +
          playerData.credit +
          " credit, and you are trying to spend " +
          amount +
          " credit."
      );
    }
    playerData.credit = CustomMath.roundDec(playerData.credit - amount);
  }
  static spendMineral(playerData, amount) {
    if (amount <= 0) {
      return;
    }
    if (playerData.mineral < amount) {
      throw new Error(
        "Not enough mineral. You have " +
          playerData.mineral +
          " mineral, and you are trying to spend " +
          amount +
          " mineral."
      );
    }
    playerData.mineral = CustomMath.roundDec(playerData.mineral - amount);
  }
  static gainMineral(playerData, amount) {
    playerData.mineral = CustomMath.roundDec(playerData.mineral + amount);
  }
  static spendPopulation(playerData, amount) {
    if (amount <= 0) {
      return;
    }

    if (playerData.population < amount) {
      throw new Error(
        "Not enough population. You have " +
          playerData.population +
          " population, and you are trying to spend " +
          amount +
          " population."
      );
    }
    playerData.population = CustomMath.roundDec(playerData.population - amount);
  }
  static gainPopulation(playerData, amount) {
    playerData.population = CustomMath.roundDec(playerData.population + amount);
  }
  static gainCredit(playerData, amount) {
    playerData.credit = CustomMath.roundDec(playerData.credit + amount);
  }

  static gainVP(playerData, amount, explanation, explanationItems = []) {
    ScoreData.addScore(
      playerData.scoreData,
      amount,
      explanation,
      explanationItems
    );
  }

  static getVP(playerData) {
    return ScoreData.getScore(playerData.scoreData);
  }

  //Spend
  static adaptCostForBuy(playerData, cost, planet = null) {
    //Adapt the cost if not enough resources to pay, to use credit to pay.

    let missingMineral = 0;
    if (cost.mineral) {
      if (!planet) {
        throw new Error(
          "You have to spend mineral using the mineral stock of a planet."
        );
      }
      missingMineral = cost.mineral - planet.mineral;
    }
    const missingScience = cost.science - playerData.science;
    const missingEnergy = cost.energy - playerData.energy;
    const missingInfluence = cost.influence - playerData.influence;

    const amountOfCreditToUse =
      missingMineral * Rules.BUY_MINERAL_COST +
      missingScience * Rules.BUY_SCIENCE_COST +
      missingEnergy * Rules.BUY_ENERGY_COST +
      missingInfluence * Rules.BUY_INFLUENCE_COST;

    cost.credit += CustomMath.roundDec(amountOfCreditToUse);
    if (missingMineral > 0) {
      cost.mineral = CustomMath.roundDec(cost.mineral - missingMineral);
    }
    if (missingScience > 0) {
      cost.science = CustomMath.roundDec(cost.science - missingScience);
    }
    if (missingEnergy > 0) {
      cost.energy = CustomMath.roundDec(cost.energy - missingEnergy);
    }
    if (missingInfluence > 0) {
      cost.influence = CustomMath.roundDec(cost.influence - missingInfluence);
    }
  }

  static spendCost(playerData, cost) {
    this.spendCargo(playerData, cost.cargo);
    this.spendCredit(playerData, cost.credit);
    this.spendEnergy(playerData, cost.energy);
    this.spendInfluence(playerData, cost.influence);
    this.spendScience(playerData, cost.science);
    this.spendMineral(playerData, cost.mineral);
    this.spendPopulation(playerData, cost.population);
  }

  static buyMineral(playerData, amount, planet) {
    const creditCost = amount * Rules.BUY_MINERAL_COST;
    this.spendCredit(playerData, creditCost);
    Planet.addMineral(planet, amount);
  }

  static buyScience(playerData, amount) {
    const creditCost = amount * Rules.BUY_SCIENCE_COST;
    this.spendCredit(playerData, creditCost);
    this.gainScience(playerData, amount);
  }

  static buyInfluence(playerData, amount) {
    const creditCost = amount;
    this.spendCredit(playerData, creditCost);
    this.gainInfluence(playerData, amount);
  }

  static buyEnergy(playerData, amount) {
    const creditCost = amount * Rules.BUY_ENERGY_COST;
    this.spendCredit(playerData, creditCost);
    this.gainEnergy(playerData, amount);
  }

  static getFleetLimit(playerData) {
    return playerData.cargo;
  }

  static addMandatoryAction(playerData, actionName, data) {
    playerData.mandatoryAction.push({ name: actionName, data: data });
  }

  static removeMandatoryAction(playerData) {
    playerData.mandatoryAction.shift();
  }

  static removeAllMandatoryAction(playerData) {
    playerData.mandatoryAction = [];
  }

  static getFirstMandatoryAction(playerData) {
    return playerData.mandatoryAction[0];
  }
  static resetMandatoryAction(playerData) {
    playerData.mandatoryAction = [];
  }

  static replaceMandatoryAction(playerData, data) {
    playerData.mandatoryAction[0] = data;
  }

  static isThereMandatoryAction(playerData) {
    return playerData.mandatoryAction.length > 0;
  }

  static getFleetLimit(playerData) {
    return playerData.cargo;
  }

  static getCostMove(playerData) {
    const cost = Cost.createCost({ energy: 1 });
    return cost;
  }

  static getCostLift(playerData) {
    const cost = Cost.createCost({ energy: 1 });
    return cost;
  }

  static getCostTransfer(playerData, amountPopulation, amountMineral) {
    const sumResource = amountPopulation + amountMineral;
    const costEnergy = Math.ceil(sumResource / 3);
    const cost = Cost.createCost({
      energy: costEnergy,
    });
    return cost;
  }

  //log //get

  static getItems(playerData) {
    return playerData.items;
  }

  static addItem(playerData, item) {
    playerData.items.push(item);
  }

  static removeItem(playerData, item) {
    const index = playerData.items.indexOf(item);
    if (index > -1) {
      playerData.items.splice(index, 1);
    }
  }

  static getScoreData(playerData) {
    return playerData.scoreData;
  }

  static getActivityLog(playerData) {
    const logBook = LogBook.createLogBook();
    for (let i = 0; i < playerData.activityLogs.length; i++) {
      LogBook.concat(playerData.activityLogs[i], logBook);
    }
    return logBook;
  }

  static getLastActivityLogBook(playerData) {
    LogBook.correctCursor(
      playerData.activityLogs[playerData.activityLogs.length - 1]
    );
    return playerData.activityLogs[playerData.activityLogs.length - 1];
  }

  static replaceLastActivityLogBook(playerData, logBook) {
    playerData.activityLogs[playerData.activityLogs.length - 1] = logBook;
  }

  static getLastScoreLogBook(playerData) {
    return ScoreData.getLastLog(playerData.scoreData);
  }

  static scoreGenerateAddMessage(playerData, text, itemArray = []) {
    const logBook = this.getLastScoreLogBook(playerData);
    LogBook.generateAddMessage(logBook, text, itemArray);
  }

  static clearLogsNewRound(playerData) {}

  static createNewRoundLogs(playerData, roundNumber) {
    const logBook = LogBook.createLogBook();
    LogBook.createMessage(
      logBook,
      [{ content: "ROUND " + roundNumber }],
      false,
      LogMessage.TYPE_TITLE_SECTION
    );
    playerData.activityLogs.push(logBook);
    playerData.activityLogs.push(LogBook.createLogBook());
  }

  static generateLogActivity(playerData, text, itemArray = []) {
    const logBook = this.getLastActivityLogBook(playerData);
    LogBook.correctCursor(logBook);
    LogBook.generateAddMessage(logBook, text, itemArray);
  }

  /* static logActivityToSystem(playerData, system, logMessage) {
    LogAttachment.logActivity(playerData, logMessage);
    LogAttachment.logActivity(system, logMessage);
  }

  static logActivityToSpaceObject(playerData, system, spaceObject, logMessage) {
    LogAttachment.logActivity(playerData, logMessage);
    LogAttachment.logActivity(system, logMessage);
    LogAttachment.logActivity(spaceObject, logMessage);
  }*/

  //Round
  static clearBeforeRound(playerData) {
    UIMessage.resetMessageToDisplay(playerData);
  }

  //Resource
  static updateEnergyConsumption(playerData) {
    playerData.energyConsumption = 0;
    const systemList = Map.getSystemList(playerData.map);
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];

      const planetList = System.getPlanetsFromFaction(
        system,
        playerData.faction.name
      );

      for (let j = 0; j < planetList.length; j++) {
        const planet = planetList[j];
        playerData.energyConsumption = CustomMath.roundDec(
          playerData.energyConsumption +
            Planet.getConsumedEnergy(playerData, planet)
        );
      }
    }
  }

  static updateFleetLimit(playerData) {
    playerData.cargo = playerData.faction.startingCargo;
    const systemList = Map.getSystemList(playerData.map);
    for (let i = 0; i < systemList.length; i++) {
      const system = systemList[i];

      const planetList = System.getPlanetsFromFaction(
        system,
        playerData.faction.name
      );

      for (let j = 0; j < planetList.length; j++) {
        const planet = planetList[j];
        playerData.cargo = CustomMath.roundDec(
          playerData.cargo + Planet.getFleetLimitGain(playerData, planet)
        );
      }
    }
  }

  //Tutorial
  static setTutorial(playerData, isTutorial) {
    playerData.isTutorial = isTutorial;
  }

  static isTutorial(playerData) {
    return playerData.isTutorial;
  }

  static getActionData(playerData) {
    return playerData.actionData;
  }
}

module.exports = PlayerData;
